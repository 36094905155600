export const ROUTES = {
    LOGIN_ROUTE: "/",
    UNAUTHORIZE_ROUTE: "/unAuthorize",
    PAGE_NOT_FOUND_ROUTE: "404",
    RESET_PASS_ROUTE: "/resetPassword",

    RAW_DATA_ROUTE: '/row-data',
    REPORT_DATA_ROUTE: '/report-data',

    PML_CALCULATION: '/fire-pml-calculation',
    ENGINEERING_CALCULATION: '/engineering-pml-calculation',
    SLABS_TREATY: '/fire-slabs-treaty',

    ALLOCATION_LOGIC_MASTER: '/allocation-master',

    USER_DETAILS: '/user-details',
    ADD_NEW_USER: '/user-details/add-new-user',
    EDIT_USER: '/edit-user/:id',
    ADD_USER_ROUTE: "/add-user",
    IP_ROUTE: "/ipInfo",
    MONTH_CLOSING_ROUTE: "/month-closing",

    HISTORY_LIST: "/history"
}
