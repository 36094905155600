import React, { useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import jspreadsheet from "jspreadsheet-ce";
import DatePicker from "react-datepicker";
import { getRequest, patchRequest } from "../../../helper/AxiosClient";
import { GET_PML_DATA, EDIT_PML_DATA, UPDATE_PML_RISK, GET_PML_RISK } from "../../routes/ApiEndPoin";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import CustomButton from "../../common/CustomButton";

const FirePmlCalculation = () => {
  const pmlProductRef = useRef();
  const pmlProductRiskRef = useRef();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editPmlFlag, setEditPmlFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRisk, setLoadingRisk] = useState(false);
  const [getApiData, setGetApiData] = useState([]);
  const [getApiDataRisk, setGetApiDataRisk] = useState();
  const [pmlColumnData, setPmlColumnData] = useState([]);
  const [pmlColumnDataRisk, setPmlColumnDataRisk] = useState([]);
  const [pmlRowData, setPmlRowData] = useState([]);
  const [pmlRowDataRisk, setPmlRowDataRisk] = useState([]);
  const [monthClosingValue, setMonthClosingValue] = useState(false);

  const selectedMonth = parseInt(selectedDate?.getMonth() + 1);
  const selectedYear = selectedDate?.getFullYear();
  // const isEditable =
  //   (curYear === selectedYear && curMonth === selectedMonth) ||
  //   currentDate < selectedDate;
  var selectedMonthName = selectedDate?.toLocaleString("en-US", {
    month: "short",
  });

  /* ----- Col Generator Function ----- */
  const colGenerator = () => {
    const pmlColumnData = [
      {
        name: "minimum_sum_insured",
        title: "Minimum Sum Insured",
        width: 150,
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "maximum_sum_insured",
        title: "Maximum Sum Insured",
        width: 150,
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "Minimum PML MB %",
        title: "Minimum PML MB %",
        width: 150,
        type: "number",
        mask: "0.00",
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "Minimum PML BI %",
        title: "Minimum PML BI %",
        width: 150,
        type: "number",
        mask: "0.00",
        readOnly: editPmlFlag ? false : true,
      },
      {
        title: "Action",
        width: 50,
        type: editPmlFlag ? "image" : "hidden",
      },
    ];
    setPmlColumnData(pmlColumnData);
  };

  /* ----- Col Generator Risk Function ----- */
  const colGeneratorRisk = () => {
    const pmlColumnDataRisk = [

      {
        name: "PML above INR",
        title: "PML above INR",
        width: 150,
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "Minimum Share %",
        title: "Minimum Share %",
        width: 150,
        type: "number",
        mask: "0.00",
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "Maximum Share %",
        title: "Maximum Share %",
        width: 150,
        readOnly: editPmlFlag ? false : true,
      },
      {
        name: "Capacity %",
        title: "Capacity %",
        width: 150,
        type: "number",
        mask: "0.00",
        readOnly: editPmlFlag ? false : true,
      },
      {
        title: "Action",
        width: 50,
        type: editPmlFlag ? "image" : "hidden",
      },
    ];
    setPmlColumnDataRisk(pmlColumnDataRisk);
  };

  /* ----- API call for get motor data -----*/
  const getPmlData = async () => {
    try {
      const res = await getRequest(
        `${GET_PML_DATA}?month=${selectedMonth}&year=${selectedYear}`
      );
      setMonthClosingValue(res?.data?.data?.is_closed)
      setGetApiData(res?.data?.data?.pmlData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /* ----- API call for get pml Risk -----*/
  const getPmlDataRisk = async () => {
    try {
      const res = await getRequest(
        `${GET_PML_RISK}?month=${selectedMonth}&year=${selectedYear}&product_class=Fire`
      );
      setGetApiDataRisk(res?.data?.data?.riskPmlData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRisk(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getPmlData();
    setEditPmlFlag(false);
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setLoadingRisk(true);
    getPmlDataRisk()
    setEditPmlFlag(false);
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const tempRowData = [];
    if (getApiData?.length !== 0) {
      getApiData?.forEach((data, j) => {
        const { createdAt, updatedAt, month, year, id, ...rest } = data;
        const tempRowObj = {
          "Minimum PML MB %": rest["Minimum PML MB %"],
          "Minimum PML BI %": rest["Minimum PML BI %"],
          minimum_sum_insured: rest["minimum_sum_insured"],
          maximum_sum_insured: rest["maximum_sum_insured"],
        };
        tempRowData.push(tempRowObj);
      });
      setPmlRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [getApiData, editPmlFlag]);

  useEffect(() => {
    const tempRowData = [];
    if (getApiDataRisk?.length !== 0) {
      getApiDataRisk?.forEach((data, j) => {
        const { createdAt, updatedAt, month, year, id, ...rest } = data;
        const tempRowObj = {
          "PML above INR": rest["PML above INR"],
          "Minimum Share %": rest["Minimum Share %"],
          'Maximum Share %': rest["Maximum Share %"],
          'Capacity %': rest["Capacity %"],
        };
        tempRowData.push(tempRowObj);
      });
      setPmlRowDataRisk(tempRowData);
    }
    colGeneratorRisk();
    // eslint-disable-next-line
  }, [getApiDataRisk, editPmlFlag]);

  const options = {
    data: pmlRowData,
    columns: pmlColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    columnSorting: true,
    editable: true,
    updateTable: function (el, cell, x, y, col, source, value, label, items) {
      if (x === pmlColumnData.length - 1) {
        const deleteIcon = document.createElement("img");
        deleteIcon.src = "https://cdn.iconscout.com/icon/free/png-256/free-delete-2902143-2411575.png";
        deleteIcon.style.width = "20px";
        deleteIcon.style.height = "20px";
        deleteIcon.style.cursor = "pointer";

        deleteIcon.addEventListener("click", function (event) {
          event.stopPropagation(); // Stop the event from propagating to the table

          const isConfirmed = window.confirm("Are you sure you want to delete this row?");
          if (isConfirmed) {
            const newData = jspreadsheet(pmlProductRef.current, options).getData();
            newData.splice(y, 1);
            setPmlRowData(newData);
          }
        });
        cell.innerHTML = ''; // Clear existing content
        cell.appendChild(deleteIcon);
      }
    },
  };

  const optionsRisk = {
    data: pmlRowDataRisk,
    columns: pmlColumnDataRisk,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    columnSorting: true,
    editable: true,
    updateTable: function (el, cell, x, y, col, source, value, label, items) {
      if (x === pmlColumnDataRisk.length - 1) {
        const deleteIcon = document.createElement("img");
        deleteIcon.src = "https://cdn.iconscout.com/icon/free/png-256/free-delete-2902143-2411575.png";
        deleteIcon.style.width = "20px";
        deleteIcon.style.height = "20px";
        deleteIcon.style.cursor = "pointer";

        deleteIcon.addEventListener("click", function (event) {
          event.stopPropagation(); // Stop the event from propagating to the table

          const isConfirmed = window.confirm("Are you sure you want to delete this row?");
          if (isConfirmed) {
            const newData = jspreadsheet(pmlProductRiskRef.current, optionsRisk).getData();
            newData.splice(y, 1);
            setPmlRowDataRisk(newData);
          }
        });
        cell.innerHTML = ''; // Clear existing content
        cell.appendChild(deleteIcon);
      }
    },
  };

  useEffect(() => {
    if (pmlRowData?.length !== 0 && !loading) {
      document.getElementById("pmlTable").innerHTML = "";
      jspreadsheet(pmlProductRef.current, options);
    }
    // eslint-disable-next-line
  }, [pmlRowData]);

  useEffect(() => {
    if (pmlRowDataRisk?.length !== 0 && !loadingRisk) {
      document.getElementById("pmlTableRisk").innerHTML = "";
      jspreadsheet(pmlProductRiskRef.current, optionsRisk);
    }
    // eslint-disable-next-line
  }, [pmlRowDataRisk]);

  const savePmlData = async () => {
    setLoading(true);
    const healthRefInstance = pmlProductRef?.current?.jexcel;
    const jexcelRecords = healthRefInstance?.getJson();
    const jsonRecord = JSON?.parse(JSON?.stringify(jexcelRecords));
    const newData = jsonRecord.map(({ "4": _, ...rest }) => {
      const convertedObject = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          typeof value === 'string' && !isNaN(value.replace(/,/g, '')) ? parseFloat(value.replace(/,/g, '')) : value,
        ])
      );
      return convertedObject;
    });

    const pmlSave = {
      pml_data: newData,
      month: selectedMonth,
      year: selectedYear,
    };
    try {
      const res = await patchRequest(`${EDIT_PML_DATA}`, pmlSave);
      successNotification(res?.data?.message);
      getPmlData();
    } catch (error) {
      errorNotification(error.response.data.message);
      getPmlData();
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const savePmlDataRisk = async () => {
    setLoadingRisk(true);
    const healthRefInstance = pmlProductRiskRef?.current?.jexcel;
    const jexcelRecords = healthRefInstance?.getJson();
    const jsonRecord = JSON?.parse(JSON?.stringify(jexcelRecords));
    const newData = jsonRecord.map(({ "4": _, "5": __, ...rest }) => {
      const convertedObject = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          typeof value === 'string' && !isNaN(value.replace(/,/g, '')) ? parseFloat(value.replace(/,/g, '')) : value,
        ])
      );
      return convertedObject;
    });
    const pmlSave = {
      data: newData,
      month: selectedMonth,
      year: selectedYear,
    };
    try {
      const res = await patchRequest(`${UPDATE_PML_RISK}?product_class=Fire`, pmlSave);
      successNotification(res?.data?.message);
      getPmlDataRisk();
    } catch (error) {
      errorNotification(error.response.data.message);
      getPmlDataRisk();
      console.log(error);
    } finally {
      setLoadingRisk(false);
    }
  };

  /* ----- Function date changes  ----- */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{
        height: "25px",
        borderRadius: "4px",
        border: "1px solid #cccccc",
        padding: "10px",
        fontSize: "12px",
        width: "100%",
      }}
    />
  );
  const insertRow = () => {
    const newData = jspreadsheet(pmlProductRef.current, options).getData();
    newData.push(pmlColumnData.map((item) => "")); // Add as many nulls as the number of columns
    setPmlRowData(newData);
  };
  const insertRowRisk = () => {
    const newData = jspreadsheet(pmlProductRiskRef.current, optionsRisk).getData();
    newData.push(pmlColumnDataRisk.map((item) => "")); // Add as many nulls as the number of columns
    setPmlRowDataRisk(newData);
  };

  return (
    <div class="dashboard p-3 pb-md-5 ">
      <div class="row justify-content-between">
        <div class="col sub-header ">
          <h3 className="mb-lg-0 mb-2">{`PML Calculation (${selectedMonthName}-${selectedYear})`}</h3>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12 ">
          <label htmlFor="product" className="dropdown-title">
            Month/Year
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showMonthYearPicker
            dateFormat="MM/yyyy"
            customInput={<CustomInput />}
          />
        </div>
        <div className="col-12 mt-3 position-relative">
          {loading ? (
            <div className="row">
              <div className="col text-center py-5">
                <BeatLoader />
              </div>
            </div>
          ) : getApiData?.length === 0 ? (
            <div className="row">
              <div className="col text-center py-5">No Data Available</div>
            </div>
          ) : (
            <div class="row">
              <div>
                {!monthClosingValue && (
                  <form style={{ float: "right", marginTop: "10px" }}>
                    <label
                      htmlFor="pivot"
                      className="pe-2"
                      style={{ fontSize: "14px" }}
                    >
                      Edit PML
                    </label>
                    <input
                      id="pivot"
                      type="checkbox"
                      value={editPmlFlag}
                      checked={editPmlFlag}
                      onChange={() => setEditPmlFlag(!editPmlFlag)}
                    />
                  </form>
                )}
              </div>
              <div class="col-12">
                <div ref={pmlProductRef} id="pmlTable" />
                {editPmlFlag && (
                  <div className="d-flex justify-content-start">
                    <CustomButton
                      type="submit"
                      className="mainBtn float-end btn-sm mt-2"
                      style={{ marginRight: "545px" }}
                      handleClick={() => insertRow()}
                    >
                      Add
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      className="mainBtn float-end btn-sm mt-2"
                      handleClick={() => savePmlData()}
                    >
                      Enter For Save
                    </CustomButton>
                  </div>
                )}
              </div>
            </div>
          )}
          {loadingRisk ? (
            <div className="row">
              <div className="col text-center py-5">
                <BeatLoader />
              </div>
            </div>
          ) : getApiDataRisk?.length === 0 ? (
            <div className="row">
              <div className="col text-center py-5">No Data Available</div>
            </div>
          ) : (<div class="col-12 mt-5">
            <div class="col sub-header ">
              <h3 className="mb-lg-0 mb-2">{`Risk PML (${selectedMonthName}-${selectedYear})`}</h3>
            </div>
            <div ref={pmlProductRiskRef} id="pmlTableRisk" />
            {editPmlFlag && (
              <div className="d-flex justify-content-start">
                <CustomButton
                  type="submit"
                  className="mainBtn float-end btn-sm mt-2"
                  style={{ marginRight: "545px" }}
                  handleClick={() => insertRowRisk()}
                >
                  Add
                </CustomButton>
                <CustomButton
                  type="submit"
                  className="mainBtn float-end btn-sm mt-2"
                  handleClick={() => savePmlDataRisk()}
                >
                  Enter For Save
                </CustomButton>
              </div>
            )}
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default FirePmlCalculation;
