import jspreadsheet from "jspreadsheet-ce";
import { debounce } from "lodash";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import { getRequest, postRequest } from "../../../helper/AxiosClient";
import { customStylesOfSelect } from "../../../helper/customStyleOfSelect";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import UploadControl from "../../../helper/UploadControl";
import CustomButton from "../../common/CustomButton";
import {
  GET_PRODUCT_LIST,
  GET_ROW_DATA,
  UPLOAD_RAW_DATA,
} from "../../routes/ApiEndPoin";
import { columnRawData, openingHcColumnDatas } from "./ColumnData";

const RiRawData = () => {
  const pageSize = 15;
  const [fireProductRowData, setFireProductRowData] = useState([]);
  const [fireProductColumnData, setFireProductColumnData] = useState();
  const [loading, setLoading] = useState(true);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [count, setCount] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState([]);
  const [submitToggle, setSubmitToggle] = useState(true);
  const [getApiData, setGetApiData] = useState([]);
  const [selectedParameter, setSelectedParameter] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [productList, setProductList] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const fireProductRef = useRef();
  const [monthClosingValue, setMonthClosingValue] = useState(false);

  const selectedMonth = parseInt(selectedDate?.getMonth() + 1);
  const selectedYear = selectedDate?.getFullYear();

  /* ----- increment/decrement Function for pagination page count -----*/
  const increment = () => {
    setCount((prevActiveStep) => prevActiveStep + 1);
  };
  const decrement = () => {
    setCount((prevActiveStep) => prevActiveStep - 1);
  };
  /* ----- Function for Excel data Read ----- */
  const readUploadFile = async (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await postRequest(`${UPLOAD_RAW_DATA}?month=${selectedMonth}&year=${selectedYear}`, formData);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          getRowDataList();
          getProductList();
          setLoading(false);
          e.target.value = "";
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
          setLoading(false);
          e.target.value = "";
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setSubmitToggle(true);
    setLoading(false);
    setUploadFlag(false);
    // getOpeningHc()
  }, [count, selectedParameter]);

  /* ----- Col Generator Function ----- */
  const colGenerator = () => {
    setFireProductColumnData(openingHcColumnDatas);
  };

  /* ----- Create Row Data ----- */
  useEffect(() => {
    if (getApiData) {
      const tempRowData = [];
      getApiData?.forEach((data, j) => {
        const { createdAt, updatedAt, id, ...rest } = data;
        const tempRowObj = columnRawData(count, j, rest);
        tempRowData.push(tempRowObj);
      });
      setFireProductRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [getApiData]);

  const options = {
    data: fireProductRowData,
    columns: fireProductColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    ...(uploadFlag && { pagination: 15 }),
    columnSorting: true,
    updateTable: function (el, cell, x, y, source, value, label) {
      if (value === null || value === "") {
        cell.innerHTML = "-";
      }
    },
  };

  /* ----- Feed Row and col data to ref ----- */
  useEffect(() => {
    if (
      fireProductColumnData?.length !== 0 &&
      fireProductRowData?.length !== 0 &&
      !loading &&
      getApiData?.length !== 0
    ) {
      document.getElementById("openingHcId").innerHTML = "";
      jspreadsheet(fireProductRef.current, options).hideIndex();
    }
    // eslint-disable-next-line
  }, [fireProductColumnData, fireProductRowData, loading, getApiData]);

  /* ----- Function for sample upload file Download ----- */
  const handleSampleFileDownload = () => {
    fetch("sample_file_fire_product.csv").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "sample_file_fire_product.csv";
        alink.click();
      });
    });
  };

  const getRowDataList = async () => {
    try {
      const res = await getRequest(
        `${GET_ROW_DATA}?page=${count}&limit=${pageSize}&month=${selectedMonth}&year=${selectedYear}&search=${searchVal}&product_class=${selectedParameter?.product?.value
          ? selectedParameter?.product?.value
          : ""
        }`
      );
      setGetApiData(res?.data?.data?.rowData?.data);
      setLastPageNumber(res?.data?.data?.rowData?.page_Information?.lastpage);
      setMonthClosingValue(res?.data?.data?.is_closed)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getProductList = async () => {
    try {
      const res = await getRequest(
        `${GET_PRODUCT_LIST}?month=${selectedMonth}&year=${selectedYear}`
      );
      setProductList(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedGetAllItem = debounce(getRowDataList, 300);

  useEffect(() => {
    setLoading(true);
    debouncedGetAllItem();
    return () => {
      debouncedGetAllItem.cancel();
    };
    // eslint-disable-next-line
  }, [count, selectedMonth, selectedYear, searchVal, selectedParameter]);

  useEffect(() => {
    getProductList();
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  /* ----- Function date changes  ----- */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <input
        ref={ref}
        type="text"
        value={value}
        onClick={onClick}
        readOnly
        style={{
          height: "25px",
          borderRadius: "4px",
          border: "1px solid #cccccc",
          padding: "10px",
          fontSize: "12px",
          width: "100%",
        }}
      />
    );
  });
  return (
    <section className="dashboard p-3 pb-md-5">
      <div className="container-fluid">
        <div className="sub-header row pb-sm-2">
          <div className="col-xl-3 col-lg-12">
            <h3 className="mb-lg-0 mb-2">Raw data</h3>
          </div>
          <div className="col-xl-9 col-12 mt-xxl-0 mt-2">
            <div class="row justify-content-end">
              <div class="col-xxl-2 col-lg-3 col-md-4 col-12 mt-xl-0 mt-2 z-3">
                <label htmlFor="product" className="dropdown-title">
                  Month/Year
                </label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  customInput={<CustomInput />}
                />
              </div>
              <div class="col-xxl-2 col-lg-3 col-md-4 col-12 mt-xl-0 mt-2">
                <label htmlFor="product" className="dropdown-title">
                  Product
                </label>
                <Select
                  id="product"
                  name="product"
                  options={[
                    { value: "", label: "Select Product" },
                    ...(productList &&
                      productList.map((product) => ({
                        value: product["original_product_class"],
                        label: product["original_product_class"],
                      }))),
                  ]}
                  value={selectedParameter?.product}
                  className="ms-1"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  styles={customStylesOfSelect}
                  onChange={(e) =>
                    setSelectedParameter(
                      { ...selectedParameter, product: e },
                      setCount(1)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 overflow-auto mt-3">
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center">
                {!monthClosingValue && (
                  <div className="col d-flex justify-content-end align-items-center">
                    <CustomButton
                      className="mainBtn me-3 btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Import Sample Template"
                      onClick={handleSampleFileDownload}
                    >
                      Format for upload
                    </CustomButton>
                    <CustomButton
                      className="mainBtn btn-sm"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Import csv"
                    >
                      <UploadControl onChange={readUploadFile}>
                        Upload
                      </UploadControl>
                    </CustomButton>
                  </div>
                )}
              </div>
            </div>
            {getApiData?.length !== 0 && <div className="col-xl-3 col-lg-12">
              <span
                style={{
                  color: "#212529",
                  fontSize: "14px",
                  marginRight: "5px",
                }}
              >
                Search :
              </span>
              <input
                style={{
                  border: "1px solid #8d8e91",
                  borderRadius: "4px",
                  padding: "2px",
                  color: "#8d8e91",
                  fontSize: "14px",
                }}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </div>}
            {loading ? (
              <div className="row">
                <div className="col text-center py-5">
                  <BeatLoader />
                </div>
              </div>
            ) : (
              <div className="row position-relative">
                <div className="col">
                  {getApiData?.length === 0 ? (
                    <div className="row">
                      <div className="col text-center py-5">No Data Available</div>
                    </div>
                  ) : <div className="actual_gwp">
                    <div className="d-flex flex-column">
                      <div className="mt-3 d-flex justify-content-between overflow-auto">
                        <div ref={fireProductRef} id="openingHcId" />
                      </div>
                    </div>
                  </div>}
                  {getApiData?.length !== 0 && submitToggle ? (
                    <div className="mt-2 d-flex justify-content-between">
                      <div className="pageNumber">
                        Page {count} of {lastPageNumber}
                      </div>
                      <div>
                        <CustomButton
                          type="button"
                          className="mainBtn float-end btn-sm ms-3"
                          disabled={lastPageNumber <= count}
                          onClick={increment}
                        >
                          Next
                        </CustomButton>
                        <CustomButton
                          type="button"
                          className="mainBtn float-end btn-sm "
                          disabled={count === 1}
                          onClick={decrement}
                        >
                          Prev
                        </CustomButton>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RiRawData;
