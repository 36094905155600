import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { deleteRequest, getRequest } from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification
} from "../../../helper/notification";
import TableOfDetails from "../../../helper/TableOfDetails";
import CustomButton from "../../common/CustomButton";
import { DELETE_USER, GET_ALL_USER } from "../../routes/ApiEndPoin";
import AddNewUser from './AddNewUser';

const UsersDetails = () => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userEditData, setUserEditData] = useState({})

  const routeMapping = {
    "/row-data": "Raw Data",
    "/report-data": "Report Data",
    "/allocation-master": "Allocation Master",
    "/fire-pml-calculation": "Fire PML",
    "/engineering-pml-calculation": "Engineering PML Calculation",
    "/fire-slabs-treaty": "Fire Slabs & Treaty",
    "/user-details": "User Details",
    "/ipInfo": "IP Info",
    "/month-closing": "Month Closing",
    "/history": "History",
  };

  /* ----- Prepare Column Data -----*/
  const columnData = useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "username",
      },
      {
        Header: "Email Id",
        accessor: "email",
      },
      {
        Header: "User Privilages",
        accessor: "user_privilages",
        Cell: (row) => {
          return (
            <span className="row">
              {row?.row?.original?.user_privileges?.map((upName, i) => (
                <span className="col-8" key={i}>
                  {routeMapping[upName]}
                </span>
              ))}
            </span>
          );
        },
        minWidth: "350px",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (row) => (
          <div className="text-center">
            <button
              className="btn btn-secondary btn-sm me-3"
              type="button"
              onClick={() => handleEditUserData(row)}
            >
              <MdEdit />
            </button>
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={() => handleDeleteUserData(row)}
            >
              <AiFillDelete />
            </button>
          </div>
        ),
        minWidth: "150px",
      },
    ],
    // eslint-disable-next-line
    []
  );

  /* ----- API call for get User data -----*/
  const getUser = async () => {
    try {
      const res = await getRequest(GET_ALL_USER, null, navigate);
      setUserData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /* ----- API call for Delete User data -----*/
  const deleteUserData = async (id) => {
    try {
      const res = await deleteRequest(DELETE_USER + id, navigate);
      if (res.type === 1) {
        getUser();
        successNotification("User deleted successfully");
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* ----- trigger API call On Page Render -----*/
  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  /* ----- Handle for Edit User -----*/
  const handleEditUserData = (row) => {
    const userEditData = row.row.original;
    setUserEditData(userEditData)
    setModalShow(true)
    // navigate(`/edit-user/${userId}`, { state: { userEditData } });
  };

  /* ----- Handle for Delete User -----*/
  const handleDeleteUserData = (row) => {
    const userId = row.row.original.id;
    if (window.confirm("Are you sure you want to delete this user data?")) {
      deleteUserData(userId);
    }
  };
  const [modalShow, setModalShow] = React.useState(false);
  const onCloseModal = () => {
    setModalShow(false);
    setUserEditData({})
  }

  return (
    <>
      <section className="admin_dashboard dashboard p-3 pb-md-5">
        <div className="container-fluid">
          <div className="sub-header row">
            <div className="col">
              <h3 className="mb-lg-0 mb-2">User list</h3>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                <span data-bs-target="#addUser">
                  <CustomButton
                    type="submit"
                    className="mainBtn btn-sm"
                    // onClick={() => navigate(ROUTES.ADD_NEW_USER)}
                    onClick={() => setModalShow(true)}
                  >
                    Add User +
                  </CustomButton>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 dashboard_family mt-3 detailsTable">
              {loading ? (
                <div className="row">
                  <div className="col text-center py-5">
                    <BeatLoader />
                  </div>
                </div>
              ) : userData?.length === 0 ? (
                <div className="row">
                  <div className="col text-center py-5">No Data Available</div>
                </div>
              ) : (
                <>
                  <TableOfDetails columns={columnData} data={userData} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* ------- Modal For IP Address ------- */}
      <Modal centered show={modalShow} onHide={() => onCloseModal()}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px", fontWeight: "600" }}>
            User Details
          </Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <Form.Group className="mb-3">
              <AddNewUser userEditData={userEditData} onCloseModal={onCloseModal} getUser={getUser} />
            </Form.Group>
          </Modal.Body>
        </Form>
      </Modal>
    </>
  );
};

export default UsersDetails;
