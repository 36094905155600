import { getRequest } from "../../../helper/AxiosClient";
import { GET_ALL_DROPDOWN_LIST } from "../../routes/ApiEndPoin";

export const getClassApiCall = async (setLoading, setClassList, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=original_product_class&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data.map((data) => ({
            value: data['original_product_class'],
            label: data['original_product_class'],
        }))
        list.unshift({ value: "All", label: "All" });
        setClassList(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
export const getTranIdApiCall = async (setLoading, setTranIdList, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=Tran ID&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['Tran ID'],
            label: data['Tran ID'],
        }))
        list.unshift({ value: "All", label: "All" });
        setTranIdList(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
export const getInsuredNameApiCall = async (setLoading, setInsuredName, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=Insured Name&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['Insured Name'],
            label: data['Insured Name'],
        }))
        list.unshift({ value: "All", label: "All" });
        setInsuredName(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
export const getBranchCodeApiCall = async (setLoading, setBranchCode, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=Branch Code&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['Branch Code'],
            label: data['Branch Code'],
        }))
        list.unshift({ value: "All", label: "All" });
        setBranchCode(list);
    } catch (error) {
        console.log(error);

    } finally {
        setLoading(false);
    }
};
export const getProductCodeApiCall = async (setLoading, setProductCode, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=Product Code&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['Product Code'],
            label: data['Product Code'],
        }))
        list.unshift({ value: "All", label: "All" });
        setProductCode(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
export const getPolicyNoApiCall = async (setLoading, setPolicyNo, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=Policy No&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['Policy No'],
            label: data['Policy No'],
        }))
        list.unshift({ value: "All", label: "All" });
        setPolicyNo(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};
export const getUwYearApiCall = async (setLoading, setUwYear, selectedYear, selectedMonth, selectedParameter) => {
    setLoading(true);
    const transformedObject = {};
    for (const key in selectedParameter) {
        if (selectedParameter.hasOwnProperty(key)) {
            const filteredArray = selectedParameter[key].filter(
                (item) => item.value !== undefined
            );
            if (filteredArray.length > 0) {
                transformedObject[key] = filteredArray.map((item) => item.value);
            }
        }
    }
    try {
        const res = await getRequest(`${GET_ALL_DROPDOWN_LIST}?month=${selectedMonth}&year=${selectedYear}&group_by=U/W Year&product_class=${transformedObject?.class ? transformedObject?.class : ""
            }`);
        const list = res?.data?.data?.map((data) => ({
            value: data['U/W Year'],
            label: data['U/W Year'],
        }))
        list.unshift({ value: "All", label: "All" });
        setUwYear(list);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};


export const CustomInput = ({ value, onClick }) => (
    <input
        type="text"
        value={value}
        onClick={onClick}
        style={{
            height: "38px",
            borderRadius: "4px",
            border: "1px solid #cccccc",
            padding: "10px",
            fontSize: "12px",
            width: "100%",
        }}
    />
);