export const reportDataColumn = [
  {
    type: 'checkbox',
    title: 'Rectification',
    width: 80,
    name: "reversed",
  },
  {
    title: 'rectification_status',
    width: 50,
    name: "rectification_status",
    type: 'hidden',
  },
  {
    name: "sr_no",
    title: "SR NO",
    readOnly: true,
    wordWrap: true,
    width: 50,
  },
  {
    name: "id",
    title: "id",
    readOnly: true,
    wordWrap: true,
    width: 50,
    type: "hidden",
  },
  {
    name: "100% SI",
    title: "100% SI",
    readOnly: true,
    wordWrap: true,
    type: "hidden",
  },
  {
    name: "Product Class",
    title: "Product Class",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Sub_System",
    title: "Sub System",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Prod & Yr",
    title: "Prod & Yr",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI BAND",
    title: "SI Band",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Dept Code",
    title: "Dept Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "System ID",
    title: "System ID",
    readOnly: true,
    wordWrap: true,
    width: 180,
  },
  {
    name: "Tran ID",
    title: "Tran ID",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "RiskLocationAddress",
    title: "Risk Location Address",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "RiskCode",
    title: "Risk Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Proposal/CoverNote No",
    title: "Proposal/CoverNote No",
    readOnly: true,
    wordWrap: true,
    width: 120,
  },
  {
    name: "Proposal Date",
    title: "Proposal Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Policy/Endt Issue Date",
    title: "Policy/Endt Issue Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Insured Name",
    title: "Insured Name",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Address of Insured",
    title: "Address of Insured",
    readOnly: true,
    wordWrap: true,
    width: 450,
  },
  {
    name: "Name of  City / Town / Village",
    title: "Name of  City / Town / Village",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Pin Code",
    title: "Pin Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Branch Code",
    title: "Branch Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Product Code",
    title: "Product Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Policy No",
    title: "Policy No",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "U/W Year",
    title: "U/W Year",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Endt Type",
    title: "Endt Type",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Endt No",
    title: "Endt No",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Endt Year",
    title: "Endt Year",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Policy Start Date",
    title: "Policy Start Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Policy End Date",
    title: "Policy End Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "No.of Lives Covered",
    title: "No.of Lives Covered",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Sum Insured",
    title: "Sum Insured",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Per Bottom Limit",
    title: "Per Bottom Limit",
    readOnly: true,
    wordWrap: true,
    width: 110,
  },
  {
    name: "Marine Premium/ Gross Premium",
    title: "Marine Premium/ Gross Premium",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Net OD/War",
    title: "Net OD/War",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Net TP/SRCC",
    title: "Net TP/SRCC",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Premium",
    title: "Premium",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Terrorism",
    title: "Terrorism",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Net Premium",
    title: "Net Premium",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Fee Charged",
    title: "Fee Charged",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Service Tax",
    title: "Service Tax",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Service Levied Tax",
    title: "Service Levied Tax",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Education Cess",
    title: "Education Cess",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "H. Education Cess",
    title: "H. Education Cess",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  { name: "ERF", title: "ERF", readOnly: true, wordWrap: true, width: 80 },
  {
    name: "Stamp Duty",
    title: "Stamp Duty",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Total Premium",
    title: "Total Premium",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Payment Mode",
    title: "Payment Mode",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "CD Account No. (If Payment Mode is CD)",
    title: "CD Account No. (If Payment Mode is CD)",
    readOnly: true,
    wordWrap: true,
    width: 120,
  },
  {
    name: "Stamp Duty (Paid by RGIC)",
    title: "Stamp Duty (Paid by RGIC)",
    readOnly: true,
    wordWrap: true,
    width: 120,
  },
  {
    name: "Agent/Broker Name",
    title: "Agent/Broker Name",
    readOnly: true,
    wordWrap: true,
    width: 150,
  },
  {
    name: "Agent/Broker Code",
    title: "Agent/Broker Code",
    readOnly: true,
    wordWrap: true,
    width: 110,
  },
  {
    name: "Commission Rate",
    title: "Commission Rate",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Commission Amount",
    title: "Commission Amount",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "NRMR Name",
    title: "NRMR Name",
    readOnly: true,
    wordWrap: true,
    width: 120,
  },
  {
    name: "NRMR Code",
    title: "NRMR Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SM Name",
    title: "SM Name",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SM Code",
    title: "SM Code",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "IMD Ref Code",
    title: "IMD Ref Code",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Coinsurance Status",
    title: "Coinsurance Status",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Leader's Name",
    title: "Leader's Name",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Our Share %",
    title: "Our Share %",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Others Share %",
    title: "Others Share %",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Co-Insurers",
    title: "Co-Insurers",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Remarks (Coins)",
    title: "Remarks (Coins)",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Service Tax Exempted Reason",
    title: "Service Tax Exempted Reason",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Vessel/Conveyance",
    title: "Vessel/Conveyance",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Cargo",
    title: "Cargo",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Packing",
    title: "Packing",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "B/L-R/R No",
    title: "B/L-R/R No",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "B/L-R/R Date",
    title: "B/L-R/R Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Voyage From",
    title: "Voyage From",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Voyage To",
    title: "Voyage To",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Model No (Motor)",
    title: "Model No (Motor)",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Make (Motor)",
    title: "Make (Motor)",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Business Type",
    title: "Business Type",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Prev Policy No. (Ren)",
    title: "Prev Policy No. (Ren)",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Remarks",
    title: "Remarks",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "System Posting Date",
    title: "System Posting Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Payee Name (Refund)",
    title: "Payee Name (Refund)",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Channel",
    title: "Channel",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Remarks (VMG)",
    title: "Remarks (VMG)",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Prev. Trans ID",
    title: "Prev. Trans ID",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "Source",
    title: "Source",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Name (Source)",
    title: "Name (Source)",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "DPR Date",
    title: "DPR Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SAP Posting Date",
    title: "SAP Posting Date",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  { name: "Zone", title: "Zone", readOnly: true, wordWrap: true, width: 80 },
  {
    name: "BA Code",
    title: "BA Code",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Profit Centre",
    title: "Profit Centre",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Region",
    title: "Region",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Policy Count",
    title: "Policy Count",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "New_Posting_Date",
    title: "New Posting Date",
    readOnly: true,
    width: 100,
  },
  {
    name: "ODdiscPerc",
    title: "ODdiscPerc",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "ODdiscAmt",
    title: "ODdiscAmt",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "NCBPerc",
    title: "NCBPerc",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "NCBAmt",
    title: "NCBAmt",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "ICM_TRAN_ID",
    title: "ICM TRAN ID",
    readOnly: true,
    wordWrap: true,
    width: 110,
  },
  {
    name: "RIFlag",
    title: "RI Flag",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RateCode",
    title: "Rate Code",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RiskPinCode",
    title: "Risk PinCode",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "NoOfLocations",
    title: "No Of Locations",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "HighestSI",
    title: "Highest SI",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Hazard_Category",
    title: "Hazard Category",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "DeclinedRiskYN",
    title: "Declined Risk YN",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "QualityOfRisk",
    title: "Quality Of Risk",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RiskCity",
    title: "Risk City",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RiskDistrict",
    title: "Risk District",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RIskState",
    title: "RIsk State",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "DWH_Remark",
    title: "DWH Remark",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "HazardGrade",
    title: "Hazard Grade",
    readOnly: true,
    wordWrap: true,
    width: 110,
  },
  {
    name: "OccupancyCode",
    title: "Occupancy Code",
    readOnly: true,
    wordWrap: true,
    width: 110,
  },
  {
    name: "HighestBISI",
    title: "Highest BISI",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "HighestMDSI",
    title: "Highest MDSI",
    readOnly: true,
    wordWrap: true,
    width: 90,
  },
  {
    name: "PML_PERCENT",
    title: "PML PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 90,
  },
  {
    name: "PML_AMOUNT",
    title: "PML AMOUNT",
    readOnly: false,
    wordWrap: true,
    width: 90,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "COINSURANCE_PERCENT",
    title: "COINSURANCE PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 90,
  },
  {
    name: "SI_RETENTION",
    title: "SI RETENTION",
    readOnly: true,
    wordWrap: true,
    width: 90,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_OBLIGATORY",
    title: "SI OBLIGATORY",
    readOnly: true,
    wordWrap: true,
    width: 90,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_VOLUNTARY_QUOTA",
    title: "SI VOLUNTARY QUOTA",
    readOnly: true,
    wordWrap: true,
    width: 90,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_SURPLUS_TREATY_1",
    title: "SI SURPLUS TREATY 1",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_SURPLUS_TREATY_2",
    title: "SI SURPLUS TREATY 2",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_MARKET_SURPLUS_TREATY",
    title: "SI MARKET SURPLUS TREATY",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_FACULATIVE_1",
    title: "SI FACULTATIVE 1",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_FACULATIVE_2",
    title: "SI FACULTATIVE 2",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "SI_FACULATIVE_3",
    title: "SI FACULTATIVE 3",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_MOTOR_TP_POOL",
    title: "SI MOTOR TP POOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_HULL_WA",
    title: "SI HULL WA",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Net Calculation",
    title: "Net Calculation",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MARINE_OBLIGATORY_PERCENT",
    title: "RI MARINE OBLIGATORY PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_WAR_OBLIGATORY_PERCENT",
    title: "RI WAR OBLIGATORY PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SRCC_OBLIGATORY_PERCENT",
    title: "RI SRCC OBLIGATORY PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },

  {
    name: "RI_OBLIGATORY_PERCENT",
    title: "RI OBLIGATORY PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_PERCENT",
    title: "RI VOLUNTARY QUOTA PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_RETENTION_PERCENT",
    title: "RI RETENTION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SURPLUS_TREATY_1_PERCENT",
    title: "RI SURPLUS TREATY 1 PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SURPLUS_TREATY_2_PERCENT",
    title: "RI SURPLUS TREATY 2 PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MARKET_SURPLUS_TREATY_PERCENT",
    title: "RI MARKET SURPLUS TREATY PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_1",
    title: "RI FACULTATIVE PERCENT 1",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_2",
    title: "RI FACULATIVE PERCENT 2",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_3",
    title: "RI FACULATIVE PERCENT 3",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MOTOR_TP_POOL_PERCENT",
    title: "RI MOTOR TP POOL PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_HULL_WAR_PERCENT",
    title: "RI HULL WAR PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_MARINE_OBLIGATORY",
    title: "RI PREMIUM MARINE OBLIGATORY",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_WAR__OBLIGATORY",
    title: "RI PREMIUM WAR OBLIGATORY",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_SRCC_OBLIGATORY",
    title: "RI PREMIUM SRCC OBLIGATORY",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_OBLIGATORY",
    title: "RI PREMIUM OBLIGATORY",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_VOLUNTARY_QUOTA",
    title: "RI PREMIUM VOLUNTARY QUOTA",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI__PREMIUM_RETENTION",
    title: "RI PREMIUM RETENTION",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_SURPLUS_TREATY_1",
    title: "RI PREMIUM SURPLUS TREATY 1",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_SURPLUS_TREATY_2",
    title: "RI PREMIUM SURPLUS TREATY 2",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_MARKET_SURPLUS_TREATY",
    title: "RI PREMIUM MARKET SURPLUS TREATY",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_1",
    title: "RI PREMIUM FACULATIVE 1",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_PREMIUM_FACULATIVE_2",
    title: "RI PREMIUM FACULATIVE 2",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_3",
    title: "RI PREMIUM FACULATIVE 3",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_MOTOR_TP_POOL",
    title: "RI PREMIUM MOTOR TP POOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_HULL_WAR",
    title: "RI PREMIUM HULL WAR",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MARINE_OBLIGATORY_COMMISSION_PERCENTAGE",
    title: "RI MARINE OBLIGATORY COMMISSION PERCENTAGE",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_WAR_OBLIGATORY_COMMISSION_PERCENTAGE",
    title: "RI WAR OBLIGATORY COMMISSION PERCENTAGE",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SRCC_OBLIGATORY_COMMISSION_PERCENTAGE",
    title: "RI SRCC OBLIGATORY COMMISSION PERCENTAGE",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_COMMISSION_PERCENT",
    title: "RI OBLIGATORY COMMISSION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_COMMISSION_PERCENT",
    title: "RI VOLUNTARY QUOTA COMMISSION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SURPLUS_TREATY_1_COMMISSION_PERCENT",
    title: "RI SURPLUS TREATY 1 COMMISSION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_SURPLUS_TREATY_2_COMMISSION_PERCENT",
    title: "RI SURPLUS TREATY 2 COMMISSION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MARKET_SURPLUS_TREATY_COMMISSION_PERCENT",
    title: "RI MARKET SURPLUS TREATY COMMISSION PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_1",
    title: "RI FACULATIVE COMMISSION PERCENT 1",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_2",
    title: "RI FACULATIVE COMMISSION PERCENT 2",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_3",
    title: "RI FACULATIVE COMMISSION PERCENT 3",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MOTOR_TP_POOL_COMMISSION_PERCENT",
    title: "RI MOTOR TP POOL COMMISSION PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_HULL_WAR_COMMISSION_PERCENT",
    title: "RI HULL WAR COMMISSION PERCENT",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_MARINE_OBLIGATORY_COMMISSION_AMOUNT",
    title: "RI MARINE OBLIGATORY COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_WAR_OBLIGATORY_COMMISSION_AMOUNT",
    title: "RI WAR OBLIGATORY COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_SRCC_OBLIGATORY_COMMISSION_AMOUNT",
    title: "RI SRCC OBLIGATORY COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_OBLIGATORY_COMMISSION_AMOUNT",
    title: "RI OBLIGATORY COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_VOLUNTARY_QUOTA_COMMISSION_AMOUNT",
    title: "RI VOLUNTARY QUOTA COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_SURPLUS_TREATY_1_COMMISSION_AMOUNT",
    title: "RI SURPLUS TREATY 1 COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_SURPLUS_TREATY_2_COMMISSION_AMOUNT",
    title: "RI SURPLUS TREATY 2 COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_MARKET_SURPLUS_TREATY_COMMISSION_AMOUNT",
    title: "RI MARKET SURPLUS TREATY COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_1",
    title: "RI FACULATIVE COMMISSION AMOUNT 1",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_2",
    title: "RI FACULATIVE COMMISSION AMOUNT 2",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_3",
    title: "RI FACULATIVE COMMISSION AMOUNT 3",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_MOTOR_TP_POOL_COMMISSION_AMOUNT",
    title: "RI MOTOR TP POOL COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_HULL_WAR_COMMISSION_AMOUNT",
    title: "RI HULL WAR COMMISSION AMOUNT",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "FACULTATIVE_REINSURER_1",
    title: "FACULTATIVE REINSURER 1",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_2",
    title: "FACULTATIVE REINSURER 2",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_3",
    title: "FACULTATIVE REINSURER 3",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_1",
    title: "FACULTATIVE REINSURER CODE 1",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_2",
    title: "FACULTATIVE REINSURER CODE 2",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_3",
    title: "FACULTATIVE REINSURER CODE 3",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_TERRORISM",
    title: "RI PREMIUM TERRORISM",
    readOnly: true,
    wordWrap: true,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "RI_TERRORISM_COMMISSION",
    title: "RI TERRORISM COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_RETENTION_OD",
    title: "RI PREMIUM RETENTION OD",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_RETENTION_TP",
    title: "RI PREMIUM RETENTION TP",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_OD_PREMIUM",
    title: "RI OBLIGATORY OD PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_TP_PREMIUM",
    title: "RI OBLIGATORY TP PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_OD_PREMIUM",
    title: "RI VOLUNTARY QUOTA OD PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_TP_PREMIUM",
    title: "RI VOLUNTARY QUOTA TP PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULTATIVE_OD_PREMIUM",
    title: "RI FACULTATIVE OD PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULTATIVE_TP_PREMIUM",
    title: "RI FACULTATIVE TP PREMIUM",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_OD_COMMISSION",
    title: "RI OBLIGATORY OD COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_TP_COMMISSION",
    title: "RI OBLIGATORY TP COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_OD_COMMISSION",
    title: "RI VOLUNTARY QUOTA OD COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_TP_COMMISSION",
    title: "RI VOLUNTARY QUOTA TP COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULTATIVE_OD_COMMISSION",
    title: "RI FACULTATIVE OD COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULTATIVE_TP_COMMISSION",
    title: "RI FACULTATIVE TP COMMISSION",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "PROPORTIONAL_RI_REMARKS",
    title: "PROPORTIONAL RI REMARKS",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "WHOLE_ACCOUNT_XL",
    title: "WHOLE ACCOUNT XL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "MISCELLANEOUS_XOL",
    title: "MISCELLANEOUS XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "TRAVEL_XOL",
    title: "TRAVEL XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "GPA_JPA_XOL",
    title: "GPA JPA XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "MARINE_XOL",
    title: "MARINE XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "PUBLIC_LIABILITY_XOL",
    title: "PUBLIC LIABILITY XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "HEALTH_XOL",
    title: "HEALTH XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "MOTOR_OD_XOL",
    title: "MOTOR OD XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "MOTOR_TP_XOL",
    title: "MOTOR TP XOL",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "NON_PROPORTIONAL_RI_REMARKS",
    title: "NON PROPORTIONAL RI REMARKS",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Reinsurer",
    title: "Reinsurer",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI Remark",
    title: "RI Remark",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Product Description",
    title: "Product Description",
    readOnly: true,
    wordWrap: true,
  },
  {
    name: "RI_FACULATIVE_PERCENT_4",
    title: "RI FACULATIVE PERCENT 4",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_5",
    title: "RI FACULATIVE PERCENT 5",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_6",
    title: "RI FACULATIVE PERCENT 6",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_7",
    title: "RI FACULATIVE PERCENT 7",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_8",
    title: "RI FACULATIVE PERCENT 8",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_9",
    title: "RI FACULATIVE PERCENT 9",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_PERCENT_10",
    title: "RI FACULATIVE PERCENT 10",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_4",
    title: "SI FACULATIVE 4",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_5",
    title: "SI FACULATIVE 5",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_6",
    title: "SI FACULATIVE 6",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_7",
    title: "SI FACULATIVE 7",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_8",
    title: "SI FACULATIVE 8",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_9",
    title: "SI FACULATIVE 9",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "SI_FACULATIVE_10",
    title: "SI FACULATIVE 10",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_4",
    title: "RI PREMIUM FACULATIVE 4",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_5",
    title: "RI PREMIUM FACULATIVE 5",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_6",
    title: "RI PREMIUM FACULATIVE 6",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_7",
    title: "RI PREMIUM FACULATIVE 7",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_8",
    title: "RI PREMIUM FACULATIVE 8",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_9",
    title: "RI PREMIUM FACULATIVE 9",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_FACULATIVE_10",
    title: "RI PREMIUM FACULATIVE 10",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_4",
    title: "RI FACULATIVE COMMISSION PERCENT 4",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_5",
    title: "RI FACULATIVE COMMISSION PERCENT 5",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_6",
    title: "RI FACULATIVE COMMISSION PERCENT 6",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_7",
    title: "RI FACULATIVE COMMISSION PERCENT 7",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_8",
    title: "RI FACULATIVE COMMISSION PERCENT 8",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_9",
    title: "RI FACULATIVE COMMISSION PERCENT 9",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_PERCENT_10",
    title: "RI FACULATIVE COMMISSION PERCENT 10",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_4",
    title: "RI FACULATIVE COMMISSION AMOUNT 4",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_5",
    title: "RI FACULATIVE COMMISSION AMOUNT 5",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_6",
    title: "RI FACULATIVE COMMISSION AMOUNT 6",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_7",
    title: "RI FACULATIVE COMMISSION AMOUNT 7",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_8",
    title: "RI FACULATIVE COMMISSION AMOUNT 8",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_9",
    title: "RI FACULATIVE COMMISSION AMOUNT 9",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_FACULATIVE_COMMISSION_AMOUNT_10",
    title: "RI FACULATIVE COMMISSION AMOUNT 10",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_4",
    title: "FACULTATIVE REINSURER 4",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_5",
    title: "FACULTATIVE REINSURER 5",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_6",
    title: "FACULTATIVE REINSURER 6",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_7",
    title: "FACULTATIVE REINSURER 7",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_8",
    title: "FACULTATIVE REINSURER 8",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_9",
    title: "FACULTATIVE REINSURER 9",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_10",
    title: "FACULTATIVE REINSURER 10",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_4",
    title: "FACULTATIVE REINSURER CODE 4",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_5",
    title: "FACULTATIVE REINSURER CODE 5",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_6",
    title: "FACULTATIVE REINSURER CODE 6",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_7",
    title: "FACULTATIVE REINSURER CODE 7",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_8",
    title: "FACULTATIVE REINSURER CODE 8",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_9",
    title: "FACULTATIVE REINSURER CODE 9",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "FACULTATIVE_REINSURER_CODE_10",
    title: "FACULTATIVE REINSURER CODE 10",
    readOnly: true,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_RETENTION_OD_PERCENT",
    title: "RI PREMIUM RETENTION OD PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_PREMIUM_RETENTION_TP_PERCENT",
    title: "RI PREMIUM RETENTION TP PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_OD_PREMIUM_PERCENT",
    title: "RI OBLIGATORY OD PREMIUM PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_OBLIGATORY_TP_PREMIUM_PERCENT",
    title: "RI OBLIGATORY TP PREMIUM PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_OD_PREMIUM_PERCENT",
    title: "RI VOLUNTARY QUOTA OD PREMIUM PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "RI_VOLUNTARY_QUOTA_TP_PREMIUM_PERCENT",
    title: "RI VOLUNTARY QUOTA TP PREMIUM PERCENT",
    readOnly: false,
    wordWrap: true,
    width: 100,
  },
  {
    name: "Prod",
    title: "Prod",
    readOnly: true,
    wordWrap: false,
    width: 50,
  },
  {
    name: "Date",
    title: "Date",
    readOnly: true,
    wordWrap: false,
    width: 50,
  },
  {
    name: "Month",
    title: "Month",
    readOnly: true,
    wordWrap: false,
    width: 50,
  },
  {
    name: "RSD",
    title: "RSD",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "RED",
    title: "RED",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Endt Date",
    title: "Endt Date",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Expired Days",
    title: "Expired Days",
    readOnly: true,
    wordWrap: false,
    width: 50,
  },
  {
    name: "Unexpired Days",
    title: "Unexpired Days",
    readOnly: true,
    wordWrap: false,
    width: 50,
  },
  {
    name: "Expired Premium",
    title: "Expired Premium",
    readOnly: true,
    wordWrap: false,
    width: 100,
  },
  {
    name: "Unexpired Premium",
    title: "Unexpired Premium",
    readOnly: true,
    wordWrap: false,
    width: 100,
    type: "number",
    locale: "en-IN",
    options: { currency: "INR" },
  },
  {
    name: "Upload Month",
    title: "Upload Month",
    wordWrap: true,
    width: 80,
    readOnly: true,
  },
  {
    name: "Upload Year",
    title: "Upload Year",
    wordWrap: true,
    width: 80,
    readOnly: true,
  },
  {
    name: "original_product_class",
    title: "original_product_class",
    type: 'hidden',
  },
];

export function columnReportData(count, j, rest) {
  return {
    sr_no: 15 * (count - 1) + j + 1,
    id: rest["id"],
    "100% SI": rest["100% SI"],
    "Product Code": rest["Product Code"],
    Sub_System: rest["Sub_System"],
    "Product Class": rest["Product Class"],
    "Prod & Yr": rest["Prod & Yr"],
    "SI BAND": rest["SI BAND"],
    "Dept Code": rest["Dept Code"],
    "System ID": rest["System ID"],
    "Tran ID": rest["Tran ID"],
    RiskLocationAddress: rest["RiskLocationAddress"],
    RiskCode: rest["RiskCode"],
    "Proposal/CoverNote No": rest["Proposal/CoverNote No"],
    "Proposal Date": rest["Proposal Date"],
    "Policy/Endt Issue Date": rest["Policy/Endt Issue Date"],
    "Insured Name": rest["Insured Name"],
    "Address of Insured": rest["Address of Insured"],
    "Name of  City / Town / Village": rest["Name of  City / Town / Village"],
    "Pin Code": rest["Pin Code"],
    "Branch Code": rest["Branch Code"],
    "Policy No": rest["Policy No"],
    "U/W Year": rest["U/W Year"],
    "Endt Type": rest["Endt Type"],
    "Endt No": rest["Endt No"],
    "Endt Year": rest["Endt Year"],
    "Policy Start Date": rest["Policy Start Date"],
    "Policy End Date": rest["Policy End Date"],
    "No.of Lives Covered": rest["No.of Lives Covered"],
    "Sum Insured": rest["Sum Insured"],
    "Per Bottom Limit": rest["Per Bottom Limit"],
    "Marine Premium/ Gross Premium": rest["Marine Premium/ Gross Premium"],
    "Net OD/War": rest["Net OD/War"],
    "Net TP/SRCC": rest["Net TP/SRCC"],
    Premium: rest["Premium"],
    Terrorism: rest["Terrorism"],
    "Net Premium": rest["Net Premium"],
    "Fee Charged": rest["Fee Charged"],
    "Service Tax": rest["Service Tax"],
    "Service Levied Tax": rest["Service Levied Tax"],
    "Education Cess": rest["Education Cess"],
    "H. Education Cess": rest["H. Education Cess"],
    ERF: rest["ERF"],
    "Stamp Duty": rest["Stamp Duty"],
    "Total Premium": rest["Total Premium"],
    "Payment Mode": rest["Payment Mode"],
    "CD Account No. (If Payment Mode is CD)":
      rest["CD Account No. (If Payment Mode is CD)"] || "NA",
    "Stamp Duty (Paid by RGIC)": rest["Stamp Duty (Paid by RGIC)"],
    "Agent/Broker Name": rest["Agent/Broker Name"],
    "Agent/Broker Code": rest["Agent/Broker Code"],
    "Commission Rate": rest["Commission Rate"],
    "Commission Amount": rest["Commission Amount"],
    "NRMR Name": rest["NRMR Name"],
    "NRMR Code": rest["NRMR Code"],
    "SM Name": rest["SM Name"],
    "SM Code": rest["SM Code"],
    "IMD Ref Code": rest["IMD Ref Code"],
    "Coinsurance Status": rest["Coinsurance Status"],
    "Leader's Name": rest["Leader's Name"],
    "Our Share %": rest["Our Share %"],
    "Others Share %": rest["Others Share %"],
    "Co-Insurers": rest["Co-Insurers"],
    "Remarks (Coins)": rest["Remarks (Coins)"],
    "Service Tax Exempted Reason": rest["Service Tax Exempted Reason"],
    "Vessel/Conveyance": rest["Vessel/Conveyance"],
    Cargo: rest["Cargo"],
    Packing: rest["Packing"],
    "B/L-R/R No": rest["B/L-R/R No"],
    "B/L-R/R Date": rest["B/L-R/R Date"],
    "Voyage From": rest["Voyage From"],
    "Voyage To": rest["Voyage To"],
    "Model No (Motor)": rest["Model No (Motor)"],
    "Make (Motor)": rest["Make (Motor)"],
    "Business Type": rest["Business Type"],
    "Prev Policy No. (Ren)": rest["Prev Policy No. (Ren)"],
    Remarks: rest["Remarks"],
    "System Posting Date": rest["System Posting Date"],
    "Payee Name (Refund)": rest["Payee Name (Refund)"],
    Channel: rest["Channel"],
    "Remarks (VMG)": rest["Remarks (VMG)"],
    "Prev. Trans ID": rest["Prev. Trans ID"],
    Source: rest["Source"],
    "Name (Source)": rest["Name (Source)"],
    "DPR Date": rest["DPR Date"],
    "SAP Posting Date": rest["SAP Posting Date"],
    Zone: rest["Zone"],
    "BA Code": rest["BA Code"],
    "Profit Centre": rest["Profit Centre"],
    Region: rest["Region"],
    "Policy Count": rest["Policy Count"],
    New_Posting_Date: rest["New_Posting_Date"],
    ODdiscPerc: rest["ODdiscPerc"],
    ODdiscAmt: rest["ODdiscAmt"],
    NCBPerc: rest["NCBPerc"],
    NCBAmt: rest["NCBAmt"],
    ICM_TRAN_ID: rest["ICM_TRAN_ID"],
    RIFlag: rest["RIFlag"],
    RateCode: rest["RateCode"],
    RiskPinCode: rest["RiskPinCode"],
    NoOfLocations: rest["NoOfLocations"],
    HighestSI: rest["HighestSI"],
    Hazard_Category: rest["Hazard_Category"],
    DeclinedRiskYN: rest["DeclinedRiskYN"],
    QualityOfRisk: rest["QualityOfRisk"],
    RiskCity: rest["RiskCity"],
    RiskDistrict: rest["RiskDistrict"],
    RIskState: rest["RIskState"],
    DWH_Remark: rest["DWH_Remark"],
    HazardGrade: rest["HazardGrade"],
    OccupancyCode: rest["OccupancyCode"],
    HighestBISI: rest["HighestBISI"],
    HighestMDSI: rest["HighestMDSI"],
    PML_PERCENT: rest["PML_PERCENT"],
    PML_AMOUNT: rest["PML_AMOUNT"],
    COINSURANCE_PERCENT: rest["COINSURANCE_PERCENT"],
    SI_RETENTION: rest["SI_RETENTION"],
    SI_OBLIGATORY: rest["SI_OBLIGATORY"],
    SI_VOLUNTARY_QUOTA: rest["SI_VOLUNTARY_QUOTA"],
    SI_SURPLUS_TREATY_1: rest["SI_SURPLUS_TREATY_1"],
    SI_SURPLUS_TREATY_2: rest["SI_SURPLUS_TREATY_2"],
    SI_MARKET_SURPLUS_TREATY: rest["SI_MARKET_SURPLUS_TREATY"],
    SI_FACULATIVE_1: rest["SI_FACULATIVE_1"],
    SI_FACULATIVE_2: rest["SI_FACULATIVE_2"],
    SI_FACULATIVE_3: rest["SI_FACULATIVE_3"],
    SI_MOTOR_TP_POOL: rest["SI_MOTOR_TP_POOL"],
    SI_HULL_WA: rest["SI_HULL_WA"],
    "Net Calculation": rest["Net Calculation"],
    RI_MARINE_OBLIGATORY_PERCENT: rest["RI_MARINE_OBLIGATORY_PERCENT"],
    RI_WAR_OBLIGATORY_PERCENT: rest["RI_WAR_OBLIGATORY_PERCENT"],
    RI_SRCC_OBLIGATORY_PERCENT: rest["RI_SRCC_OBLIGATORY_PERCENT"],
    RI_OBLIGATORY_PERCENT: rest["RI_OBLIGATORY_PERCENT"],
    RI_VOLUNTARY_QUOTA_PERCENT: rest["RI_VOLUNTARY_QUOTA_PERCENT"],
    RI_RETENTION_PERCENT: rest["RI_RETENTION_PERCENT"],
    RI_SURPLUS_TREATY_1_PERCENT: rest["RI_SURPLUS_TREATY_1_PERCENT"],
    RI_SURPLUS_TREATY_2_PERCENT: rest["RI_SURPLUS_TREATY_2_PERCENT"],
    RI_MARKET_SURPLUS_TREATY_PERCENT: rest["RI_MARKET_SURPLUS_TREATY_PERCENT"],
    RI_FACULATIVE_PERCENT_1: rest["RI_FACULATIVE_PERCENT_1"],
    RI_FACULATIVE_PERCENT_2: rest["RI_FACULATIVE_PERCENT_2"],
    RI_FACULATIVE_PERCENT_3: rest["RI_FACULATIVE_PERCENT_3"],
    RI_MOTOR_TP_POOL_PERCENT: rest["RI_MOTOR_TP_POOL_PERCENT"],
    RI_HULL_WAR_PERCENT: rest["RI_HULL_WAR_PERCENT"],
    RI_PREMIUM_MARINE_OBLIGATORY: rest["RI_PREMIUM_MARINE_OBLIGATORY"],
    RI_PREMIUM_WAR__OBLIGATORY: rest["RI_PREMIUM_WAR__OBLIGATORY"],
    RI_PREMIUM_SRCC_OBLIGATORY: rest["RI_PREMIUM_SRCC_OBLIGATORY"],
    RI_PREMIUM_OBLIGATORY: rest["RI_PREMIUM_OBLIGATORY"],
    RI_PREMIUM_VOLUNTARY_QUOTA: rest["RI_PREMIUM_VOLUNTARY_QUOTA"],
    RI__PREMIUM_RETENTION: rest["RI__PREMIUM_RETENTION"],
    RI_PREMIUM_SURPLUS_TREATY_1: rest["RI_PREMIUM_SURPLUS_TREATY_1"],
    RI_PREMIUM_SURPLUS_TREATY_2: rest["RI_PREMIUM_SURPLUS_TREATY_2"],
    RI_PREMIUM_MARKET_SURPLUS_TREATY: rest["RI_PREMIUM_MARKET_SURPLUS_TREATY"],
    RI_PREMIUM_FACULATIVE_1: rest["RI_PREMIUM_FACULATIVE_1"],
    RI_PREMIUM_FACULATIVE_2: rest["RI_PREMIUM_FACULATIVE_2"],
    RI_PREMIUM_FACULATIVE_3: rest["RI_PREMIUM_FACULATIVE_3"],
    RI_PREMIUM_MOTOR_TP_POOL: rest["RI_PREMIUM_MOTOR_TP_POOL"],
    RI_PREMIUM_HULL_WAR: rest["RI_PREMIUM_HULL_WAR"],
    RI_MARINE_OBLIGATORY_COMMISSION_PERCENTAGE:
      rest["RI_MARINE_OBLIGATORY_COMMISSION_PERCENTAGE"],
    RI_WAR_OBLIGATORY_COMMISSION_PERCENTAGE:
      rest["RI_WAR_OBLIGATORY_COMMISSION_PERCENTAGE"],
    RI_SRCC_OBLIGATORY_COMMISSION_PERCENTAGE:
      rest["RI_SRCC_OBLIGATORY_COMMISSION_PERCENTAGE"],
    RI_OBLIGATORY_COMMISSION_PERCENT: rest["RI_OBLIGATORY_COMMISSION_PERCENT"],
    RI_VOLUNTARY_QUOTA_COMMISSION_PERCENT:
      rest["RI_VOLUNTARY_QUOTA_COMMISSION_PERCENT"],
    RI_SURPLUS_TREATY_1_COMMISSION_PERCENT:
      rest["RI_SURPLUS_TREATY_1_COMMISSION_PERCENT"],
    RI_SURPLUS_TREATY_2_COMMISSION_PERCENT:
      rest["RI_SURPLUS_TREATY_2_COMMISSION_PERCENT"],
    RI_MARKET_SURPLUS_TREATY_COMMISSION_PERCENT:
      rest["RI_MARKET_SURPLUS_TREATY_COMMISSION_PERCENT"],
    RI_FACULATIVE_COMMISSION_PERCENT_1:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_1"],
    RI_FACULATIVE_COMMISSION_PERCENT_2:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_2"],
    RI_FACULATIVE_COMMISSION_PERCENT_3:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_3"],
    RI_MOTOR_TP_POOL_COMMISSION_PERCENT:
      rest["RI_MOTOR_TP_POOL_COMMISSION_PERCENT"],
    RI_HULL_WAR_COMMISSION_PERCENT: rest["RI_HULL_WAR_COMMISSION_PERCENT"],
    RI_MARINE_OBLIGATORY_COMMISSION_AMOUNT:
      rest["RI_MARINE_OBLIGATORY_COMMISSION_AMOUNT"],
    RI_WAR_OBLIGATORY_COMMISSION_AMOUNT:
      rest["RI_WAR_OBLIGATORY_COMMISSION_AMOUNT"],
    RI_SRCC_OBLIGATORY_COMMISSION_AMOUNT:
      rest["RI_SRCC_OBLIGATORY_COMMISSION_AMOUNT"],
    RI_OBLIGATORY_COMMISSION_AMOUNT: rest["RI_OBLIGATORY_COMMISSION_AMOUNT"],
    RI_VOLUNTARY_QUOTA_COMMISSION_AMOUNT:
      rest["RI_VOLUNTARY_QUOTA_COMMISSION_AMOUNT"],
    RI_SURPLUS_TREATY_1_COMMISSION_AMOUNT:
      rest["RI_SURPLUS_TREATY_1_COMMISSION_AMOUNT"],
    RI_SURPLUS_TREATY_2_COMMISSION_AMOUNT:
      rest["RI_SURPLUS_TREATY_2_COMMISSION_AMOUNT"],
    RI_MARKET_SURPLUS_TREATY_COMMISSION_AMOUNT:
      rest["RI_MARKET_SURPLUS_TREATY_COMMISSION_AMOUNT"],
    RI_FACULATIVE_COMMISSION_AMOUNT_1:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_1"],
    RI_FACULATIVE_COMMISSION_AMOUNT_2:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_2"],
    RI_FACULATIVE_COMMISSION_AMOUNT_3:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_3"],
    RI_MOTOR_TP_POOL_COMMISSION_AMOUNT:
      rest["RI_MOTOR_TP_POOL_COMMISSION_AMOUNT"],
    RI_HULL_WAR_COMMISSION_AMOUNT: rest["RI_HULL_WAR_COMMISSION_AMOUNT"],
    FACULTATIVE_REINSURER_1: rest["FACULTATIVE_REINSURER_1"],
    FACULTATIVE_REINSURER_2: rest["FACULTATIVE_REINSURER_2"],
    FACULTATIVE_REINSURER_3: rest["FACULTATIVE_REINSURER_3"],
    FACULTATIVE_REINSURER_CODE_1: rest["FACULTATIVE_REINSURER_CODE_1"],
    FACULTATIVE_REINSURER_CODE_2: rest["FACULTATIVE_REINSURER_CODE_2"],
    FACULTATIVE_REINSURER_CODE_3: rest["FACULTATIVE_REINSURER_CODE_3"],
    RI_PREMIUM_TERRORISM: rest["RI_PREMIUM_TERRORISM"],
    RI_TERRORISM_COMMISSION: rest["RI_TERRORISM_COMMISSION"],
    RI_PREMIUM_RETENTION_OD: rest["RI_PREMIUM_RETENTION_OD"],
    RI_PREMIUM_RETENTION_TP: rest["RI_PREMIUM_RETENTION_TP"],
    RI_OBLIGATORY_OD_PREMIUM: rest["RI_OBLIGATORY_OD_PREMIUM"],
    RI_OBLIGATORY_TP_PREMIUM: rest["RI_OBLIGATORY_TP_PREMIUM"],
    RI_VOLUNTARY_QUOTA_OD_PREMIUM: rest["RI_VOLUNTARY_QUOTA_OD_PREMIUM"],
    RI_VOLUNTARY_QUOTA_TP_PREMIUM: rest["RI_VOLUNTARY_QUOTA_TP_PREMIUM"],
    RI_FACULTATIVE_OD_PREMIUM: rest["RI_FACULTATIVE_OD_PREMIUM"],
    RI_FACULTATIVE_TP_PREMIUM: rest["RI_FACULTATIVE_TP_PREMIUM"],
    RI_OBLIGATORY_OD_COMMISSION: rest["RI_OBLIGATORY_OD_COMMISSION"],
    RI_OBLIGATORY_TP_COMMISSION: rest["RI_OBLIGATORY_TP_COMMISSION"],
    RI_VOLUNTARY_QUOTA_OD_COMMISSION: rest["RI_VOLUNTARY_QUOTA_OD_COMMISSION"],
    RI_VOLUNTARY_QUOTA_TP_COMMISSION: rest["RI_VOLUNTARY_QUOTA_TP_COMMISSION"],
    RI_FACULTATIVE_OD_COMMISSION: rest["RI_FACULTATIVE_OD_COMMISSION"],
    RI_FACULTATIVE_TP_COMMISSION: rest["RI_FACULTATIVE_TP_COMMISSION"],
    PROPORTIONAL_RI_REMARKS: rest["PROPORTIONAL_RI_REMARKS"],
    WHOLE_ACCOUNT_XL: rest["WHOLE_ACCOUNT_XL"],
    MISCELLANEOUS_XOL: rest["MISCELLANEOUS_XOL"],
    TRAVEL_XOL: rest["TRAVEL_XOL"],
    GPA_JPA_XOL: rest["GPA_JPA_XOL"],
    MARINE_XOL: rest["MARINE_XOL"],
    PUBLIC_LIABILITY_XOL: rest["PUBLIC_LIABILITY_XOL"],
    HEALTH_XOL: rest["HEALTH_XOL"],
    MOTOR_OD_XOL: rest["MOTOR_OD_XOL"],
    MOTOR_TP_XOL: rest["MOTOR_TP_XOL"],
    NON_PROPORTIONAL_RI_REMARKS: rest["NON_PROPORTIONAL_RI_REMARKS"],
    Reinsurer: rest["Reinsurer"],
    "RI Remark": rest["RI Remark"],
    "Product Description":
      rest["Product Description"] ||
      "Reliance Bharat Laghu Udyam Suraksha (BLUS)",
    RI_FACULATIVE_PERCENT_4: rest["RI_FACULATIVE_PERCENT_4"],
    RI_FACULATIVE_PERCENT_5: rest["RI_FACULATIVE_PERCENT_5"],
    RI_FACULATIVE_PERCENT_6: rest["RI_FACULATIVE_PERCENT_6"],
    RI_FACULATIVE_PERCENT_7: rest["RI_FACULATIVE_PERCENT_7"],
    RI_FACULATIVE_PERCENT_8: rest["RI_FACULATIVE_PERCENT_8"],
    RI_FACULATIVE_PERCENT_9: rest["RI_FACULATIVE_PERCENT_9"],
    RI_FACULATIVE_PERCENT_10: rest["RI_FACULATIVE_PERCENT_10"],
    SI_FACULATIVE_4: rest["SI_FACULATIVE_4"],
    SI_FACULATIVE_5: rest["SI_FACULATIVE_5"],
    SI_FACULATIVE_6: rest["SI_FACULATIVE_6"],
    SI_FACULATIVE_7: rest["SI_FACULATIVE_7"],
    SI_FACULATIVE_8: rest["SI_FACULATIVE_8"],
    SI_FACULATIVE_9: rest["SI_FACULATIVE_9"],
    SI_FACULATIVE_10: rest["SI_FACULATIVE_10"],
    RI_PREMIUM_FACULATIVE_4: rest["RI_PREMIUM_FACULATIVE_4"],
    RI_PREMIUM_FACULATIVE_5: rest["RI_PREMIUM_FACULATIVE_5"],
    RI_PREMIUM_FACULATIVE_6: rest["RI_PREMIUM_FACULATIVE_6"],
    RI_PREMIUM_FACULATIVE_7: rest["RI_PREMIUM_FACULATIVE_7"],
    RI_PREMIUM_FACULATIVE_8: rest["RI_PREMIUM_FACULATIVE_8"],
    RI_PREMIUM_FACULATIVE_9: rest["RI_PREMIUM_FACULATIVE_9"],
    RI_PREMIUM_FACULATIVE_10: rest["RI_PREMIUM_FACULATIVE_10"],
    RI_FACULATIVE_COMMISSION_PERCENT_4:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_4"],
    RI_FACULATIVE_COMMISSION_PERCENT_5:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_5"],
    RI_FACULATIVE_COMMISSION_PERCENT_6:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_6"],
    RI_FACULATIVE_COMMISSION_PERCENT_7:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_7"],
    RI_FACULATIVE_COMMISSION_PERCENT_8:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_8"],
    RI_FACULATIVE_COMMISSION_PERCENT_9:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_9"],
    RI_FACULATIVE_COMMISSION_PERCENT_10:
      rest["RI_FACULATIVE_COMMISSION_PERCENT_10"],
    RI_FACULATIVE_COMMISSION_AMOUNT_4:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_4"],
    RI_FACULATIVE_COMMISSION_AMOUNT_5:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_5"],
    RI_FACULATIVE_COMMISSION_AMOUNT_6:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_6"],
    RI_FACULATIVE_COMMISSION_AMOUNT_7:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_7"],
    RI_FACULATIVE_COMMISSION_AMOUNT_8:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_8"],
    RI_FACULATIVE_COMMISSION_AMOUNT_9:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_9"],
    RI_FACULATIVE_COMMISSION_AMOUNT_10:
      rest["RI_FACULATIVE_COMMISSION_AMOUNT_10"],
    FACULTATIVE_REINSURER_4: rest["FACULTATIVE_REINSURER_4"],
    FACULTATIVE_REINSURER_5: rest["FACULTATIVE_REINSURER_5"],
    FACULTATIVE_REINSURER_6: rest["FACULTATIVE_REINSURER_6"],
    FACULTATIVE_REINSURER_7: rest["FACULTATIVE_REINSURER_7"],
    FACULTATIVE_REINSURER_8: rest["FACULTATIVE_REINSURER_8"],
    FACULTATIVE_REINSURER_9: rest["FACULTATIVE_REINSURER_9"],
    FACULTATIVE_REINSURER_10: rest["FACULTATIVE_REINSURER_10"],
    FACULTATIVE_REINSURER_CODE_4: rest["FACULTATIVE_REINSURER_CODE_4"],
    FACULTATIVE_REINSURER_CODE_5: rest["FACULTATIVE_REINSURER_CODE_5"],
    FACULTATIVE_REINSURER_CODE_6: rest["FACULTATIVE_REINSURER_CODE_6"],
    FACULTATIVE_REINSURER_CODE_7: rest["FACULTATIVE_REINSURER_CODE_7"],
    FACULTATIVE_REINSURER_CODE_8: rest["FACULTATIVE_REINSURER_CODE_8"],
    FACULTATIVE_REINSURER_CODE_9: rest["FACULTATIVE_REINSURER_CODE_9"],
    FACULTATIVE_REINSURER_CODE_10: rest["FACULTATIVE_REINSURER_CODE_10"],
    RI_PREMIUM_RETENTION_OD_PERCENT: rest["RI_PREMIUM_RETENTION_OD_PERCENT"],
    RI_PREMIUM_RETENTION_TP_PERCENT: rest["RI_PREMIUM_RETENTION_TP_PERCENT"],
    RI_OBLIGATORY_OD_PREMIUM_PERCENT: rest["RI_OBLIGATORY_OD_PREMIUM_PERCENT"],
    RI_OBLIGATORY_TP_PREMIUM_PERCENT: rest["RI_OBLIGATORY_TP_PREMIUM_PERCENT"],
    RI_VOLUNTARY_QUOTA_OD_PREMIUM_PERCENT:
      rest["RI_VOLUNTARY_QUOTA_OD_PREMIUM_PERCENT"],
    RI_VOLUNTARY_QUOTA_TP_PREMIUM_PERCENT:
      rest["RI_VOLUNTARY_QUOTA_TP_PREMIUM_PERCENT"],
    Prod: rest["Prod"],
    Date: rest["Date"],
    Month: rest["Month"],
    RSD: rest["RSD"],
    RED: rest["RED"],
    "Endt Date": rest["Endt Date"],
    "Expired Days": rest["Expired Days"],
    "Unexpired Days": rest["Unexpired Days"],
    "Expired Premium": rest["Expired Premium"],
    "Unexpired Premium": rest["Unexpired Premium"],
    "Upload Month": rest["Upload Month"],
    "Upload Year": rest["Upload Year"],
    "rectification_status": rest["rectification_status"],
    "original_product_class": rest["original_product_class"],
  };
}
