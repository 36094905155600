import React from "react";
import unAuthorizedImg from "../../assets/unAthorized.png";
import { ROUTES } from "../routes/Routes";
import { Link } from "react-router-dom";

const UnAuthorized = () => {
  return (
    <div
      className="align-items-center d-flex justify-content-center align-items-center flex-column"
      style={{ height: "100vh" }}
    >
      <div className="text-center">
        <img
          className="img-fluid"
          src={unAuthorizedImg}
          alt="unauthorized"
          height="150px"
          width="150px"
        />
        <h1 className="mt-5">Unauthorized User</h1>
        <h5>only admin has access to this page</h5>
        <Link to={ROUTES.LOGIN_ROUTE} style={{ marginTop: "20px" }}>
          Go to Login
        </Link>
      </div>
    </div>
  );
};

export default UnAuthorized;
