import React from 'react';
import { BeatLoader } from 'react-spinners';

const Wrapper = ({ loading, children }) => {

    return (
        <>
            {
                loading
                    ?
                    <div className="container">
                        <div className="row vh-100">
                            <div className="col d-flex align-items-center justify-content-center">
                                <BeatLoader />
                            </div>
                        </div>
                    </div>
                    :
                    children
            }
        </>
    )
}

export default Wrapper