export const pmlColumnDataCreate = [
    {
        name: "sr_no",
        title: "SR NO",
        readOnly: true,
        width: 50
    },
    {
        name: "id",
        title: "id",
        readOnly: true,
        wordWrap: true,
        width: 50,
        type: 'hidden',
    },
    {
        name: "Product Code",
        title: "Product Code",
        readOnly: true,
        wordWrap: true,
        width: 50,
    },
    {
        name: "Class",
        title: "Class",
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: "Product Description",
        title: "Product Description",
        readOnly: true,
        wordWrap: true,
    },
    {
        name: "PML /OCCUPANCY",
        title: "PML /OCCUPANCY",
        readOnly: true,
        wordWrap: true,
    },
    {
        name: "OCCUPANCY CODE",
        title: "OCCUPANCY CODE",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Obligatory",
        title: "Obligatory",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Facultative",
        title: "Facultative",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Terrorism",
        title: "Terrorism",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Obligatory Commission",
        title: "Obligatory Commission",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Obligatory Commission OD",
        title: "Obligatory Commission OD",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Obligatory Commission TP",
        title: "Obligatory Commission TP",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Commission %",
        title: "Quota Commission %",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Commission % OD",
        title: "Quota Commission % OD",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Commission % TP",
        title: "Quota Commission % TP",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Allocation %",
        title: "Quota Allocation %",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Allocation % Maximum",
        title: "Quota Allocation % Maximum",
        wordWrap: true,
        width: 100,
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
    },
    {
        name: "Quota Allocation OD % of IDV",
        title: "Quota Allocation OD % of IDV",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Quota Allocation TP % of IDV",
        title: "Quota Allocation TP % of IDV",
        wordWrap: true,
        width: 80,
    },
    {
        name: "First Surplus Capacity",
        title: "First Surplus Capacity",
        wordWrap: true,
    },
    {
        name: "First Surplus Capacity Lines",
        title: "First Surplus Capacity Lines",
        wordWrap: true,
        width: 100,
    },
    {
        name: "First Surplus Capacity Maximum on PML",
        title: "First Surplus Capacity Maximum on PML",
        wordWrap: true,
        width: 100,
    },
    {
        name: "Retention",
        title: "Retention",
        wordWrap: true,
    },
    {
        name: "Retention %",
        title: "Retention %",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention Maximum",
        title: "Retention Maximum",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention Amount",
        title: "Retention Amount",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention OD %",
        title: "Retention OD %",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention OD Amount",
        title: "Retention OD Amount",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention TP %",
        title: "Retention TP %",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Retention TP Amount",
        title: "Retention TP Amount",
        wordWrap: true,
        width: 80,
    },
    {
        name: "First Surplus Commission",
        title: "First Surplus Commission",
        wordWrap: true,
        width: 100,
    },
    {
        name: "FAC Comm",
        title: "FAC Comm",
        wordWrap: true,
        width: 120,
    },
    {
        name: "Terrorism Commission",
        title: "Terrorism Commission",
        wordWrap: true,
        width: 80,
    },
    {
        name: "Co-insurance Limitation",
        title: "Co-insurance Limitation",
        wordWrap: true,
    },
    {
        name: "Treaty Name",
        title: "Treaty Name",
        wordWrap: true,
        width: 120,
        readOnly: true,
    },
    {
        name: "month",
        title: "month",
        wordWrap: true,
        width: 80,
        readOnly: true,
    },
    {
        name: "year",
        title: "year",
        wordWrap: true,
        width: 80,
        readOnly: true,
    },
];

export const CustomInput = ({ value, onClick }) => (
    <input
        type="text"
        value={value}
        onClick={onClick}
        style={{
            height: "38px",
            borderRadius: "4px",
            border: "1px solid #cccccc",
            padding: "10px",
            fontSize: "12px",
            width: "100%",
        }}
    />
);