import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getCurrentUser } from './Utils';

const PrivateRoute = ({ redirectTo }) => {
    if (!getCurrentUser().token) {
        return <Navigate to={redirectTo} />
    }
    return <Outlet />
};

export default PrivateRoute;