export const setUserSession = (token) => {
    localStorage.setItem('token', token)
}

export const getToken = () => {
    return localStorage.getItem('token')
}

export const setLocalStorageItem = (key, value) => {
    return localStorage.setItem(key, value)
}

export const getLocalStorageItem = (key) => {
    return localStorage.getItem(key)
}