import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../components/routes/Routes";
import { getCurrentUser } from './Utils';
import UnAuthorized from "../components/pages/UnAuthorized";

const RequireAuth = ({ to }) => {

  const token = getCurrentUser()?.token;
  const user_privileges
    = getCurrentUser()?.user_privileges;
  const hasAccess = user_privileges?.includes(to);
  return token ? (
    hasAccess ? (
      <Outlet />
    ) : (
      <UnAuthorized />
    )
  ) : (
    <Navigate to={ROUTES.LOGIN_ROUTE} replace />
  );
};
export default RequireAuth;
