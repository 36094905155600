import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import {
    getRequest,
    patchRequest
} from "../../../helper/AxiosClient";
import {
    errorNotification,
    successNotification
} from "../../../helper/notification";
import CustomButton from "../../common/CustomButton";
import { GET_PML_RISK, UPDATE_PML_RISK } from "../../routes/ApiEndPoin";

const EngineeringRiskPml = ({ selectedMonthName, selectedYear, selectedMonth, editPmlFlag, setEditPmlFlag }) => {
    const engineeringProductRiskRef = useRef();
    const [loadingRisk, setLoadingRisk] = useState(false);
    const [getApiDataRisk, setGetApiDataRisk] = useState();
    const [engineeringColumnDataRisk, setEngineeringColumnDataRisk] = useState([]);
    const [engineeringRowDataRisk, setEngineeringRowDataRisk] = useState([]);


    /* ----- Col Generator Risk Function ----- */
    const colGeneratorRisk = () => {
        const engineeringColumnDataRisk = [

            {
                name: "PML above INR",
                title: "PML above INR",
                width: 150,
                type: "number",
                locale: "en-IN",
                options: { currency: "INR" },
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Minimum Share %",
                title: "Minimum Share %",
                width: 150,
                type: "number",
                mask: "0.00",
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Maximum Share %",
                title: "Maximum Share %",
                width: 150,
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Capacity %",
                title: "Capacity %",
                width: 150,
                type: "number",
                mask: "0.00",
                readOnly: editPmlFlag ? false : true,
            },
            {
                title: "Action",
                width: 50,
                type: editPmlFlag ? "image" : "hidden",
            },
        ];
        setEngineeringColumnDataRisk(engineeringColumnDataRisk);
    };

    /* ----- API call for get eng Risk -----*/
    const getEngineeringDataRisk = async () => {
        try {
            const res = await getRequest(
                `${GET_PML_RISK}?month=${selectedMonth}&year=${selectedYear}&product_class=Engineering`
            );
            setGetApiDataRisk(res?.data?.data?.riskPmlData);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingRisk(false);
        }
    };

    useEffect(() => {
        setLoadingRisk(true);
        getEngineeringDataRisk()
        setEditPmlFlag(false);
        // eslint-disable-next-line
    }, [selectedMonth, selectedYear]);

    const optionsRisk = {
        data: engineeringRowDataRisk,
        columns: engineeringColumnDataRisk,
        autoIncrement: false,
        allowInsertColumn: false,
        allowInsertRow: false,
        columnDrag: false,
        contextMenu: false,
        copyCompatibility: true,
        defaultColWidth: 200,
        search: false,
        csvHeaders: true,
        csvFileName: "Opening_Hc",
        columnSorting: true,
        editable: true,
        updateTable: function (el, cell, x, y, col, source, value, label, items) {
            if (x === engineeringColumnDataRisk.length - 1) {
                const deleteIcon = document.createElement("img");
                deleteIcon.src = "https://cdn.iconscout.com/icon/free/png-256/free-delete-2902143-2411575.png";
                deleteIcon.style.width = "20px";
                deleteIcon.style.height = "20px";
                deleteIcon.style.cursor = "pointer";

                deleteIcon.addEventListener("click", function (event) {
                    event.stopPropagation(); // Stop the event from propagating to the table

                    const isConfirmed = window.confirm("Are you sure you want to delete this row?");
                    if (isConfirmed) {
                        const newData = jspreadsheet(engineeringProductRiskRef.current, optionsRisk).getData();
                        newData.splice(y, 1);
                        setEngineeringRowDataRisk(newData);
                    }
                });
                cell.innerHTML = ''; // Clear existing content
                cell.appendChild(deleteIcon);
            }
            // if (value === null || value === "") {
            //     cell.innerHTML = "-";
            //     // cell.classList.add("readonly");
            // }
        },
    };

    useEffect(() => {
        if (engineeringRowDataRisk?.length !== 0 && !loadingRisk) {
            document.getElementById("pmlTableRisk").innerHTML = "";
            jspreadsheet(engineeringProductRiskRef.current, optionsRisk);
        }
        // eslint-disable-next-line
    }, [engineeringRowDataRisk]);

    useEffect(() => {
        const tempRowData = [];
        if (getApiDataRisk?.length !== 0) {
            getApiDataRisk?.forEach((data, j) => {
                const { createdAt, updatedAt, month, year, id, ...rest } = data;
                const tempRowObj = {
                    "PML above INR": rest["PML above INR"],
                    "Minimum Share %": rest["Minimum Share %"],
                    'Maximum Share %': rest["Maximum Share %"],
                    'Capacity %': rest["Capacity %"],
                };
                tempRowData.push(tempRowObj);
            });
            setEngineeringRowDataRisk(tempRowData);
        }
        colGeneratorRisk();
        // eslint-disable-next-line
    }, [getApiDataRisk, editPmlFlag]);

    /* ----- add button click insert a new row -----*/
    const insertRowRisk = () => {
        const newData = jspreadsheet(engineeringProductRiskRef.current, optionsRisk).getData();
        newData.push(engineeringColumnDataRisk.map((item) => "")); // Add as many nulls as the number of columns
        setEngineeringRowDataRisk(newData);
    };

    /* ----- update eng data -----*/
    const saveEngineeringDataRisk = async () => {
        setLoadingRisk(true);
        const healthRefInstance = engineeringProductRiskRef?.current?.jexcel;
        const jexcelRecords = healthRefInstance?.getJson();
        const jsonRecord = JSON?.parse(JSON?.stringify(jexcelRecords));
        const newData = jsonRecord.map(({ "4": _, "5": __, ...rest }) => {
            const convertedObject = Object.fromEntries(
                Object.entries(rest).map(([key, value]) => [
                    key,
                    typeof value === 'string' && !isNaN(value.replace(/,/g, '')) ? parseFloat(value.replace(/,/g, '')) : value,
                ])
            );
            return convertedObject;

        });
        const pmlSave = {
            data: newData,
            month: selectedMonth,
            year: selectedYear,
        };
        try {
            const res = await patchRequest(`${UPDATE_PML_RISK}?product_class=Engineering`, pmlSave);
            successNotification(res?.data?.message);
            getEngineeringDataRisk();
        } catch (error) {
            errorNotification(error.response.data.message);
            getEngineeringDataRisk();
            console.log(error);
        } finally {
            setLoadingRisk(false);
        }
    };
    return (
        <div>
            {loadingRisk ? (
                <div className="row">
                    <div className="col text-center py-5">
                        <BeatLoader />
                    </div>
                </div>
            ) : getApiDataRisk?.length === 0 ? (
                <div className="row">
                    <div className="col text-center py-5">No Data Available</div>
                </div>
            ) : (<div class="col-12 mt-5">
                <div class="col sub-header d-flex justify-content-between">
                    <h3 className="mb-lg-0 mb-2">{`Engineering Risk PML (${selectedMonthName}-${selectedYear})`}</h3>

                </div>
                <div className="row position-relative">
                    <div className="col">
                        <div className="actual_gwp">
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between overflow-auto">
                                    <div ref={engineeringProductRiskRef} id="pmlTableRisk" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {editPmlFlag && (
                    <div className="d-flex justify-content-start">
                        <CustomButton
                            type="submit"
                            className="mainBtn float-end btn-sm mt-2"
                            style={{ marginRight: "545px" }}
                            handleClick={() => insertRowRisk()}
                        >
                            Add
                        </CustomButton>
                        <CustomButton
                            type="submit"
                            className="mainBtn float-end btn-sm mt-2"
                            handleClick={() => saveEngineeringDataRisk()}
                        >
                            Enter For Save
                        </CustomButton>
                    </div>
                )}
            </div>)}
        </div>
    )
}

export default EngineeringRiskPml
