import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { ADD_IP_EP, DELETE_IP_EP, GET_IP_EP } from "../../routes/ApiEndPoin";
import CustomButton from "../../common/CustomButton";
import TableOfDetails from "../../../helper/TableOfDetails";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../../helper/AxiosClient";

const IpDetails = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [ipData, setIpData] = useState([]);
  const [modalShow, setModalShow] = React.useState(false);

  const [enteredIP, setEnteredIP] = useState("");

  /* ----- Prepare Column Data -----*/
  const columnData = useMemo(
    () => [
      {
        Header: "Sr No",
        Cell: (row) => +row?.row?.id + 1,
        width: "80px",
      },
      {
        Header: "IP Address",
        accessor: "ip",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: (row) => (
          <div className="text-center">
            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={() => handleDeleteUserData(row)}
            >
              <AiFillDelete />
            </button>
          </div>
        ),
        width: "100px",
      },
    ],
    // eslint-disable-next-line
    []
  );

  /* ----- API call for get Ip Address -----*/
  const getIPaddress = async () => {
    try {
      const res = await getRequest(GET_IP_EP, null, navigate);
      setIpData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /* ----- Handle for Delete IP -----*/
  const handleDeleteUserData = (row) => {
    const ipID = row.row.original.id;
    if (window.confirm("Are you sure you want to delete this IP Address?")) {
      deleteUserData(ipID);
    }
  };

  /* ----- API call for add IP Address -----*/
  const addIpAddress = async () => {
    setLoading(true);
    const payload = { ip: enteredIP };

    try {
      const res = await postRequest(ADD_IP_EP, payload, navigate);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        setLoading(false);
        getIPaddress();
      }
      if (res.type === 2) {
        errorNotification(res?.errormessage || res?.errors);
        setLoading(false);
      }
    } catch (error) {
      errorNotification(error?.message);
      setLoading(false);
    }
  };

  /* ----- API call for Delete IP Data -----*/
  const deleteUserData = async (id) => {
    setLoading(true);
    try {
      const res = await deleteRequest(DELETE_IP_EP + id, navigate);
      if (res.type === 1) {
        successNotification(res?.response?.data?.message);
        getIPaddress();
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitIP = (e) => {
    e.preventDefault();
    setModalShow(false);
    addIpAddress();
    setEnteredIP("");
  };

  /* ----- trigger API call On Page Render -----*/
  useEffect(() => {
    getIPaddress();
    // eslint-disable-next-line
  }, []);

  const onCloseModal = () => {
    setModalShow(false);
    setEnteredIP("");
  };

  return (
    <>
      <section className="dashboard p-3 pb-md-5">
        <div className="container-fluid">
          <div className="sub-header row pb-sm-2">
            <div className="col">
              <h3 className="mb-lg-0 mb-2">IP Addresses</h3>
            </div>
            <div className="col">
              <div className="d-flex justify-content-end">
                <CustomButton
                  type="button"
                  className="mainBtn btn-sm"
                  onClick={() => setModalShow(true)}
                >
                  Add IP +
                </CustomButton>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 dashboard_family mt-3 detailsTable">
              {loading ? (
                <div className="row">
                  <div className="col text-center py-5">
                    <BeatLoader />
                  </div>
                </div>
              ) : ipData?.length === 0 ? (
                <div className="row">
                  <div className="col text-center py-5">No Data Available</div>
                </div>
              ) : (
                <>
                  <TableOfDetails columns={columnData} data={ipData} />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* ------- Modal For IP Address ------- */}
      <Modal centered show={modalShow} onHide={() => onCloseModal()}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "20px", fontWeight: "600" }}>
            Add IP Address
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitIP}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>IP Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IP"
                name="ip"
                value={enteredIP}
                onChange={(e) => setEnteredIP(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton className="mainBtn">Add</CustomButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default IpDetails;
