export const openingHcColumnDatas = [
    {
        name: "sr_no",
        title: "SR NO",
        readOnly: true,
        width: 50
    },
    {
        name: "System ID",
        title: "System ID",
        type: "text",
        readOnly: true,
        wordWrap: true,
        width: 180
    },
    {
        name: 'Sub_System',
        title: 'Sub System',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Tran ID',
        title: 'Tran ID',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'RiskLocationAddress',
        title: 'Risk Location Address',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'RiskCode',
        title: 'Risk Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Proposal/CoverNote No',
        title: 'Proposal CoverNote No',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Proposal Date',
        title: 'Proposal Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Policy/Endt Issue Date',
        title: 'Policy/Endt Issue Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Insured Name',
        title: 'Insured Name',
        type: 'text',
        readOnly: true,
        wordWrap: true,
    },
    {
        name: 'Address of Insured',
        title: 'Address of Insured',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 450
    },
    {
        name: 'Name of  City / Town / Village',
        title: 'Name of  City / Town / Village',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 90
    },
    {
        name: 'Pin Code',
        title: 'Pin Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Branch Code',
        title: 'Branch Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Product Code',
        title: 'Product Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Policy No',
        title: 'Policy No',
        type: 'text',
        readOnly: true,
        wordWrap: true,
    },
    {
        name: 'U/W Year',
        title: 'U/W Year',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 50
    },
    {
        name: 'Endt Type',
        title: 'Endt Type',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Endt No',
        title: 'Endt No',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 50
    },
    {
        name: 'Endt Year',
        title: 'Endt Year',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 50
    },
    {
        name: 'Policy Start Date',
        title: 'Policy Start Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 70
    },
    {
        name: 'Policy End Date',
        title: 'Policy End Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 70
    },
    {
        name: 'No.of Lives Covered',
        title: 'No.of Lives Covered',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Sum Insured',
        title: 'Sum Insured',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Per Bottom Limit ',
        title: 'Per Bottom Limit ',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Marine Premium/ Gross Premium',
        title: 'Marine Premium/ Gross Premium',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Net OD/War',
        title: 'Net OD/War',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Net TP/SRCC',
        title: 'Net TP/SRCC',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Premium',
        title: 'Premium',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Terrorism',
        title: 'Terrorism',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Net Premium',
        title: 'Net Premium',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100
    },
    {
        name: 'Fee Charged',
        title: 'Fee Charged',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Service Tax',
        title: 'Service Tax',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Service Levied Tax',
        title: 'Service Levied Tax',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Education Cess',
        title: 'Education Cess',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'H. Education Cess',
        title: 'H. Education Cess',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'ERF',
        title: 'ERF',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Stamp Duty',
        title: 'Stamp Duty',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Total Premium',
        title: 'Total Premium',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Payment Mode',
        title: 'Payment Mode',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'CD Account No. (If Payment Mode is CD)',
        title: 'CD Account No. (If Payment Mode is CD)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Stamp Duty (Paid by RGIC)',
        title: 'Stamp Duty (Paid by RGIC)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Agent/Broker Name',
        title: 'Agent/Broker Name',
        type: 'text',
        readOnly: true,
        wordWrap: true,
    },
    {
        name: 'Agent/Broker Code',
        title: 'Agent/Broker Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Commission Rate',
        title: 'Commission Rate',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'Commission Amount',
        title: 'Commission Amount',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'NRMR Name',
        title: 'NRMR Name',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'NRMR Code',
        title: 'NRMR Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'SM Name',
        title: 'SM Name',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'SM Code',
        title: 'SM Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120
    },
    {
        name: 'IMD Ref Code',
        title: 'IMD Ref Code',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'Coinsurance Status',
        title: 'Coinsurance Status',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: "Leader's Name",
        title: "Leader's Name",
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Our Share %',
        title: 'Our Share %',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Others Share %',
        title: 'Others Share %',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80
    },
    {
        name: 'Co-Insurers',
        title: 'Co-Insurers',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Remarks (Coins)',
        title: 'Remarks (Coins)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Service Tax Exempted Reason',
        title: 'Service Tax Exempted Reason',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Vessel/Conveyance',
        title: 'Vessel/Conveyance',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Cargo',
        title: 'Cargo',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Packing',
        title: 'Packing',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'B/L,R/R No',
        title: 'B/L,R/R No',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'B/L,R/R Date',
        title: 'B/L,R/R Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Voyage From',
        title: 'Voyage From',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Voyage To',
        title: 'Voyage To',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Model No (Motor)',
        title: 'Model No (Motor)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Make (Motor)',
        title: 'Make (Motor)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Business Type',
        title: 'Business Type',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Prev Policy No. (Ren)',
        title: 'Prev Policy No. (Ren)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 150,
    },
    {
        name: 'Remarks',
        title: 'Remarks',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'System Posting Date',
        title: 'System Posting Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Payee Name (Refund)',
        title: 'Payee Name (Refund)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Channel',
        title: 'Channel',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120,
    },
    {
        name: 'Remarks (VMG)',
        title: 'Remarks (VMG)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Prev. Trans ID',
        title: 'Prev. Trans ID',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'Source',
        title: 'Source',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Name (Source)',
        title: 'Name (Source)',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'DPR Date',
        title: 'DPR Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'SAP Posting Date',
        title: 'SAP Posting Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Zone',
        title: 'Zone',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'BA Code',
        title: 'BA Code',
        type: 'text',
        readOnly: true,
        wordWrap: true
    },
    {
        name: 'Profit Centre',
        title: 'Profit Centre',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Region',
        title: 'Region',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'original_product_class',
        title: 'Product Class',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Policy Count',
        title: 'Policy Count',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'New_Posting_Date',
        title: 'New Posting Date',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'ODdiscPerc',
        title: 'ODdiscPerc',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'ODdiscAmt',
        title: 'ODdiscAmt',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'NCBPerc',
        title: 'NCBPerc',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'NCBAmt',
        title: 'NCBAmt',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'ICM_TRAN_ID',
        title: 'ICM TRAN ID',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 120,
    },
    {
        name: 'RIFlag',
        title: 'RI Flag',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'RateCode',
        title: 'Rate Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'RiskPinCode',
        title: 'Risk PinCode',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'NoOfLocations',
        title: 'NoOfLocations',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'HighestSI',
        title: 'Highest SI',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Hazard_Category',
        title: 'Hazard Category',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'DeclinedRiskYN',
        title: 'DeclinedRiskYN',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'QualityOfRisk',
        title: 'QualityOfRisk',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'RiskCity',
        title: 'Risk City',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'RiskDistrict',
        title: 'Risk District',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'RIskState',
        title: 'Risk State',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'DWH_Remark',
        title: 'DWH Remark',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'HazardGrade',
        title: 'Hazard Grade',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'OccupancyCode',
        title: 'Occupancy Code',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'HighestMDSI',
        title: 'Highest MDSI',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'HighestBISI',
        title: 'Highest BISI',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 100,
    },
    {
        name: 'Upload Month',
        title: 'Upload Month',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    },
    {
        name: 'Upload Year',
        title: 'Upload Year',
        type: 'text',
        readOnly: true,
        wordWrap: true,
        width: 80,
    }
]

export function columnRawData(count, j, rest) {
    return {
        sr_no: 15 * (count - 1) + j + 1,
        "System ID": rest["System ID"] || "",
        Sub_System: rest["Sub_System"] || "",
        "Tran ID": rest["Tran ID"] || "",
        RiskLocationAddress: rest["RiskLocationAddress"] || "",
        RiskCode: rest["RiskCode"] || "",
        "Proposal/CoverNote No": rest["Proposal/CoverNote No"] || "",
        "Proposal Date": rest["Proposal Date"] || "",
        "Policy/Endt Issue Date": rest["Policy/Endt Issue Date"] || "",
        "Insured Name": rest["Insured Name"] || "",
        "Address of Insured": rest["Address of Insured"] || "",
        "Name of  City / Town / Village":
            rest["Name of  City / Town / Village"] || "",
        "Pin Code": rest["Pin Code"] || "",
        "Branch Code": rest["Branch Code"] || "",
        "Product Code": rest["Product Code"] || "",
        "Policy No": rest["Policy No"] || "",
        "U/W Year": rest["U/W Year"] || "",
        "Endt Type": rest["Endt Type"] || "",
        "Endt No": rest["Endt No"] || "",
        "Endt Year": rest["Endt Year"] || "",
        "Policy Start Date": rest["Policy Start Date"] || "",
        "Policy End Date": rest["Policy End Date"] || "",
        "No.of Lives Covered": rest["No.of Lives Covered"] || "",
        "Sum Insured": rest["Sum Insured"] || "",
        "Per Bottom Limit": rest["Per Bottom Limit "] || "",
        "Marine Premium/ Gross Premium":
            rest["Marine Premium/ Gross Premium"] || "",
        "Net TP/SRCC": rest["Net TP/SRCC"] || "",
        Premium: rest["Premium"] || "",
        Terrorism: rest["Terrorism"] || "",
        "Net Premium": rest["Net Premium"] || "",
        "Fee Charged": rest["Fee Charged"] || "",
        "Service Tax": rest["Service Tax"] || "",
        "Service Levied Tax": rest["Service Levied Tax"] || "",
        "Education Cess": rest["Education Cess"] || "",
        "H. Education Cess": rest["H. Education Cess"] || "",
        ERF: rest["ERF"] || "",
        "Stamp Duty": rest["Stamp Duty"] || "",
        "Total Premium": rest["Total Premium"] || "",
        "Payment Mode": rest["Payment Mode"] || "",
        "CD Account No. (If Payment Mode is CD)":
            rest["CD Account No. (If Payment Mode is CD)"] || "",
        "Stamp Duty (Paid by RGIC)": rest["Stamp Duty (Paid by RGIC)"] || "",
        "Agent/Broker Name": rest["Agent/Broker Name"] || "",
        "Agent/Broker Code": rest["Agent/Broker Code"] || "",
        "Commission Rate": rest["Commission Rate"] || "",
        "Commission Amount": rest["Commission Amount"] || "",
        "NRMR Name": rest["NRMR Name"] || "",
        "NRMR Code": rest["NRMR Code"] || "",
        "SM Name": rest["SM Name"] || "",
        "SM Code": rest["SM Code"] || "",
        "IMD Ref Code": rest["IMD Ref Code"] || "",
        "Coinsurance Status": rest["Coinsurance Status"] || "",
        "Leader's Name": rest["Leader's Name"] || "",
        "Our Share %": rest["Our Share %"] || "",
        "Others Share %": rest["Others Share %"] || "",
        "Co-Insurers": rest["Co-Insurers"] || "",
        "Remarks (Coins)": rest["Remarks (Coins)"] || "",
        "Service Tax Exempted Reason":
            rest["Service Tax Exempted Reason"] || "",
        "Vessel/Conveyance": rest["Vessel/Conveyance"] || "",
        Cargo: rest["Cargo"] || "",
        Packing: rest["Packing"] || "",
        "B/L,R/R No": rest["B/L,R/R No"] || "",
        "B/L,R/R Date": rest["B/L,R/R Date"] || "",
        "Voyage From": rest["Voyage From"] || "",
        "Voyage To": rest["Voyage To"] || "",
        "Model No (Motor)": rest["Model No (Motor)"] || "",
        "Make (Motor)": rest["Make (Motor)"] || "",
        "Business Type": rest["Business Type"] || "",
        "Prev Policy No. (Ren)": rest["Prev Policy No. (Ren)"] || "",
        Remarks: rest["Remarks"] || "",
        "System Posting Date": rest["System Posting Date"] || "",
        "Payee Name (Refund)": rest["Payee Name (Refund)"] || "",
        Channel: rest["Channel"] || "",
        "Remarks (VMG)": rest["Remarks (VMG)"] || "",
        "Prev. Trans ID": rest["Prev. Trans ID"] || "",
        Source: rest["Source"] || "",
        "Name (Source)": rest["Name (Source)"] || "",
        "DPR Date": rest["DPR Date"] || "",
        "SAP Posting Date": rest["SAP Posting Date"] || "",
        Zone: rest["Zone"] || "",
        "BA Code": rest["BA Code"] || "",
        "Profit Centre": rest["Profit Centre"] || "",
        Region: rest["Region"] || "",
        "Product Class": rest["original_product_class"] || "",
        "Policy Count": rest["Policy Count"] || "",
        New_Posting_Date: rest["New_Posting_Date"] || "",
        ODdiscPerc: rest["ODdiscPerc"] || "",
        ODdiscAmt: rest["ODdiscAmt"] || "",
        NCBPerc: rest["NCBPerc"] || "",
        NCBAmt: rest["NCBAmt"] || "",
        ICM_TRAN_ID: rest["ICM_TRAN_ID"] || "",
        RIFlag: rest["RIFlag"] || "",
        RateCode: rest["RateCode"] || "",
        RiskPinCode: rest["RiskPinCode"] || "",
        NoOfLocations: rest["NoOfLocations"] || "",
        HighestSI: rest["HighestSI"] || "",
        Hazard_Category: rest["Hazard_Category"] || "",
        DeclinedRiskYN: rest["DeclinedRiskYN"] || "",
        QualityOfRisk: rest["QualityOfRisk"] || "",
        RiskCity: rest["RiskCity"] || "",
        RiskDistrict: rest["RiskDistrict"] || "",
        RIskState: rest["RIskState"] || "",
        DWH_Remark: rest["DWH_Remark"] || "",
        HazardGrade: rest["HazardGrade"] || "",
        OccupancyCode: rest["OccupancyCode"] || "",
        HighestMDSI: rest["HighestMDSI"] || "",
        HighestBISI: rest["HighestBISI"] || "",
        'Upload Month': rest["Upload Month"] || "",
        'Upload Year': rest["Upload Year"] || "",
    };
}