import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import BeatLoader from "react-spinners/BeatLoader";
import CustomButton from "../../common/CustomButton";
import { AiTwotoneMail } from "react-icons/ai";
import { CiLock } from "react-icons/ci";
import "./login.css";
import { useNavigate } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import { setLocalStorageItem } from "../../../helper/localStorage";
import { postRequest } from "../../../helper/AxiosClient";
import { LOGIN_EP } from "../../routes/ApiEndPoin";

const SignInPage = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState({ password: false });
  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  /* ----- Function for Show and Hide password  ----- */
  const handleShowPassword = () => {
    setShowPassword({ showPassword, password: !showPassword.password });
  };

  /* ----- handle Login Submit Function  ----- */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await postRequest(LOGIN_EP, userData, navigate);
      if (res.type === 1) {
        setLoading(false);
        setLocalStorageItem(
          "current_user",
          JSON.stringify(res?.response?.data?.data)
        );
        successNotification(res?.response?.data?.message);
        navigate(res?.response?.data?.data?.user_privileges?.[0]);
      }
      if (res.type === 2) {
        setLoading(false);
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id="signIn">
      <div className="container-fluid">
        <div className="row">
          <div className="d-md-inline-block d-none col-md-6 signInImage">
            <div className="row h-100">
              <div className="col title-text d-flex justify-content-center align-items-center">
                <h1 className="mb-0">RI</h1>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {loading ? (
              <div className="row h-100">
                <div className="col d-flex align-items-center justify-content-center">
                  <BeatLoader />
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-xxl-6 col-xl-7 col-md-9 m-auto">
                    <div className="login-form">
                      <div className="card border-0 rounded-0 w-100">
                        <div className="card-header border-0 bg-white">
                          <div className="card-title">
                            <h4>Welcome Back!</h4>
                            <p className="mb-0">
                              Enter your email and password to sign in
                            </p>
                          </div>
                        </div>
                        <div className="card-body">
                          <form onSubmit={handleSubmit}>
                            <fieldset className="form-group position-relative mb-4">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                name="email"
                                value={userData.email}
                                onChange={handleInput}
                                autoFocus
                              />
                              <div className="position-absolute icon-position">
                                <AiTwotoneMail />
                              </div>
                            </fieldset>
                            <fieldset className="form-group position-relative mb-4">
                              <input
                                type={
                                  showPassword.password ? "text" : "password"
                                }
                                autoComplete="true"
                                className="form-control"
                                placeholder="Password"
                                name="password"
                                value={userData.password}
                                onChange={handleInput}
                              />
                              <div className="position-absolute icon-position">
                                <CiLock />
                              </div>
                              <div
                                className="position-absolute eye-position"
                                onClick={handleShowPassword}
                              >
                                {showPassword.password ? (
                                  <BsEyeFill />
                                ) : (
                                  <BsEyeSlashFill />
                                )}
                              </div>
                            </fieldset>
                            <fieldset className="form-group mb-0 mt-4">
                              <CustomButton
                                type="submit"
                                className="full-width-btn mainBtn"
                              >
                                Sign In
                              </CustomButton>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignInPage;
