import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastStyle = {
    autoClose: 1000,
    hideProgressBar: true,
    position: "bottom-right"
}

export const successNotification = (message) => toast.success(message, toastStyle);
export const errorNotification = (message) => toast.error(message, toastStyle);