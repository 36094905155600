import React from 'react'
import pageNotFoundImg from '../../assets/pageNotFound.png'

const NotFoundPage = () => {
  return (
    <div className='dashboard d-flex justify-content-center align-items-center flex-column'>
      <div className='text-center'>
        <img className='img-fluid' src={pageNotFoundImg} alt="page not found" height="150px" width="150px" />
        <h3 className='mt-5 fw-bold'>oh  no! Page Not Found</h3>
      </div>
    </div>
  )
}

export default NotFoundPage