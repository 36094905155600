import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import {
    getRequest,
    patchRequest,
    postRequest
} from "../../../helper/AxiosClient";
import {
    errorNotification,
    successNotification
} from "../../../helper/notification";
import UploadControl from "../../../helper/UploadControl";
import CustomButton from "../../common/CustomButton";
import {
    DOWNLOAD_ENGINEERING_PML_DATA, GET_ENGINEERING_PML, UPDATE_ENGINEERING_PML,
    UPLOAD_ENGINEERING_PML_CSV
} from "../../routes/ApiEndPoin";
import EngineeringRiskPml from "./EngineeringRiskPml";

const EngineeringPmlCalculation = () => {
    const pageSize = 15;
    const engineeringRef = useRef();


    const [selectedDate, setSelectedDate] = useState(new Date());
    const selectedYear = selectedDate?.getFullYear();
    const selectedMonth = parseInt(selectedDate?.getMonth() + 1);

    const [loading, setLoading] = useState(false);

    const [getApiData, setGetApiData] = useState([]);
    const [engineeringColumnData, setEngineeringColumnData] = useState([]);
    const [engineeringRowData, setEngineeringRowData] = useState([]);

    const [changesData, setChangesData] = useState([]);
    const [storeData, setStoreData] = useState([]);

    const [count, setCount] = useState(1);
    const [lastPageNumber, setLastPageNumber] = useState([]);

    const [submitToggle, setSubmitToggle] = useState(true);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [monthClosingValue, setMonthClosingValue] = useState(false);

    const [excelExportLoading, setExcelExportLoading] = useState(false);
    const [exportData, setExportData] = useState();
    const [editPmlFlag, setEditPmlFlag] = useState(false);

    var selectedMonthName = selectedDate?.toLocaleString("en-US", {
        month: "short",
    });

    useEffect(() => {
        setSubmitToggle(true);
        setUploadFlag(false);
    }, [count]);

    /* ----- increment/decrement Function for pagination page count -----*/
    const increment = () => {
        setCount((prevActiveStep) => prevActiveStep + 1);
    };
    const decrement = () => {
        setCount((prevActiveStep) => prevActiveStep - 1);
    };

    /* ----- Col Generator Function ----- */
    const colGenerator = () => {
        const engineeringColumnDataCreate = [
            {
                name: "id",
                title: "id",
                readOnly: editPmlFlag ? false : true,
                wordWrap: true,
                type: 'hidden',
            },
            {
                name: "sr_no",
                title: "SR NO",
                readOnly: true,
                width: 60,
            },
            {
                name: "Product Code",
                title: "Product Code",
                readOnly: editPmlFlag ? false : true,
                wordWrap: true,
                width: 80,
            },
            {
                name: "Product Description",
                title: "Product Description",
                readOnly: editPmlFlag ? false : true,
                wordWrap: true,
                width: 300,
            },
            {
                name: "Minimum TSI Bands",
                title: "Minimum TSI Bands",
                width: 130,
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Maximum TSI Bands",
                title: "Maximum TSI Bands",
                wordWrap: true,
                width: 130,
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Minimum PML TSI %",
                title: "Minimum PML TSI %",
                wordWrap: true,
                width: 130,
                type: "number",
                mask: "0.00",
                readOnly: editPmlFlag ? false : true,
            },
            {
                name: "Minimum PML Amount",
                title: "Minimum PML Amount",
                wordWrap: true,
                width: 110,
                readOnly: editPmlFlag ? false : true,
            },
            {
                title: "Action",
                width: 50,
                type: editPmlFlag ? "image" : "hidden",
            },
        ];
        setEngineeringColumnData(engineeringColumnDataCreate);
    };

    /* ----- Function date changes  ----- */
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    /* ----- Function for sample upload file Download ----- */
    const handleSampleFileDownload = () => {
        fetch("engineering_pml_file.csv").then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "engineering_pml_file.csv";
                alink.click();
            });
        });
    };

    /* ----- Function upload file ----- */
    const readUploadFile = async (e) => {
        setLoading(true);
        if (e.target.files[0]) {
            let file = e.target.files[0];
            const formData = new FormData();
            formData.append("file", file);
            try {
                const res = await postRequest(`${UPLOAD_ENGINEERING_PML_CSV}?month=${selectedMonth}&year=${selectedYear}`, formData);
                if (res.type === 1) {
                    successNotification(res?.response?.data?.message);
                    e.target.value = "";
                }
                if (res.type === 2) {
                    errorNotification(res.errormessage);
                    e.target.value = "";
                }
            } catch (error) {
                console.log(error);
            } finally {
                getEngineeringMasterData();
                setLoading(false);
            }
        }
    };

    /* ----- Function for get eng data ----- */
    const getEngineeringMasterData = async () => {
        setLoading(true);
        try {
            const url = `${GET_ENGINEERING_PML}?page=${count}&limit=${pageSize}&month=${selectedMonth}&year=${selectedYear}`;
            const response = await getRequest(url);
            const responseData = response.data.data;
            setMonthClosingValue(responseData.is_closed);
            setGetApiData(responseData.response.page_data);
            setLastPageNumber(
                responseData.response.page_information.last_page
            );
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEngineeringMasterData();
        // eslint-disable-next-line
    }, [selectedMonth, selectedYear, count]);

    useEffect(() => {
        const tempRowData = [];
        if (getApiData?.length !== 0) {
            getApiData?.forEach((data, j) => {
                const { createdAt, updatedAt, month, year, ...rest } = data;
                const tempRowObj = {
                    sr_no: 15 * (count - 1) + j + 1,
                    id: rest.id,
                    "Product Code": rest["Product Code"],
                    "Product Description": rest["Product Description"],
                    "Minimum TSI Bands": rest["Minimum TSI Bands"],
                    "Maximum TSI Bands": rest["Maximum TSI Bands"],
                    "Minimum PML TSI %": rest["Minimum PML TSI %"],
                    "Minimum PML Amount": rest["Minimum PML Amount"],
                };
                tempRowData.push(tempRowObj);
            });
            setEngineeringRowData(tempRowData);
        }
        colGenerator();
        // eslint-disable-next-line
    }, [getApiData, editPmlFlag]);

    const onAfterChanges = () => {
        if (engineeringRef && engineeringRef.current) {
            const healthRefInstance = engineeringRef?.current?.jexcel;
            const changedRowNumber = healthRefInstance.getSelectedRows(true)[0];
            const changedRowData = healthRefInstance.getJsonRow(changedRowNumber);
            setChangesData([...changesData, changedRowData]);
        }
    };

    useEffect(() => {
        if (changesData.length !== 0) {
            const finalData = [...storeData, changesData?.[0]];
            const data = finalData?.filter((cur) => cur?.id !== changesData?.[0]?.id);
            setStoreData([...data, changesData?.[0]]);
        }
        // eslint-disable-next-line
    }, [changesData]);

    const options = {
        data: engineeringRowData,
        columns: engineeringColumnData,
        autoIncrement: false,
        allowInsertColumn: false,
        allowInsertRow: false,
        columnDrag: false,
        contextMenu: false,
        copyCompatibility: true,
        defaultColWidth: 200,
        search: false,
        csvHeaders: true,
        csvFileName: "Opening_Hc",
        ...(uploadFlag && { pagination: 15 }),
        columnSorting: true,
        onafterchanges: onAfterChanges,
        updateTable: function (el, cell, x, y, source, value, label) {
            if (monthClosingValue) {
                cell.classList.add("readonly");
            }
            if (value === null || value === "" || value === "-") {
                cell.innerHTML = '-';
            }
            if (x === engineeringColumnData.length - 1) {
                const deleteIcon = document.createElement("img");
                deleteIcon.src = "https://cdn.iconscout.com/icon/free/png-256/free-delete-2902143-2411575.png";
                deleteIcon.style.width = "20px";
                deleteIcon.style.height = "20px";
                deleteIcon.style.cursor = "pointer";

                deleteIcon.addEventListener("click", function (event) {
                    event.stopPropagation(); // Stop the event from propagating to the table

                    const isConfirmed = window.confirm("Are you sure you want to delete this row?");
                    if (isConfirmed) {
                        const newData = jspreadsheet(engineeringRef.current, options).getData();
                        const deletedRowId = newData[y][0];
                        if (newData[y][0] === '-') {
                            newData.splice(y, 1);
                            setEngineeringRowData(newData);
                        } else {
                            deleteEngineeringPmlList(deletedRowId)
                            setEngineeringRowData(newData);
                        }
                    }
                });
                cell.innerHTML = ''; // Clear existing content
                cell.appendChild(deleteIcon);
            }
            const arrayList = getApiData.map((cur) => cur['Product Code'])
            const color = ['#DDFFBB', '#E3DFFD', '#F3FDE8']
            arrayList.map((cur, index) => {
                if (Number(value) === cur) {
                    const colorIndex = index % color.length;
                    // Assuming 'cell' is the current cell in the row
                    const row = cell.parentNode; // Get the parent row
                    // Loop through all cells in the row and set background color
                    Array.from(row.cells).forEach((rowCell) => {
                        rowCell.style.backgroundColor = color[colorIndex];
                    });
                }
                return null;
            });

        },
    };

    /* ----- Add Button click insert a new row ----- */
    const insertRow = () => {
        const newData = jspreadsheet(engineeringRef.current, options).getData();
        newData.unshift(engineeringColumnData.map((item) => "")); // Add as many nulls as the number of columns
        setEngineeringRowData(newData);
    };

    useEffect(() => {
        if (engineeringRowData?.length !== 0 && !loading) {
            document.getElementById("engineering").innerHTML = "";
            jspreadsheet(engineeringRef.current, options).hideIndex();
        }
        // eslint-disable-next-line
    }, [engineeringRowData]);


    /* ----- Function for delete eng data ----- */
    const deleteEngineeringPmlList = async (deletedRowId) => {
        setLoading(true)
        const obj = {
            data: [{ id: deletedRowId, is_deleted: 'true' }],
        };
        try {
            const res = await patchRequest(`${UPDATE_ENGINEERING_PML}?month=${selectedMonth}&year=${selectedYear}`, obj);
            successNotification(res.data.message);
            getEngineeringMasterData();
            setChangesData([]);
            setStoreData([])
        } catch (error) {
            errorNotification(error.response.data.message);
            getEngineeringMasterData();
        } finally {
            setLoading(false)
        }
    };

    /* ----- Function for update eng data ----- */
    const saveEngineeringData = async () => {
        setLoading(true)
        const newData = storeData.map((item) => {
            const replacedItem = Object.fromEntries(
                Object.entries(item).map(([key, value]) => [key, value === "-" ? null : value])
            );
            return replacedItem;
        });
        const newDataAddIsDeleted = newData.map(item => ({
            ...item,
            is_deleted: 'false',
            id: item.id === '' ? null : item.id
        }));
        const obj = {
            data: newDataAddIsDeleted,
        };
        try {
            const res = await patchRequest(`${UPDATE_ENGINEERING_PML}?month=${selectedMonth}&year=${selectedYear}`, obj);
            successNotification(res.data.message);
            getEngineeringMasterData();
            setChangesData([]);
            setStoreData([])
        } catch (error) {
            errorNotification(error.response.data.message);
            getEngineeringMasterData();
            setChangesData([]);
            setStoreData([])
        } finally {
            setLoading(false)
        }
    };

    /* ----- Function for export sheet data from API ----- */
    const exportSheet = async () => {
        setExcelExportLoading(true);
        try {
            const res = await getRequest(
                `${DOWNLOAD_ENGINEERING_PML_DATA}?month=${selectedMonth}&year=${selectedYear}`
            );
            setExportData(res?.data?.data?.url);
            setExcelExportLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    /* ----- Function for download exported sheet  ----- */
    const handleSaveFile = () => {
        if (exportData) {
            window.open(exportData);
            setExportData();
        }
    };

    const CustomInput = ({ value, onClick }) => (
        <input
            type="text"
            value={value}
            onClick={onClick}
            style={{
                height: "25px",
                borderRadius: "4px",
                border: "1px solid #cccccc",
                padding: "10px",
                fontSize: "12px",
                width: "100%",
            }}
        />
    );

    return (
        <div class="dashboard p-3 pb-md-5 ">
            <div class="row justify-content-between">
                <div class="col sub-header ">
                    <h3 className="mb-lg-0 mb-2">{`Engineering PML Calculation (${selectedMonthName}-${selectedYear})`}</h3>
                </div>
                <div class="col-xl-2 col-md-3 col-sm-12 z-3">
                    <label htmlFor="product" className="dropdown-title">
                        Month/Year
                    </label>
                    <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        customInput={<CustomInput />}
                    />
                </div>
            </div>
            <div style={{ color: 'red' }}><span>Note*</span> :- If you want to upload the data of ENG Pml then download it and make changes in it and upload all data in the files.</div>
            <div className="col-12 mt-3 position-relative ">
                <div className="row mb-3">
                    <div className="col d-flex justify-content-between align-items-center">
                        <div className="col d-flex justify-content-end align-items-center">
                            {!monthClosingValue && (
                                <form style={{ float: "right" }}>
                                    <label
                                        htmlFor="pivot"
                                        className="pe-2"
                                        style={{ fontSize: "14px" }}
                                    >
                                        Edit Engineering PML
                                    </label>
                                    <input
                                        id="pivot"
                                        type="checkbox"
                                        value={editPmlFlag}
                                        checked={editPmlFlag}
                                        onChange={() => setEditPmlFlag(!editPmlFlag)}
                                    />
                                </form>
                            )}
                            {!monthClosingValue && <CustomButton
                                className="mainBtn me-3 btn-sm ms-3"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Import Sample Template"
                                onClick={handleSampleFileDownload}
                            >
                                Format for upload
                            </CustomButton>}
                            {!monthClosingValue && <CustomButton
                                className="mainBtn btn-sm"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Import csv"
                            >
                                <UploadControl onChange={readUploadFile}>
                                    Upload
                                </UploadControl>
                            </CustomButton>}
                            {excelExportLoading ? (
                                <BeatLoader size={8} style={{ marginLeft: '16px' }} />
                            ) : exportData ? (
                                <CustomButton
                                    type="button"
                                    className="mainBtn float-end btn-sm ms-3"
                                    onClick={handleSaveFile}
                                    disabled={getApiData?.length === 0}
                                >
                                    Export
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    type="button"
                                    className="mainBtn float-end btn-sm ms-3"
                                    onClick={exportSheet}
                                    disabled={getApiData?.length === 0}
                                >
                                    Download
                                </CustomButton>
                            )}
                        </div>
                    </div>
                </div>
                {loading ? (
                    <div className="row">
                        <div className="col text-center py-5">
                            <BeatLoader />
                        </div>
                    </div>
                ) : getApiData?.length === 0 ? (
                    <div className="row">
                        <div className="col text-center py-5">No Data Available</div>
                    </div>
                ) : (
                    <>
                        {editPmlFlag && <CustomButton
                            type="submit"
                            className="mainBtn btn-sm mt-2 position-absolute top-0 "
                            handleClick={() => insertRow()}
                        >
                            Add Raw
                        </CustomButton>}
                        <div className="row position-relative">
                            <div className="col">
                                <div className="actual_gwp">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between overflow-auto">
                                            <div ref={engineeringRef} id="engineering" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '970px' }}>
                                {submitToggle ? (
                                    <div className="mt-2 d-flex justify-content-between">
                                        <div className="pageNumber">
                                            Page {count} of {lastPageNumber}
                                        </div>

                                        <div>
                                            <CustomButton
                                                type="button"
                                                className="mainBtn float-end btn-sm ms-3"
                                                disabled={lastPageNumber <= count}
                                                onClick={increment}
                                            >
                                                Next
                                            </CustomButton>
                                            <CustomButton
                                                type="button"
                                                className="mainBtn float-end btn-sm "
                                                disabled={count === 1}
                                                onClick={decrement}
                                            >
                                                Prev
                                            </CustomButton>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {!monthClosingValue && editPmlFlag && (
                                    <div className="col-12">
                                        <CustomButton
                                            type="submit"
                                            className="mainBtn float-end btn-sm mt-2"
                                            handleClick={() => saveEngineeringData()}
                                            disabled={storeData.length === 0}
                                        >
                                            Enter For Save
                                        </CustomButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <EngineeringRiskPml selectedMonthName={selectedMonthName} selectedYear={selectedYear} selectedMonth={selectedMonth} editPmlFlag={editPmlFlag} setEditPmlFlag={setEditPmlFlag} />
        </div>
    );
};

export default EngineeringPmlCalculation




