import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/RGIC_logo.png";
import { ROUTES } from "../routes/Routes";
import "./layout.css";
import {
  errorNotification,
  successNotification,
} from "../../helper/notification";
import { LOG_OUT_EP } from "../routes/ApiEndPoin";
import { postRequest } from "../../helper/AxiosClient";
import { GoTriangleDown } from "react-icons/go";
import { getCurrentUser } from "../../helper/Utils";

const Header = () => {
  const navigate = useNavigate();
  const dropdownRefAdmin = useRef(null);
  const location = useLocation().pathname;
  const [dropdownOpenState, setDropdownOpenState] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRefAdmin.current && !dropdownRefAdmin.current.contains(event.target)) {
        setDropdownOpenState({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      const res = await postRequest(LOG_OUT_EP);
      if (res.type === 1) {
        localStorage.clear();
        navigate(ROUTES.LOGIN_ROUTE);
        successNotification(res.response.data.message);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDropDownAdmin = (menuItemLabel) => {
    setDropdownOpenState((prevState) => ({
      [menuItemLabel]: !prevState[menuItemLabel],
    }));
  };

  const collapseMenu = () => {
    const nav = document.getElementById("navbar");
    document.getElementById("checkbox").checked = false;
    nav.classList.remove("show");
  };

  const headerMenu = [
    {
      label: "Raw Data",
      route: ROUTES.RAW_DATA_ROUTE,
    },
    {
      label: "Report Data",
      route: ROUTES.REPORT_DATA_ROUTE,
    },
    {
      label: "PML Slab",
      subMenu: [
        {
          label: "Fire PML",
          route: ROUTES.PML_CALCULATION,
        },
        {
          label: "Engineering PML",
          route: ROUTES.ENGINEERING_CALCULATION,
        },
      ],
    },
    {
      label: "Retention and Surplus Treaty Slab",
      subMenu: [
        {
          label: "Fire Slabs & Treaty",
          route: ROUTES.SLABS_TREATY,
        },
      ],
    },
    {
      label: "Allocation Master",
      route: ROUTES.ALLOCATION_LOGIC_MASTER,
    },
    {
      label: "Admin Panel",
      subMenu: [
        {
          label: "Users Details",
          route: ROUTES.USER_DETAILS,
        },
        {
          label: "IP Details",
          route: ROUTES.IP_ROUTE,
        },
        {
          label: "Month Closing",
          route: ROUTES.MONTH_CLOSING_ROUTE,
        },
        {
          label: "History",
          route: ROUTES.HISTORY_LIST,
        },
      ],
    },
  ];

  const userPrivileges = getCurrentUser()?.user_privileges;

  const getAllowedMenuItems = (menu, privileges) => {
    return menu.reduce((result, item) => {
      const hasPrivilege = privileges.includes(item.route);

      if (hasPrivilege) {
        // Check if the menu item is already in the result
        if (!result.some((existingItem) => existingItem.route === item.route)) {
          result.push(item);
        }
      }

      if (item.subMenu) {
        // Recursively check and include submenu items
        const allowedSubMenuItems = getAllowedMenuItems(
          item.subMenu,
          privileges
        );
        if (allowedSubMenuItems.length > 0) {
          result.push({
            ...item,
            subMenu: allowedSubMenuItems,
          });
        }
      }

      return result;
    }, []);
  };

  const allowedMenuItems = getAllowedMenuItems(headerMenu, userPrivileges);

  const handleResetPassword = () => {
    navigate(ROUTES.RESET_PASS_ROUTE);
  };
  return (
    <header id="header" className="px-3" style={{ zIndex: "999" }}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <span className="logo d-flex">
          <img src={logo} className="img-fluid mr-2" alt="logo" />
        </span>
        <div className="d-flex order-lg-2 order-1 align-items-center">
          <div
            className="dropdown userDropdown rounded-circle ml-3"
            style={{ backgroundColor: "#034da2" }}
          >
            <span
              className="user_img d-flex align-items-center justify-content-center"
              alt="user_image"
              id="userDropdown"
              data-bs-toggle="dropdown"
            >
              {getCurrentUser()?.username?.at(0)?.toUpperCase()}
            </span>
            <ul className="dropdown-menu py-1" aria-labelledby="userDropdown">
              <li role="button" onClick={() => handleResetPassword()}>
                <span className="dropdown-item">Reset Password</span>
              </li>
              <li role="button" onClick={() => handleLogout()}>
                <span className="dropdown-item">Logout</span>
              </li>
            </ul>
          </div>
          <span className="navbar-toggler p-0">
            <label
              htmlFor="checkbox"
              id="navbarBtn"
              className="humburger"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <input type="checkbox" id="checkbox" />
              <span className="line line-top"></span>
              <span className="line line-middle"></span>
              <span className="line line-bottom"></span>
            </label>
          </span>
        </div>
        <div
          className="collapse navbar-collapse order-2 order-lg-1 center-part-navbar position-relative ms-4"
          id="navbar"
        >
          <ul className="navbar-nav me-auto mb-lg-0">
            {allowedMenuItems.map((menuItem, index) => (
              <li key={index} className="nav-item">
                {menuItem.subMenu ? (
                  <div
                    onClick={() => toggleDropDownAdmin(menuItem.label)}
                    style={{ position: "initial" }}
                    className={`nav-link ${(location === menuItem.route ||
                      menuItem.subMenu.some(
                        (children) => children.route === location
                      )) &&
                      "menu-active"
                      }`}
                  >
                    {menuItem.label} <GoTriangleDown />
                    {dropdownOpenState[menuItem.label] && (
                      <div ref={dropdownRefAdmin} className="dropDownUi">
                        {menuItem.subMenu.map((subItem, subIndex) => (
                          <Link
                            key={subIndex}
                            className={`dropdown-item herachyActiveDropDownItem ${location === subItem.route &&
                              "herachyActiveDropDown"
                              }`}
                            to={subItem.route}
                            onClick={() => collapseMenu()}
                          >
                            {subItem.label}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    className={`nav-link ${location === menuItem.route && "menu-active"
                      }`}
                    to={menuItem.route}
                    onClick={() => setDropdownOpenState({})}
                  >
                    {menuItem.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
