import jspreadsheet from "jspreadsheet-ce";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import { getRequest } from '../../../helper/AxiosClient';
import { customStylesOfSelect } from "../../../helper/customStyleOfSelect";
import CustomButton from '../../common/CustomButton';
import { HISTORY, HISTORY_DOWNLOAD } from "../../routes/ApiEndPoin";

const History = () => {
    const pageSize = 15;
    const historyRef = useRef();
    const [selectedOption, setSelectedOption] = useState({ value: 'all', label: 'All' });
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const [lastPageNumber, setLastPageNumber] = useState();
    const [count, setCount] = useState(1);
    const [getApiDataHistory, setGetApiDataHistory] = useState();
    const [historyRowData, setHistoryRowData] = useState([]);
    const [historyColumnData, setHistoryColumnData] = useState([]);
    const [excelExportLoading, setExcelExportLoading] = useState(false);
    const [exportData, setExportData] = useState();

    /* ----- increment/decrement Function for pagination page count -----*/
    const increment = () => {
        setCount((prevActiveStep) => prevActiveStep + 1);
    };
    const decrement = () => {
        setCount((prevActiveStep) => prevActiveStep - 1);
    };

    const options = [
        { value: 'all', label: 'All' },
        { value: 'last1Day', label: 'Last 1 day' },
        { value: 'last7Days', label: 'Last 7 days' },
        { value: 'last30Days', label: 'Last 30 days' },
        { value: 'dateRange', label: 'Date Range' },
    ];

    const handleModalClose = () => {
        setShowModal(false);
        setEndDate('')
        setStartDate('')
    };

    const handleApplyDateRange = () => {
        if (startDate && endDate) {
            setSelectedOption({ value: 'dateRange', label: `${startDate?.toLocaleDateString()} - ${endDate?.toLocaleDateString()}` });
            setStartDate(startDate?.toLocaleDateString())
            setEndDate(endDate?.toLocaleDateString())
            setShowModal(false);
        }
    };

    /* ----- Col Generator Risk Function ----- */
    const colGeneratorRisk = () => {
        const historyColumnData = [
            {
                name: "sr_no",
                title: "SR NO",
                readOnly: true,
                width: 50
            },
            {
                name: "createdAt",
                title: "Date",
                readOnly: true,
                wordWrap: true,
                width: 130,
            },
            {
                name: "username",
                title: "User Name",
                readOnly: true,
                wordWrap: true,
                width: 130,
            },
            {
                name: "email",
                title: "User Email",
                readOnly: true,
                wordWrap: true,
            },
            {
                name: "Tran ID",
                title: "Tran ID",
                readOnly: true,
                wordWrap: true,
            },
            {
                name: "Screen Name",
                title: "Screen Name",
                readOnly: true,
                wordWrap: true,
            },
            {
                name: "Column Name",
                title: "Column Name",
                readOnly: true,
                wordWrap: true,
                width: 200
            },
            {
                name: "Previous Value",
                title: "Previous Value",
                readOnly: true,
                wordWrap: true,
                width: 250
            },
            {
                name: "New Value",
                title: "New Value",
                readOnly: true,
                wordWrap: true,
                width: 250
            },
            {
                name: "Description",
                title: "Description",
                readOnly: true,
                wordWrap: true,
                width: 700,
            },
            {
                name: "month",
                title: "Month",
                readOnly: true,
                wordWrap: true,
                width: 50,
            },
            {
                name: "year",
                title: "Year",
                readOnly: true,
                wordWrap: true,
                width: 50,
            },
            {
                name: "IP Address",
                title: "IP Address",
                readOnly: true,
                wordWrap: true,
                width: 100,
            },
        ];
        setHistoryColumnData(historyColumnData);
    };

    /* ----- API call for get pml Risk -----*/
    const getHistoryDataApiCall = async () => {
        setLoading(true)
        try {
            const res = await getRequest(`${HISTORY}?page=${count}&limit=${pageSize}&start_date=${JSON.stringify(new Date(startDate)).slice(1, 11) === 'ull' ? '' : JSON.stringify(new Date(startDate)).slice(1, 11)}&end_date=${JSON.stringify(new Date(endDate)).slice(1, 11) === 'ull' ? '' : JSON.stringify(new Date(endDate)).slice(1, 11)}`);
            setGetApiDataHistory(res.data.data.page_data);
            setLastPageNumber(res.data.data.page_information.last_page)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getHistoryDataApiCall()
        // eslint-disable-next-line
    }, [count, startDate, endDate]);

    const optionsRisk = {
        data: historyRowData,
        columns: historyColumnData,
        autoIncrement: false,
        allowInsertColumn: false,
        allowInsertRow: false,
        columnDrag: false,
        contextMenu: false,
        copyCompatibility: true,
        defaultColWidth: 200,
        search: false,
        csvHeaders: true,
        csvFileName: "Opening_Hc",
        columnSorting: true,
        editable: true,
    };

    useEffect(() => {
        if (historyRowData?.length !== 0 && !loading) {
            document.getElementById("historyTable").innerHTML = "";
            jspreadsheet(historyRef.current, optionsRisk).hideIndex();
        }
        // eslint-disable-next-line
    }, [historyRowData]);

    useEffect(() => {
        const tempRowData = [];
        if (getApiDataHistory?.length !== 0) {
            getApiDataHistory?.forEach((data, j) => {
                const { updatedAt, id, ...rest } = data;
                const tempRowObj = {
                    sr_no: 15 * (count - 1) + j + 1,
                    "createdAt": moment(rest.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    "username": rest.username,
                    "email": rest.email,
                    'Tran ID': rest["Tran ID"],
                    'Screen Name': rest["Screen Name"],
                    'Column Name': rest["Column Name"],
                    'Previous Value': rest["Previous Value"],
                    'New Value': rest["New Value"],
                    'Description': rest["Description"] || '-',
                    'month': rest["month"],
                    'year': rest["year"],
                    'IP Address': rest["IP Address"],
                };
                tempRowData.push(tempRowObj);
            });
            setHistoryRowData(tempRowData);
        }
        colGeneratorRisk();
        // eslint-disable-next-line
    }, [getApiDataHistory]);

    const handleSelect = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption.value === 'all') {
            setStartDate('');
            setEndDate('');
        } else if (selectedOption.value === 'dateRange') {
            setShowModal(true); // Open modal for selecting date range
        } else if (selectedOption.value === 'last1Day') {
            // Calculate start and end dates for the last 1 day
            const endDate = new Date(); // End date is today
            const startDate = new Date(endDate); // Start date is also today
            startDate.setDate(startDate.getDate() - 1); // Subtract 1 day from start date
            setStartDate(startDate);
            setEndDate(endDate);
        } else if (selectedOption.value === 'last7Days') {
            // Calculate start and end dates for the last 7 days
            const endDate = new Date(); // End date is today
            const startDate = new Date(endDate); // Start date is today
            startDate.setDate(startDate.getDate() - 6); // Subtract 6 days from start date
            setStartDate(startDate);
            setEndDate(endDate);
        } else if (selectedOption.value === 'last30Days') {
            // Calculate start and end dates for the last 30 days
            const endDate = new Date(); // End date is today
            const startDate = new Date(endDate); // Start date is today
            startDate.setDate(startDate.getDate() - 29); // Subtract 29 days from start date
            setStartDate(startDate);
            setEndDate(endDate);
        } else {
            // For other options, reset start and end dates
            setStartDate(null);
            setEndDate(null);
        }
    };

    /* ----- Function for export sheet data from API ----- */
    const exportSheet = async () => {
        setExcelExportLoading(true);
        try {
            const res = await getRequest(
                `${HISTORY_DOWNLOAD}?start_date=${JSON.stringify(new Date(startDate)).slice(1, 11) === 'ull' ? '' : JSON.stringify(new Date(startDate)).slice(1, 11)}&end_date=${JSON.stringify(new Date(endDate)).slice(1, 11) === 'ull' ? '' : JSON.stringify(new Date(endDate)).slice(1, 11)}`
            );
            setExportData(res?.data?.data?.url);
            setExcelExportLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    /* ----- Function for download exported sheet  ----- */
    const handleSaveFile = () => {
        if (exportData) {
            window.open(exportData);
            setExportData();
        }
    };

    return (
        <>
            <section className="dashboard p-3 pb-md-5">
                <div className="container-fluid">
                    <div className="sub-header row pb-sm-2">
                        <div className="col-xl-3 col-lg-12">
                            <h3 className="mb-lg-0 mb-2">History</h3>
                        </div>
                        <div className="col-xl-9 col-12 mt-xxl-0 mt-2">
                            <div class="row justify-content-end">
                                <div class="col-lg-3 col-md-4 col-12 mt-xl-0 mt-2">
                                    <label htmlFor="product" className="dropdown-title">
                                        History Filter
                                    </label>
                                    <Select
                                        id="data filter"
                                        name="data filter"
                                        options={options}
                                        value={selectedOption}
                                        components={{ IndicatorSeparator: () => null }}
                                        styles={customStylesOfSelect}
                                        onChange={handleSelect}
                                    />
                                </div>
                                <div className="mt-3 d-flex justify-content-end">
                                    {excelExportLoading ? (
                                        <BeatLoader size={8} />
                                    ) : exportData ? (
                                        <CustomButton
                                            type="button"
                                            className="mainBtn float-end btn-sm"
                                            onClick={handleSaveFile}
                                            disabled={getApiDataHistory?.length === 0}
                                        >
                                            Export
                                        </CustomButton>
                                    ) : (
                                        <CustomButton
                                            type="button"
                                            className="mainBtn float-end btn-sm"
                                            onClick={exportSheet}
                                            disabled={getApiDataHistory?.length === 0}
                                        >
                                            Download
                                        </CustomButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 overflow-auto mt-3">
                            {loading ? (
                                <div className="row">
                                    <div className="col text-center py-5">
                                        <BeatLoader />
                                    </div>
                                </div>
                            ) : (
                                <div className="row position-relative">
                                    <div className="col">
                                        {getApiDataHistory?.length === 0 ? (
                                            <div className="row">
                                                <div className="col text-center py-5">No Data Available</div>
                                            </div>
                                        ) : <div className="actual_gwp">
                                            <div className="d-flex flex-column">
                                                <div className=" d-flex justify-content-between overflow-auto">
                                                    <div ref={historyRef} id="historyTable" />
                                                </div>
                                            </div>
                                        </div>}
                                        {getApiDataHistory?.length !== 0 ? (
                                            <div className="mt-2 d-flex justify-content-between">
                                                <div className="pageNumber">
                                                    Page {count} of {lastPageNumber}
                                                </div>
                                                <div>
                                                    <CustomButton
                                                        type="button"
                                                        className="mainBtn float-end btn-sm ms-3"
                                                        disabled={lastPageNumber <= count}
                                                        onClick={increment}
                                                    >
                                                        Next
                                                    </CustomButton>
                                                    <CustomButton
                                                        type="button"
                                                        className="mainBtn float-end btn-sm "
                                                        disabled={count === 1}
                                                        onClick={decrement}
                                                    >
                                                        Prev
                                                    </CustomButton>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal */}
            {
                showModal && (
                    <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.9)' }}>
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Select Date Range</h5>
                                </div>
                                <div className="modal-body d-flex">
                                    <div>
                                        <span>Start Date</span>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={date => setStartDate(date)}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            dateFormat='dd-MM-yyyy'
                                        />
                                    </div>
                                    <div>
                                        <span>End Date</span>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={date => setEndDate(date)}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            dateFormat='dd-MM-yyyy'
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <CustomButton type="button" style={{ backgroundColor: '#528ee5', border: '#528ee5' }} className="btn-sm btn-primary" onClick={handleApplyDateRange}>
                                        Apply
                                    </CustomButton>
                                    <CustomButton type="button" className="btn-secondary btn-sm" onClick={handleModalClose}>
                                        Close
                                    </CustomButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default History