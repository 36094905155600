import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../components/routes/Routes";
import { getCurrentUser } from "./Utils";

export const CheckAuth = () => {
  const isLoggedIn = getCurrentUser()?.token;
  return !isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.RAW_DATA_ROUTE} replace />
  );
};
