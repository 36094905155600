export const customStylesOfSelect = {
  control: (provided, state) => ({
    ...provided,
    height: "8px",
    minHeight: "25px",
    // maxHeight : "auto",
    boxShadow: "none",
    alignContent: "center",
    fontSize: "12px",
    borderColor: state.isFocused ? "#83bbfb" : provided.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "#83bbfb" : provided.borderColor,
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "3px 10px",
    backgroundColor: state.isSelected
      ? "rgba(131, 187, 251, 0.8)"
      : state.isFocused
        ? "rgba(131, 187, 251, 0.2)"
        : undefined,
    ":active": {
      ...provided[":active"],
      backgroundColor: state.isSelected
        ? "rgba(131, 187, 251, 0.8)"
        : "rgba(131, 187, 251, 0.2)",
    },
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    zIndex: 9,
    right: 0,
    fontSize: "12px",
    fontWeight: "normal",
  }),
};

export const customStylesOfMultiSelect = {
  control: (provided, state) => ({
    ...provided,
    height: "unset",
    minHeight: "8px",
    maxHeight: "auto",
    boxShadow: "none",
    alignContent: "center",
    fontSize: "14px",
    borderColor: state.isFocused ? "#83bbfb" : provided.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? "#83bbfb" : provided.borderColor,
    }
  }),

  option: (provided, state) => ({
    ...provided,
    padding: "3px 5px",
    backgroundColor: state.isSelected
      ? "rgba(131, 187, 251, 0.8)"
      : state.isFocused
        ? "rgba(131, 187, 251, 0.2)"
        : undefined,
    ":active": {
      ...provided[":active"],
      backgroundColor: state.isSelected
        ? "rgba(131, 187, 251, 0.8)"
        : "rgba(131, 187, 251, 0.2)",
    },
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    minWidth: "100%",
    zIndex: 9,
    right: 0,
    fontSize: "12px",
    fontWeight: "normal",
  }),
};