const UploadControl = ({ children, value, onChange, disabled, accept }) => {
  return (
    <>
      <label
        className="m-0 w-100 d-flex align-items-center"
        style={{ cursor: "pointer" }}
      >
        <input
          value={value}
          accept={accept}
          disabled={disabled}
          style={{ display: "none" }}
          type="file"
          onChange={disabled ? () => { } : onChange}
        />
        {children}
      </label>
    </>
  );
};

export default UploadControl;
