import React from "react";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import { GlobalFilter } from "./GlobalFilter";

const TableOfDetails = ({ columns, data, filter = true }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  const { globalFilter } = state;
  return (
    <>
      {filter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
      <table {...getTableProps()} className="mt-2">
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()}>
              {headerGroup?.headers?.map((column) => (
                <th
                  {...column?.getHeaderProps({
                    style: { minWidth: column?.minWidth, width: column?.width },
                  })}
                >
                  {column?.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows?.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row?.cells?.map((cell) => {
                  return (
                    <td
                      {...cell?.getCellProps({
                        style: {
                          minWidth: cell?.column?.minWidth,
                          width: cell?.column?.width,
                        },
                      })}
                    >
                      {cell?.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TableOfDetails;
