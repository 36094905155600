import React, { useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import Wrapper from './components/layout/Wrapper';
import SignInPage from './components/pages/login/SignInPage';
import NotFoundPage from './components/pages/NotFoundPage';
import { ROUTES } from './components/routes/Routes';
import AddNewUser from './components/view/admin/AddNewUser';
import IpDetails from './components/view/admin/IpDetails';
import UsersDetails from './components/view/admin/UsersDetails';
import AllocationMaster from './components/view/allocationMaster/AllocationMaster';
import MonthClosing from './components/view/monthClosing/MonthClosing';
import FirePmlCalculation from './components/view/pml/FirePmlCalculation';
import FinalReportData from './components/view/report/FinalReportData';
import RiRawData from './components/view/ri/RiRawdata';
import FireSlabsAndTreaty from './components/view/slabs&treaty/FireSlabsAndTreaty';
import { SocketContext } from './context/socketContext';
import { CheckAuth } from './helper/CheckAuth';
import { Layout } from './helper/Layout';
import RequireAuth from './helper/RequireAuth';
import "/node_modules/jspreadsheet-ce/dist/jspreadsheet.css";
import "/node_modules/jsuites/dist/jsuites.css";
import ResetPassword from './components/pages/resetPasswoard/ResetPassword';
import PrivateRoute from './helper/PrivateRoute';
import EngineeringPmlCalculation from './components/view/pml/EngineeringPmlCalculation';
import History from './components/view/history/History';

const App = () => {
  const socketRef = useRef(null);
  const publicRoute = [
    {
      to: ROUTES.LOGIN_ROUTE,
      Component: SignInPage,
    },
  ];
  const routesData = [
    {
      to: ROUTES.PAGE_NOT_FOUND_ROUTE,
      Component: NotFoundPage,
    },
    {
      to: ROUTES.RAW_DATA_ROUTE,
      Component: RiRawData,
    },
    {
      to: ROUTES.REPORT_DATA_ROUTE,
      Component: FinalReportData,
    },
    {
      to: ROUTES.PML_CALCULATION,
      Component: FirePmlCalculation,
    },
    {
      to: ROUTES.ENGINEERING_CALCULATION,
      Component: EngineeringPmlCalculation,
    },
    {
      to: ROUTES.SLABS_TREATY,
      Component: FireSlabsAndTreaty,
    },
    {
      to: ROUTES.ALLOCATION_LOGIC_MASTER,
      Component: AllocationMaster,
    },
    {
      to: ROUTES.USER_DETAILS,
      Component: UsersDetails,
    },
    {
      to: ROUTES.ADD_NEW_USER,
      Component: AddNewUser,
    },
    {
      to: ROUTES.EDIT_USER,
      Component: AddNewUser,
    },
    {
      to: ROUTES.IP_ROUTE,
      Component: IpDetails,
    },
    {
      to: ROUTES.MONTH_CLOSING_ROUTE,
      Component: MonthClosing,
    },
    {
      to: ROUTES.HISTORY_LIST,
      Component: History,
    },
  ];

  return (
    <Wrapper>
      <SocketContext.Provider value={socketRef?.current}>
        <Routes>
          <Route element={<CheckAuth />}>
            {publicRoute.map(({ to, Component }) => {
              return (
                <Route key={to}>
                  <Route path={to} element={<Component />} />
                </Route>
              );
            })}
          </Route>
          <Route element={<Layout />}>
            {routesData.map(({ to, Component }) => {
              return (
                <Route
                  key={to}
                  element={<RequireAuth to={to} />}
                >
                  <Route path={to} element={<Component />} />
                </Route>
              );
            })}
            <Route
              path="*"
              element={<Navigate to={ROUTES.PAGE_NOT_FOUND_ROUTE} />}
            />
          </Route>
          <Route element={<PrivateRoute redirectTo={ROUTES.LOGIN_ROUTE} />} >
            <Route path={ROUTES.RESET_PASS_ROUTE} element={<ResetPassword />} />
          </Route>
        </Routes>
        <ToastContainer limit={1} />
      </SocketContext.Provider>
    </Wrapper>
  )
}

export default App
