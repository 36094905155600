import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import { getRequest, patchRequest } from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import CustomButton from "../../common/CustomButton";
import {
  EDIT_RETENTION_DATA,
  GET_RETENTION_DATA,
} from "../../routes/ApiEndPoin";

const FireSlabsAndTreaty = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editSlabsFlag, setEditSlabsFlag] = useState(false);
  const selectedMonth = parseInt(selectedDate?.getMonth() + 1);
  const selectedYear = selectedDate?.getFullYear();
  const [monthClosingValue, setMonthClosingValue] = useState(false);
  var selectedMonthName = selectedDate?.toLocaleString("en-US", {
    month: "short",
  });

  const [loading, setLoading] = useState(true);
  const slabRef = useRef();
  const [getApiData, setGetApiData] = useState([]);
  const [slabsAndTreatyColumnData, setSlabsAndTreatyColumnData] = useState([]);
  const [slabsAndTreatyRowData, setSlabsAndTreatyRowData] = useState([]);

  /* ----- Col Generator Function ----- */
  const colGenerator = () => {
    const slabsColumnData = [
      {
        type: "Minimum",
        title: "Minimum",
        width: "150",
        readOnly: editSlabsFlag ? false : true,
      },
      {
        type: "Maximum",
        title: "Maximum",
        width: "150",
        readOnly: editSlabsFlag ? false : true,
      },
      {
        title: "Retention",
        width: "150",
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editSlabsFlag ? false : true,
      },
      {
        title: "Surplus Treaty",
        width: "150",
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editSlabsFlag ? false : true,
      },
      {
        title: "Lines",
        width: "80",
        type: "number",
        locale: "en-IN",
        options: { currency: "INR" },
        readOnly: editSlabsFlag ? false : true,
      },
      {
        title: "Action",
        width: 50,
        type: editSlabsFlag ? "image" : "hidden",
      },
    ];
    setSlabsAndTreatyColumnData(slabsColumnData);
  };

  /* ----- API call for get motor data -----*/
  const getSlabsAndTreatyReportData = async () => {
    try {
      const res = await getRequest(
        `${GET_RETENTION_DATA}?month=${selectedMonth}&year=${selectedYear}`
      );
      setGetApiData(res?.data?.data?.retentionData);
      setMonthClosingValue(res?.data?.data?.is_closed)
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getSlabsAndTreatyReportData();
    setEditSlabsFlag(false);
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    const tempRowData = [];
    if (getApiData?.length !== 0) {
      getApiData?.forEach((data, j) => {
        const { createdAt, updatedAt, id, ...rest } = data;
        const tempRowObj = {
          Minimum: rest["Minimum"],
          Maximum: rest["Maximum"],
          Retention: rest["Retention"],
          "Surplus Treaty": rest["Surplus Treaty"],
          Lines: rest["Lines"],
        };
        tempRowData.push(tempRowObj);
      });
      setSlabsAndTreatyRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [getApiData, editSlabsFlag]);

  const options = {
    data: slabsAndTreatyRowData,
    columns: slabsAndTreatyColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    columnSorting: true,
    onchange: onchange,
    nestedHeaders: [
      [
        {
          title: "Sum Insured Slab",
          colspan: "6",
        },
      ],
    ],
    updateTable: function (el, cell, x, y, col, source, value, label, items) {
      if (x === slabsAndTreatyColumnData.length - 1) {
        const deleteIcon = document.createElement("img");
        deleteIcon.src = "https://cdn.iconscout.com/icon/free/png-256/free-delete-2902143-2411575.png";
        deleteIcon.style.width = "20px";
        deleteIcon.style.height = "20px";
        deleteIcon.style.cursor = "pointer";

        // Attach click event to the delete icon
        deleteIcon.addEventListener("click", function (event) {
          event.stopPropagation();
          const isConfirmed = window.confirm("Are you sure you want to delete this row?");

          if (isConfirmed) {
            const newData = jspreadsheet(slabRef.current, options).getData();
            newData.splice(y, 1);
            setSlabsAndTreatyRowData(newData);
          }
        });
        cell.innerHTML = ''; // Clear existing content
        cell.appendChild(deleteIcon);
      }
    },
  };

  useEffect(() => {
    if (slabsAndTreatyRowData?.length !== 0 && !loading) {
      document.getElementById("slabsForRetention").innerHTML = "";
      jspreadsheet(slabRef.current, options);
    }
    // eslint-disable-next-line
  }, [slabsAndTreatyRowData]);

  const saveSlabsData = async () => {
    setLoading(true);
    const healthRefInstance = slabRef?.current?.jexcel;
    const jexcelRecords = healthRefInstance?.getJson();
    const jsonRecord = JSON?.parse(JSON?.stringify(jexcelRecords));

    const newData = jsonRecord.map(({ "5": _, ...rest }) => {
      const convertedObject = Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          typeof value === 'string' && !isNaN(value.replace(/,/g, '')) ? parseFloat(value.replace(/,/g, '')) : value,
        ])
      );
      return convertedObject;
    });

    const pmlSave = {
      retention_data: newData,
      month: selectedMonth,
      year: selectedYear,
    };
    try {
      const res = await patchRequest(`${EDIT_RETENTION_DATA}`, pmlSave);
      successNotification(res?.data?.message);
      getSlabsAndTreatyReportData();
    } catch (error) {
      errorNotification(error.response.data.message);
      getSlabsAndTreatyReportData()
    } finally {
      setLoading(false);
    }
  };

  /* ----- Function date changes  ----- */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{
        height: "25px",
        borderRadius: "4px",
        border: "1px solid #cccccc",
        padding: "10px",
        fontSize: "12px",
        width: "100%",
      }}
    />
  );

  const insertRow = () => {
    const newData = jspreadsheet(slabRef.current, options).getData();
    newData.push(slabsAndTreatyColumnData.map((item) => ""));
    setSlabsAndTreatyRowData(newData);
  };

  return (
    <div class="dashboard p-3 pb-md-5 ">
      <div class="row justify-content-between">
        <div class="col sub-header ">
          <h3 className="mb-lg-0 mb-2">{`Slabs forRetention & Treaty capacity calculation (${selectedMonthName}-${selectedYear})`}</h3>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12 z-3">
          <label htmlFor="product" className="dropdown-title">
            Month/Year
          </label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            showMonthYearPicker
            dateFormat="MM/yyyy"
            customInput={<CustomInput />}
          />
        </div>
        <div>
          {!monthClosingValue && (
            <form style={{ float: "right", marginTop: "10px" }}>
              <label
                htmlFor="pivot"
                className="pe-2"
                style={{ fontSize: "14px" }}
              >
                Edit Slabs & Treaty
              </label>
              <input
                id="pivot"
                type="checkbox"
                checked={editSlabsFlag}
                value={editSlabsFlag}
                onChange={() => setEditSlabsFlag(!editSlabsFlag)}
              />
            </form>
          )}
        </div>
        <div className="col-12 mt-3 position-relative">
          {loading ? (
            <div className="row">
              <div className="col text-center py-5">
                <BeatLoader />
              </div>
            </div>
          ) : getApiData?.length === 0 ? (
            <div className="row">
              <div className="col text-center py-5">No Data Available</div>
            </div>
          ) : (
            <div class="row">
              <div class="col-12">
                <div ref={slabRef} id="slabsForRetention" />
                {editSlabsFlag && (
                  <div className="d-flex justify-content-start">
                    <CustomButton
                      type="button"
                      className="mainBtn float-end btn-sm mt-2"
                      style={{ marginRight: "625px" }}
                      handleClick={() => insertRow()}
                    >
                      Add
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      className="mainBtn float-end btn-sm mt-2"
                      handleClick={() => saveSlabsData()}
                    >
                      Enter For Save
                    </CustomButton>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FireSlabsAndTreaty;
