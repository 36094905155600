import axios from 'axios';
import { API_URL } from '../components/routes/ApiEndPoin';
import { ROUTES } from '../components/routes/Routes';
import { errorNotification } from './notification';
import { getCurrentUser } from './Utils';

const baseURL = API_URL;

export const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getCurrentUser()?.token,
};

export function unAuthorized(navigate) {
    localStorage.clear();
    navigate(ROUTES.LOGIN_ROUTE)
}

export function getRequest(URL, params, navigate) {
    axiosClient.defaults.params = params;
    axiosClient.defaults.headers.Authorization = `${getCurrentUser()?.token
        }`;
    return axiosClient
        .get(`/${URL}`)
        .then((response) => response)
        .catch((error) => {
            if (error.response.status === 401) {
                errorNotification(error?.response?.data?.message);
                unAuthorized(navigate);
            }

            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

export function postRequest(URL, payload, navigate) {
    axiosClient.defaults.headers.Authorization = `${getCurrentUser()?.token
        }`;
    return axiosClient
        .post(`/${URL}`, payload)
        .then((response) => {
            return { type: 1, response };
        })
        .catch((error) => {
            if (error.response.status === 401) {
                errorNotification(error?.response?.data?.message);
                unAuthorized(navigate);
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

export function putRequest(URL, payload, navigate) {
    axiosClient.defaults.headers.Authorization = `${getCurrentUser()?.token
        }`;
    return axiosClient
        .put(`/${URL}`, payload)
        .then((response) => {
            return { type: 1, response };
        })
        .catch((error) => {
            if (error.response.status === 401) {
                errorNotification(error?.response?.data?.message);
                unAuthorized(navigate);
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

export function patchRequest(URL, payload) {
    axiosClient.defaults.headers.Authorization = `${getCurrentUser()?.token
        }`;
    return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL, navigate) {
    axiosClient.defaults.headers.Authorization = `${getCurrentUser()?.token
        }`;
    return axiosClient
        .delete(`/${URL}`)
        .then((response) => {
            return { type: 1, response };
        })
        .catch((error) => {
            if (error.response.status === 401) {
                errorNotification(error?.response?.data?.message);
                unAuthorized(navigate);
            }
            const errors = error?.message;
            const errormessage = error?.response?.data?.message;
            return { type: 2, errors, errormessage };
        });
}

// export async function getRequest(URL, params, navigate) {

//     const res = await axios.get(GET_CLIENT_SYS_IP_EP);

//     axiosClient.defaults.headers.Authorization = getCurrentUser()?.token;

//     if (res.status === 200) {
//         axiosClient.defaults.headers.client_ip = res?.data?.ip
//         axiosClient.defaults.params = params

//         return axiosClient
//             .get(`/${URL}`)
//             .then((response) => response)
//             .catch((error) => {
//                 if (error.response.status === 401) {
//                     errorNotification(error?.response?.data?.message)
//                     unAuthorized(navigate)
//                 }

//                 const errors = error?.message;
//                 const errormessage = error?.response?.data?.message;
//                 return { type: 2, errors, errormessage };
//             });
//     }
// }

// export async function postRequest(URL, payload, navigate) {
//     axiosClient.defaults.headers.Authorization = getCurrentUser()?.token;
//     const res = await axios.get(GET_CLIENT_SYS_IP_EP);

//     if (res.status === 200) {
//         axiosClient.defaults.headers.client_ip = res?.data?.ip;
//         return axiosClient
//             .post(`/${URL}`, payload)
//             .then((response) => {
//                 return { type: 1, response };
//             })
//             .catch((error) => {
//                 if (error.response.status === 401) {
//                     errorNotification(error?.response?.data?.message)
//                     unAuthorized(navigate)
//                 }
//                 const errors = error?.message;
//                 const errormessage = error?.response?.data?.message;
//                 return { type: 2, errors, errormessage };
//             });
//     }
// }

// export async function putRequest(URL, payload, navigate) {
//     axiosClient.defaults.headers.Authorization = getCurrentUser()?.token;
//     const res = await axios.get(GET_CLIENT_SYS_IP_EP);

//     if (res.status === 200) {
//         axiosClient.defaults.headers.client_ip = res?.data?.ip;
//         return axiosClient
//             .put(`/${URL}`, payload)
//             .then((response) => {
//                 return { type: 1, response };
//             })
//             .catch((error) => {
//                 if (error.response.status === 401) {
//                     errorNotification(error?.response?.data?.message)
//                     unAuthorized(navigate)
//                 }
//                 const errors = error?.message;
//                 const errormessage = error?.response?.data?.message;
//                 return { type: 2, errors, errormessage };
//             });
//     }
// }

// export async function deleteRequest(URL, navigate) {
//     axiosClient.defaults.headers.Authorization = getCurrentUser()?.token;

//     const res = await axios.get(GET_CLIENT_SYS_IP_EP);
//     if (res.status === 200) {
//         axiosClient.defaults.headers.client_ip = res?.data?.ip;
//         return axiosClient
//             .delete(`/${URL}`)
//             .then((response) => { return { type: 1, response } })
//             .catch((error) => {
//                 if (error.response.status === 401) {
//                     errorNotification(error?.response?.data?.message)
//                     unAuthorized(navigate)
//                 }
//                 const errors = error?.message;
//                 const errormessage = error?.response?.data?.message;
//                 return { type: 2, errors, errormessage };
//             });
//     }
// }

// export function patchRequest(URL, payload) {
//     axiosClient.defaults.headers.Authorization = getCurrentUser()?.token;
//     return axiosClient.patch(`/${URL}`, payload).then((response) => response);
// }
