import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { patchRequest, postRequest } from "../../../helper/AxiosClient";
import { customStylesOfSelect } from "../../../helper/customStyleOfSelect";
import {
  errorNotification,
  successNotification
} from "../../../helper/notification";
import CustomButton from "../../common/CustomButton";
import { ADD_USER, EDIT_USER } from "../../routes/ApiEndPoin";

const userPrivileges = [
  "/row-data",
  "/report-data",
  "/allocation-master",
  "/fire-pml-calculation",
  "/engineering-pml-calculation",
  "/fire-slabs-treaty",
  "/user-details",
  "/ipInfo",
  "/month-closing",
  "/history",
];

const optionOfUserPrevilages = userPrivileges?.map((data) => ({
  value: data,
  label: data.toLocaleUpperCase(),
}));

const AddNewUser = ({ userEditData, onCloseModal, getUser }) => {
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    password: "",
    user_privileges: "",
  });

  /* ----- set Edit user profile data in state with permission -----*/
  useEffect(() => {
    if (userEditData.id) {
      const editData = userEditData;
      const businessType = [];
      const userPrevilages = [];
      editData?.business_type?.forEach((bt) => {
        businessType.push({ value: bt, label: bt.toUpperCase() });
      });
      editData?.user_privileges?.forEach((up) => {
        userPrevilages.push({ value: up, label: up.toUpperCase() });
      });

      setUserData({
        username: editData.username,
        email: editData.email,
        password: editData.password,
        user_privileges: userPrevilages,
      });
    }
    // eslint-disable-next-line
  }, []);

  /* ----- API call for add single User data -----*/
  const addUser = async (payload) => {
    try {
      const res = await postRequest(ADD_USER, payload);
      if (res.type === 1) {
        successNotification("User added successfully");
        getUser()
        onCloseModal()
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      errorNotification(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  /* ----- handle For Submit user Data ( ADD/EDIT ) -----*/
  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const userInfo = {
      username: userData?.username,
      email: userData?.email,
      password: userData?.password,
      user_privileges: userData?.user_privileges?.map((data) => data?.value),
    };
    if (userEditData.id) {
      await updateUser(userInfo);
    } else {
      await addUser(userInfo);
    }
  };

  /* ----- API call for update User data -----*/
  const updateUser = async (payload) => {
    try {
      const res = await patchRequest(EDIT_USER + userEditData.id, payload);
      successNotification(res.data.message);
      getUser()
      onCloseModal()
    } catch (error) {
      errorNotification(error.response.data.message);
    }
  };

  return (
    <section className="">
      <div className="row">
        <Form.Group className="mb-3">
          <Form.Label>User Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter User Name"
            name="username"
            value={userData.username}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
            required
            disabled={userEditData.id}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>{userEditData.id ? 'Reset Password' : 'Password'}</Form.Label>
          <Form.Control
            type="text"
            placeholder={userEditData.id ? "Enter Reset Password" : "Enter password"}
            name="password"
            value={userData.password}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="mt-2">User Privileges</Form.Label>
          <div className="position-relative">
            <Select
              id="userPrevilages"
              options={optionOfUserPrevilages}
              closeMenuOnSelect={false}
              value={userData.user_privileges}
              components={{ IndicatorSeparator: () => null }}
              isMulti
              styles={{
                ...customStylesOfSelect,
                control: (provided, state) => ({
                  ...provided,
                  height: "unset",
                  minHeight: "30px",
                  boxShadow: "none",
                  alignContent: "center",
                  fontSize: "14px",
                  borderColor: state.isFocused
                    ? "#83bbfb"
                    : provided.borderColor,
                  "&:hover": {
                    borderColor: state.isFocused
                      ? "#83bbfb"
                      : provided.borderColor,
                  },
                }),
              }}
              onChange={(e) =>
                setUserData({ ...userData, user_privileges: e })
              }
              required
              placeholder="Select User Previlages"
              style={{ width: "100%" }}
            />
            <Form.Control
              type="text"
              value={userData.user_privileges}
              style={{
                position: "absolute",
                zIndex: -1,
                opacity: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
              required
            />
          </div>
        </Form.Group>
      </div>
      <div className="col-12">
        <div className="text-end mt-3">
          <CustomButton
            className="secondaryBtn me-3"
            type="button"
            onClick={onCloseModal}
          >
            Back
          </CustomButton>
          <CustomButton className="mainBtn" onClick={handleFormSubmit}>
            {userEditData.id ? "Update" : "Submit"}
          </CustomButton>
        </div>
      </div>
    </section>
  );
};

export default AddNewUser;
