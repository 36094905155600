// export const API_URL = 'http://157.245.111.250:8585/api/v1';
export const API_URL = "https://rgicri.acgindia.co.in/api/v1";
// export const API_URL = 'https://ribackend.acgindia.co.in/api/v1';

export const LOGIN_EP = "login";
export const LOG_OUT_EP = "logout";
export const RESET_PASS = "reset-password";

export const UPLOAD_RAW_DATA = "upload-insured-row-data";
export const GET_ROW_DATA = "get-insured-row-data";
export const GET_PML_DATA = "get-pml-data";
export const EDIT_PML_DATA = "edit-pml-data";
export const GET_RETENTION_DATA = "get-retention-data";
export const EDIT_RETENTION_DATA = "edit-retention-data";
export const GET_PRODUCT_LIST = "get-insured-products";
export const GET_PML_RISK = "get-risk-pml-data";
export const UPDATE_PML_RISK = "edit-risk-pml-data";

export const GET_ENGINEERING_PML = "get-eng-pml-data";
export const UPDATE_ENGINEERING_PML = "edit-eng-pml-data";
export const UPLOAD_ENGINEERING_PML_CSV = "upload-eng-pml-file";
export const DOWNLOAD_ENGINEERING_PML_DATA = "export-eng-pml-file";

export const GET_CLIENT_SYS_IP_EP = "https://api.ipify.org/?format=json";
export const GET_IP_EP = "get-ip";
export const ADD_IP_EP = "add-ip";
export const DELETE_IP_EP = "delete-ip?ip_id=";

export const GET_ALL_USER = "get-users";
export const ADD_USER = "add-user";
export const EDIT_USER = "edit-user?user_id=";
export const DELETE_USER = "delete-user?user_id=";

export const ALLOCATION_MASTER_LIST = "get-allocation-master";
export const POST_ALLOCATION_MASTER_CSV = "upload-allocation-file";
export const PRODUCT_CLASS = "get-allocation-product-class";
export const PRODUCT_CODE = "get-allocation-product-codes";
export const EDIT_ALLOCATION_DATA = "edit-allocation-master";

export const CLOSING_MONTH = "get-month-closing";
export const EDIT_CLOSING_MONTH = "update-month-closing";

export const GET_ALL_DROPDOWN_LIST = "get-insured-report-drop-down-lists";
export const GET_REPORT_DATA = "get-insured-report-data";
export const GET_REPORT_DATA_EXPORT = "export-insured-report-data";
export const UPLOAD_REPORT_DATA = "edit-insured-report-data-by-file";
export const EDIT_REPORT_DATA = "edit-insured-report-data";

export const REPORT_DATA_RECALCULATE = "re-calculate-insured-report";

export const RECTIFICATION_ENTRY = "rectification-insured-report";

export const HISTORY = "get-history";
export const HISTORY_DOWNLOAD = "export-history";
