import { Formik } from 'formik';
import React, { useState } from 'react';
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs';
import { MdEmail } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import * as Yup from 'yup';
import lockImg from '../../../assets/lock.svg';
import { patchRequest } from '../../../helper/AxiosClient';
import { errorNotification, successNotification } from '../../../helper/notification';
import { getCurrentUser } from '../../../helper/Utils';
import CustomButton from '../../common/CustomButton';
import { RESET_PASS } from '../../routes/ApiEndPoin';
import { ROUTES } from '../../routes/Routes';
import './ResetPassword.css';

const ResetPassword = () => {

    const ResetPassSchema = Yup.object().shape({
        old_password: Yup.string().required('This field is required.'),
        new_password: Yup.string().required('This field is required.'),
        confirmPassword: Yup.string()
            .required('This field is required.')
            .oneOf([Yup.ref('new_password'), null], 'Passwords are mismatch!'),
    });

    const initialFormValues = {
        old_password: '',
        new_password: '',
        confirmPassword: '',
    };

    const [confirmPassword, setConfirmPassword] = useState({ password: false });
    const [oldPassword, setOldPassword] = useState({ password: false });
    const [newPassword, setNewPassword] = useState({ password: false });

    const handleConfirmPassword = () => {
        setConfirmPassword({ confirmPassword, password: !confirmPassword.password });
    };
    const handleOLDPassword = () => {
        setOldPassword({ oldPassword, password: !oldPassword.password });
    };
    const handleNewPassword = () => {
        setNewPassword({ newPassword, password: !newPassword.password });
    };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const userMail = getCurrentUser().email;

    const handleSubmit = async (values) => {
        const data = { old_password: values.old_password, new_password: values.new_password }
        setLoading(true)
        try {
            const res = await patchRequest(RESET_PASS, data, navigate);
            successNotification(res.data.message);
            localStorage.clear();
            navigate(ROUTES.LOGIN_ROUTE);
        } catch (error) {
            errorNotification(error.response.data.message);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section id='resetPassword'>
            <div className="container-fluid">
                <div className="row">
                    <div className="d-md-inline-block d-none col-md-6 signInImage">
                        <div className="row h-100">
                            <div className='col title-text d-flex justify-content-center align-items-center'>
                                <h1 className='mb-0'>RI</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {loading ?
                            <div className='row h-100'>
                                <div className="col d-flex align-items-center justify-content-center">
                                    <BeatLoader />
                                </div>
                            </div>
                            :
                            <>
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-7 col-md-9 m-auto">
                                        <div className='login-form'>
                                            <div className="card border-0 rounded-0 w-100">
                                                <div className="card-header border-0 bg-white">
                                                    <div className="card-title">
                                                        <h4>Reset your password!</h4>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <Formik
                                                        initialValues={initialFormValues}
                                                        validationSchema={ResetPassSchema}
                                                        onSubmit={(values) => {
                                                            handleSubmit(values);
                                                        }}
                                                    >
                                                        {({ errors, values, touched, handleSubmit, handleChange }) => (
                                                            <form onSubmit={handleSubmit}>
                                                                <label className='form-label mb-0'>Email</label>
                                                                <fieldset className="form-group position-relative">
                                                                    <input type="email" className="form-control" value={userMail} disabled />
                                                                    <div className='position-absolute icon-email-position'>
                                                                        <MdEmail style={{ color: '#cbcfe1', height: '18px', width: '18px' }} />
                                                                    </div>
                                                                </fieldset>
                                                                <fieldset className="form-group position-relative mt-2 mb-0">
                                                                    <label className='form-label mb-0'>Old Password</label>
                                                                    <input type={oldPassword.password ? "text" : "password"} autoComplete="true" className="form-control" placeholder='Old Password' name='old_password' value={values.old_password} onChange={handleChange} />
                                                                    <div className='position-absolute icon-position'>
                                                                        <img src={lockImg} alt="lock_icon" />
                                                                    </div>
                                                                    <div className='position-absolute eye-position' onClick={handleOLDPassword}>
                                                                        {oldPassword.password ? <BsEyeFill /> : <BsEyeSlashFill />}
                                                                    </div>
                                                                </fieldset>
                                                                {errors.old_password && touched.old_password ? (
                                                                    <div className="text-danger p-0">
                                                                        {errors.old_password}
                                                                    </div>
                                                                ) : null}
                                                                <fieldset className="form-group position-relative mt-2 mb-0">
                                                                    <label className='form-label mb-0'>New Password</label>
                                                                    <input type={newPassword.password ? "text" : "password"} autoComplete="true" className="form-control" placeholder='New Password' name='new_password' value={values.new_password} onChange={handleChange} />
                                                                    <div className='position-absolute icon-position'>
                                                                        <img src={lockImg} alt="lock_icon" />
                                                                    </div>
                                                                    <div className='position-absolute eye-position' onClick={handleNewPassword}>
                                                                        {newPassword.password ? <BsEyeFill /> : <BsEyeSlashFill />}
                                                                    </div>

                                                                </fieldset>
                                                                {errors.new_password && touched.new_password ? (
                                                                    <div className="text-danger p-0">
                                                                        {errors.new_password}
                                                                    </div>
                                                                ) : null}
                                                                <fieldset className="form-group position-relative mt-2 mb-0">
                                                                    <label className='form-label mb-0'>Confirm Password</label>
                                                                    <input type={confirmPassword.password ? "text" : "password"} autoComplete="true" className="form-control" placeholder='Confirm Password' name='confirmPassword' value={values.confirmPassword} onChange={handleChange} />
                                                                    <div className='position-absolute icon-position'>
                                                                        <img src={lockImg} alt="lock_icon" />
                                                                    </div>
                                                                    <div className='position-absolute eye-position' onClick={handleConfirmPassword}>
                                                                        {confirmPassword.password ? <BsEyeFill /> : <BsEyeSlashFill />}
                                                                    </div>
                                                                </fieldset>
                                                                {errors.confirmPassword && touched.confirmPassword ? (
                                                                    <div className="text-danger p-0">
                                                                        {errors.confirmPassword}
                                                                    </div>
                                                                ) : null}
                                                                <fieldset className="form-group mt-4 mb-0">
                                                                    <CustomButton type="submit" className="full-width-btn mainBtn">
                                                                        Reset Password
                                                                    </CustomButton>
                                                                </fieldset>
                                                            </form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </section >
    )
}

export default ResetPassword