import React from 'react';

const Footer = () => {

    return (
        <footer id='footer'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className='text-center'>
                            <p className='mb-0'>
                                © 2023 RI. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer