import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BeatLoader } from "react-spinners";
import { getRequest, patchRequest } from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification
} from "../../../helper/notification";
import CustomButton from "../../common/CustomButton";
import { CLOSING_MONTH, EDIT_CLOSING_MONTH } from "../../routes/ApiEndPoin";

const MonthClosing = () => {
  const pmlProductRef = useRef();
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [monthColumnData, SetMonthColumnData] = useState([]);
  const [monthRowData, setMonthRowData] = useState([]);
  const [apiMonthDataList, setApiMonthDataList] = useState([]);
  const [checked, setChecked] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const getAllMonthClosing = async () => {
    setLoading(true);
    try {
      const res = await getRequest(
        `${CLOSING_MONTH}?year=${startDate.getFullYear()}`
      );
      setApiMonthDataList(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllMonthClosing();
    // eslint-disable-next-line
  }, [startDate]);

  const colGenerator = () => {
    const monthColumnData = [
      {
        name: "month",
        type: "text",
        title: "Month",
        width: 120,
        readOnly: true,
      },
      {
        name: "is_closed",
        type: "checkbox",
        title: "Closing",
        width: 80,
      },
    ];
    SetMonthColumnData(monthColumnData);
  };
  const options = {
    data: monthRowData,
    columns: monthColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: false,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    columnSorting: true,
    editable: true,
    updateTable: function (el, cell, x, y, source, value, label) {
      cell.classList.add("readonly");
      const data = monthRowData?.filter((data) => data?.is_closed === true);
      if (source === false && x === 1 && y === data?.length) {
        cell?.classList?.remove("readonly");
      }
      if (x === monthColumnData.length - 1) {
        cell?.addEventListener("change", function () {
          setChecked(y);
          setModalShow(true);
        });
      }
    },
  };

  useEffect(() => {
    const tempRowData = [];
    if (apiMonthDataList?.length !== 0) {
      apiMonthDataList?.forEach((data, j) => {
        const { createdAt, updatedAt, year, id, ...rest } = data;
        const tempRowObj = {
          month: rest["month"],
          is_closed: rest["is_closed"],
        };
        tempRowData.push(tempRowObj);
      });
      setMonthRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [apiMonthDataList]);

  useEffect(() => {
    if (monthRowData?.length !== 0) {
      document.getElementById("closing").innerHTML = "";
      jspreadsheet(pmlProductRef.current, options);
    }
    // eslint-disable-next-line
  }, [monthRowData]);

  const CustomInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      style={{
        height: "25px",
        borderRadius: "4px",
        border: "1px solid #cccccc",
        padding: "10px",
        fontSize: "12px",
        width: "100%",
      }}
    />
  );

  const onCloseModal = () => {
    const data = pmlProductRef.current.jexcel;
    data?.updateCell(1, checked, false, true);
    data.refresh();
    setModalShow(false);
  };

  const handleChangeValue = (text) => {
    const data = pmlProductRef.current.jexcel;
    if (text === "ok") {
      data?.updateCell(1, checked, true, true);
    } else {
      data?.updateCell(1, checked, false, true);
    }
    data.refresh();
    setModalShow(false);
  };

  const savePmlData = async () => {
    setLoading(true);
    const healthRefInstance = pmlProductRef?.current?.jexcel;
    const jexcelRecords = healthRefInstance?.getJson();
    const jsonRecord = JSON?.parse(JSON?.stringify(jexcelRecords));
    const obj = {
      year: startDate.getFullYear(),
      month_closings_data: jsonRecord,
    };
    try {
      const res = await patchRequest(EDIT_CLOSING_MONTH, obj);
      successNotification(res.data.message);
      getAllMonthClosing();
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard p-3 pb-md-5">
      <div className="row justify-content-between">
        <div className="col sub-header">
          <h3 className="mb-lg-0 mb-2">Month Closing</h3>
        </div>
        <div className="col-xl-2 col-md-3 col-sm-12">
          <label htmlFor="product" className="dropdown-title">
            Month/Year
          </label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showYearPicker
            dateFormat="yyyy"
            maxDate={new Date()}
            customInput={<CustomInput />}
          />
        </div>
        <div className="col-12 mt-3 position-relative">
          {loading ? (
            <div className="col text-center py-5">
              <BeatLoader />
            </div>
          ) : apiMonthDataList?.length === 0 ? (
            <div className="row">
              <div className="col text-center py-5">No Data Available</div>
            </div>
          ) : (
            <div className="col-12">
              <div ref={pmlProductRef} id="closing" />
              <div className="d-flex justify-content-start">
                <CustomButton
                  type="submit"
                  className="mainBtn float-end btn-sm mt-2"
                  handleClick={() => savePmlData()}
                >
                  Enter For Save
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={modalShow} onHide={() => onCloseModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Closing Month</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you wish to finalize this month, please click OK and press Enter
          for save.
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            className="mainBtn"
            onClick={() => handleChangeValue("ok")}
          >
            OK
          </CustomButton>
          <CustomButton
            className="mainBtn"
            onClick={() => handleChangeValue("cancel")}
          >
            CANCEL
          </CustomButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MonthClosing;
