import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import { BeatLoader } from "react-spinners";
import {
  getRequest,
  patchRequest
} from "../../../helper/AxiosClient";
import {
  errorNotification,
  successNotification
} from "../../../helper/notification";
import UploadControl from "../../../helper/UploadControl";
import CustomButton from "../../common/CustomButton";
import {
  EDIT_REPORT_DATA,
  GET_REPORT_DATA,
  GET_REPORT_DATA_EXPORT,
  RECTIFICATION_ENTRY,
  REPORT_DATA_RECALCULATE,
  UPLOAD_REPORT_DATA
} from "../../routes/ApiEndPoin";
import { columnReportData, reportDataColumn } from "./ReportColumsData";

const ReportDataTable = ({ filterParameter, selectedMonth, selectedYear }) => {
  const pageSize = 15;
  const consolidatedRef = useRef();
  const [loadingTable, setLoadingTable] = useState(true);
  const [getApiData, setGetApiData] = useState([]);
  const [reportColumnData, setReportColumnData] = useState([]);
  const [reportRowData, setReportRowData] = useState([]);
  const [changesData, setChangesData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [count, setCount] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState([]);
  const [submitToggle, setSubmitToggle] = useState(true);
  const [monthClosingValue, setMonthClosingValue] = useState(false);
  const [excelExportLoading, setExcelExportLoading] = useState(false);
  const [exportData, setExportData] = useState();
  const [checkedFac, setCheckedFac] = useState(false);
  const [fullyAllocated, setFullyAllocated] = useState(false)

  const handleChangePivotActualGwp = () => {
    setCheckedFac(!checkedFac);
  };
  const handleChangeFullyAllocated = () => {
    setFullyAllocated(!fullyAllocated);
  };

  useEffect(() => {
    setSubmitToggle(true);
  }, [count]);

  /* ----- increment/decrement Function for pagination page count -----*/
  const increment = () => {
    setCount((prevActiveStep) => prevActiveStep + 1);
  };
  const decrement = () => {
    setCount((prevActiveStep) => prevActiveStep - 1);
  };

  /* ----- Col Generator Function ----- */
  const colGenerator = () => {
    setReportColumnData(reportDataColumn);
  };

  /* ----- Col Generator Function ----- */
  const getAllReportData = async () => {
    try {
      const res = await getRequest(
        `${GET_REPORT_DATA}?month=${selectedMonth}&year=${selectedYear}&page=${count}&limit=${pageSize}&is_fac=${checkedFac}&is_fully_allocated=${fullyAllocated}&product_class=${filterParameter?.class ? filterParameter?.class : ""
        }&product_code=${filterParameter?.productCode ? filterParameter?.productCode : ""
        }&tran_id=${filterParameter?.tranId ? filterParameter?.tranId : ""
        }&insured_name=${filterParameter?.insuredNameList ? filterParameter?.insuredNameList : ""
        }&branch_code=${filterParameter?.branchCode ? filterParameter?.branchCode : ""
        }&policy_no=${filterParameter?.policyNo ? filterParameter?.policyNo : ""
        }&UW_year=${filterParameter?.uWYear ? filterParameter?.uWYear : ""}`
      );
      setMonthClosingValue(res?.data?.data?.is_closed);
      setGetApiData(res?.data?.data?.reportData?.page_data);
      setLastPageNumber(
        res?.data?.data?.reportData?.page_information?.last_page
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    setLoadingTable(true);
    getAllReportData();
    // eslint-disable-next-line
  }, [filterParameter, selectedMonth, selectedYear, count, checkedFac, fullyAllocated]);

  useEffect(() => {
    const tempRowData = [];
    if (getApiData?.length !== 0) {
      getApiData?.forEach((data, j) => {
        const { createdAt, updatedAt, ...rest } = data;
        const tempRowObj = columnReportData(count, j, rest);
        tempRowData.push(tempRowObj);
      });
      setReportRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [getApiData]);

  const onAfterChanges = () => {
    if (consolidatedRef && consolidatedRef.current) {
      const healthRefInstance = consolidatedRef.current.jexcel;

      // Check if healthRefInstance is defined
      if (healthRefInstance) {
        const changedRowNumber = healthRefInstance.getSelectedRows(true);
        const changedRowData = healthRefInstance.getJsonRow(changedRowNumber);
        setChangesData([...changesData, changedRowData]);
      } else {
        console.error("healthRefInstance is undefined.");
      }
    } else {
      console.error("consolidatedRef or consolidatedRef.current is undefined.");
    }
  };
  useEffect(() => {
    if (changesData.length !== 0) {
      const finalData = [...storeData, changesData?.[0]];
      const data = finalData?.filter((cur) => cur?.id !== changesData?.[0]?.id);
      setStoreData([...data, changesData?.[0]]);
    }
    // eslint-disable-next-line
  }, [changesData]);

  const handleReversedApiCall = async () => {

    const reversedData = storeData?.filter(item => item?.reversed === true);
    const tranIds = reversedData?.map(item => item["Tran ID"]);

    setLoadingTable(true)
    const obj = {
      tran_ids: tranIds,
    };
    try {
      const res = await patchRequest(RECTIFICATION_ENTRY, obj);
      successNotification(res.data.message);
      getAllReportData();
      setChangesData([])
      setStoreData([])
    } catch (error) {
      errorNotification(error.response.data.message);
      getAllReportData();
      setChangesData([]);
      setStoreData([])
    } finally {
      setLoadingTable(false)
    }
  }

  // const [isDisabledArray, setIsDisabledArray] = useState([])

  // useEffect(() => {
  //   const array = []
  //   reportRowData.forEach((item, i) => {
  //     if ([2, 3].includes(item.rectification_status)) {
  //       console.log([...isDisabledArray, i], 'dsfijdhjsfjdsf')
  //       array.push(i)
  //     }
  //   })
  //   setIsDisabledArray(array)
  // }, [reportRowData, getApiData])

  const options = {
    data: reportRowData,
    columns: reportColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    columnSorting: true,
    editable: true,
    // onselection: function (el, cell, x, y, selections) {
    //   setSelectedRowData(reportRowData[x])
    //   setHighlightSelectedRow({ x: x, y: highlightSelectedRow.y + 1 })
    // },
    onafterchanges: onAfterChanges,
    updateTable: function (el, cell, x, y, source, value, label) {
      if (monthClosingValue) {
        cell.classList.add("readonly");
      }
      if (value === null || (x !== 0 && value === "") || value === "-") {
        cell.innerHTML = "-";
      }
      reportRowData.forEach((item, i) => {
        if ([2, 3].includes(item.rectification_status)) {
          if (y === i) {
            cell.classList.add("readonly");
          }
        }
      })
      if (
        value === "As per FAC Contract" ||
        value === "If Requied" ||
        value === "As per SI & PML Calculation sheet" ||
        value === "Refer PML Slab Sheet" ||
        value === "Not Applicable" ||
        value === "Not Required"
      ) {
        cell.classList.add("readonly");
      }
    },
  };

  useEffect(() => {
    if (
      reportRowData?.length !== 0 &&
      reportColumnData?.length !== 0 &&
      !loadingTable &&
      getApiData?.length !== 0
    ) {
      document.getElementById("reportData").innerHTML = "";
      jspreadsheet(consolidatedRef.current, options).hideIndex();
    }
    // eslint-disable-next-line
  }, [reportRowData, reportColumnData, loadingTable, getApiData]);


  /* ----- Report Data update API call ----- */
  const saveAllReportData = async () => {
    setLoadingTable(true)
    const obj = {
      report_data: storeData,
    };
    try {
      const res = await patchRequest(EDIT_REPORT_DATA, obj);
      successNotification(res.data.message);
      getAllReportData();
      setStoreData([])
      setChangesData([]);
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoadingTable(false)
    }
  };

  /* ----- Function for Excel data Read ----- */
  const readUploadFile = async (e) => {
    setLoadingTable(true);
    if (e.target.files[0]) {
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await patchRequest(`${UPLOAD_REPORT_DATA}?month=${selectedMonth}&year=${selectedYear}`, formData);
        successNotification(res?.data?.message);
        getAllReportData();
        setLoadingTable(false);
        e.target.value = "";
      } catch (error) {
        errorNotification(error.response.data.message);
        setLoadingTable(false);
        e.target.value = "";
      }
    }
  };

  /* ----- Function for export sheet data from API ----- */
  const exportSheet = async () => {
    setExcelExportLoading(true);
    try {
      const res = await getRequest(
        `${GET_REPORT_DATA_EXPORT}?month=${selectedMonth}&year=${selectedYear}&page=${count}&limit=${pageSize}&is_fac=${checkedFac}&is_fully_allocated=${fullyAllocated}&product_class=${filterParameter?.class ? filterParameter?.class : ""
        }&product_code=${filterParameter?.productCode ? filterParameter?.productCode : ""
        }&tran_id=${filterParameter?.tranId ? filterParameter?.tranId : ""
        }&insured_name=${filterParameter?.insuredNameList ? filterParameter?.insuredNameList : ""
        }&branch_code=${filterParameter?.branchCode ? filterParameter?.branchCode : ""
        }&policy_no=${filterParameter?.policyNo ? filterParameter?.policyNo : ""
        }&UW_year=${filterParameter?.uWYear ? filterParameter?.uWYear : ""}`
      );
      setExportData(res?.data?.data?.url);
      setExcelExportLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  /* ----- Function for download exported sheet  ----- */
  const handleSaveFile = () => {
    if (exportData) {
      window.open(exportData);
      setExportData();
    }
  };

  /* ----- Function for sample upload file Download ----- */
  const handleSampleFileDownload = () => {
    fetch("report_data_sample_file.csv").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "report_data_sample_file.csv";
        alink.click();
      });
    });
  };

  /* ----- Function for sample Recalculate ----- */
  const handleRecalculateApi = async () => {
    setLoadingTable(true)
    try {
      const res = await patchRequest(`${REPORT_DATA_RECALCULATE}?month=${selectedMonth}&year=${selectedYear}`);
      successNotification(res?.data?.message);
      getAllReportData();
    } catch (error) {
      errorNotification(error.response.data.message);
      getAllReportData();
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  }

  return (
    <div class=" p-3 pb-md-5 ">
      <div className="col-12 position-relative ">
        {loadingTable && loadingTable ? (
          <div className="row">
            <div className="col text-center py-5">
              <BeatLoader />
            </div>
          </div>
        ) : (
          <div className="row position-relative">
            <div className="col g-0">
              <div className="col d-flex justify-content-end align-items-center mb-2">
                <div div className="me-3">
                  <form style={{ float: "right", alignItems: 'center', display: 'flex' }}>
                    <label
                      htmlFor="pivot"
                      className="pe-2"
                      style={{ fontSize: "14px" }}
                    >
                      FAC
                    </label>
                    <input
                      value={checkedFac}
                      checked={checkedFac}
                      id="pivot"
                      type="checkbox"
                      onChange={handleChangePivotActualGwp}
                    />
                  </form>
                  <form style={{ float: "right", marginRight: '10px', alignItems: 'center', display: 'flex' }}>
                    <label
                      htmlFor="pivot"
                      className="pe-2"
                      style={{ fontSize: "14px" }}
                    >
                      Fully Not Allocated
                    </label>
                    <input
                      value={fullyAllocated}
                      checked={fullyAllocated}
                      id="pivot"
                      type="checkbox"
                      onChange={handleChangeFullyAllocated}
                    />
                  </form>
                </div>
                {!monthClosingValue && <CustomButton
                  className="mainBtn me-3 btn-sm"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Import Sample Template"
                  onClick={handleSampleFileDownload}
                >
                  Format for upload
                </CustomButton>}
                {!monthClosingValue && <CustomButton
                  className="mainBtn btn-sm me-3"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Import csv"
                >
                  <UploadControl onChange={readUploadFile}>
                    Upload
                  </UploadControl>
                </CustomButton>}
                {excelExportLoading ? (
                  <BeatLoader size={8} />
                ) : exportData ? (
                  <CustomButton
                    type="button"
                    className="mainBtn float-end btn-sm"
                    onClick={handleSaveFile}
                    disabled={getApiData?.length === 0}
                  >
                    Export
                  </CustomButton>
                ) : (
                  <CustomButton
                    type="button"
                    className="mainBtn float-end btn-sm"
                    onClick={exportSheet}
                    disabled={getApiData?.length === 0}
                  >
                    Download
                  </CustomButton>
                )}
                {!monthClosingValue && <CustomButton
                  className="mainBtn btn-sm ms-3"
                  type='button'
                  onClick={handleRecalculateApi}
                >
                  Recalculate
                </CustomButton>}
                {!monthClosingValue && <CustomButton
                  className="mainBtn ms-3 btn-sm"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Import Sample Template"
                  onClick={handleReversedApiCall}
                  disabled={storeData?.filter(item => item?.reversed === true)?.map(item => item["Tran ID"])?.length === 0}
                >
                  Rectification
                </CustomButton>}
              </div>
              {getApiData.length === 0 ? <div className="row">
                <div className="col text-center py-5">No Data Available</div>
              </div> : <div className="actual_gwp">
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between overflow-auto">
                    <div ref={consolidatedRef} id="reportData" />
                  </div>
                </div>
              </div>}
            </div>

            {(getApiData.length !== 0 && submitToggle) ? (
              <div className="mt-2 d-flex justify-content-between">
                <div className="pageNumber">
                  Page {count} of {lastPageNumber}
                </div>
                <div>
                  <CustomButton
                    type="button"
                    className="mainBtn float-end btn-sm ms-3"
                    disabled={lastPageNumber <= count}
                    onClick={increment}
                  >
                    Next
                  </CustomButton>
                  <CustomButton
                    type="button"
                    className="mainBtn float-end btn-sm "
                    disabled={count === 1}
                    onClick={decrement}
                  >
                    Prev
                  </CustomButton>
                </div>
              </div>
            ) : (
              ""
            )}
            {(getApiData.length !== 0 && !monthClosingValue) && (
              <div className="col-12">
                <CustomButton
                  type="submit"
                  className="mainBtn float-end btn-sm mt-2"
                  handleClick={() => saveAllReportData()}
                  disabled={storeData.length === 0}
                >
                  Enter For Save
                </CustomButton>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportDataTable;
