import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import { customStylesOfMultiSelect } from "../../../helper/customStyleOfSelect";
import CustomButton from "../../common/CustomButton";
import {
  CustomInput,
  getBranchCodeApiCall,
  getClassApiCall,
  getInsuredNameApiCall,
  getPolicyNoApiCall,
  getProductCodeApiCall,
  getTranIdApiCall,
  getUwYearApiCall,
} from "./ReportApiCall";
import ReportDataTable from "./ReportDataTable";

const FinalReportData = () => {
  const [loading, setLoading] = useState(true);
  const [classList, setClassList] = useState('');
  const [insuredName, setInsuredName] = useState([]);
  const [branchCode, setBranchCode] = useState([]);
  const [productCode, setProductCode] = useState([]);
  const [policyNo, setPolicyNo] = useState([]);
  const [uWYear, setUwYear] = useState([]);
  const [tranIdList, setTranIdList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const selectedYear = selectedDate?.getFullYear();
  const selectedMonth = parseInt(selectedDate?.getMonth() + 1);

  const [selectedParameter, setSelectedParameter] = useState({
    class: classList?.[0] || [],
    tranId: tranIdList?.[0] || [],
    insuredNameList: insuredName?.[0] || [],
    branchCode: branchCode?.[0] || [],
    productCode: productCode?.[0] || [],
    policyNo: policyNo?.[0] || [],
    uWYear: uWYear?.[0] || [],
  });

  const [filterParameter, setFilterParameter] = useState(selectedParameter);
  useEffect(() => {
    getTranIdApiCall(setLoading, setTranIdList, selectedYear, selectedMonth, selectedParameter);
    getClassApiCall(setLoading, setClassList, selectedYear, selectedMonth, selectedParameter);
    getInsuredNameApiCall(
      setLoading,
      setInsuredName,
      selectedYear,
      selectedMonth,
      selectedParameter
    );
    getBranchCodeApiCall(
      setLoading,
      setBranchCode,
      selectedYear,
      selectedMonth,
      selectedParameter
    );
    getProductCodeApiCall(
      setLoading,
      setProductCode,
      selectedYear,
      selectedMonth,
      selectedParameter
    );
    getPolicyNoApiCall(setLoading, setPolicyNo, selectedYear, selectedMonth, selectedParameter);
    getUwYearApiCall(setLoading, setUwYear, selectedYear, selectedMonth, selectedParameter);
    // eslint-disable-next-line
  }, [selectedYear, selectedMonth, selectedParameter.class]);

  /* ----- handle Select for onchange of dropdown ----- */
  const handleSelect = (e, action) => {
    const isInclude = e?.length && action?.option?.value === "All";

    if (isInclude) {
      const DATA = e.filter((data) => data.value === "All");
      setSelectedParameter({ ...selectedParameter, [action.name]: DATA });
    } else {
      const DATA = e.filter((data) => data.value !== "All");
      setSelectedParameter({
        ...selectedParameter,
        [action.name]: DATA,
      });
    }
  };

  /* ----- handle Apply filter  ----- */
  const handleApplyFilter = () => {
    const transformedObject = {};
    for (const key in selectedParameter) {
      if (selectedParameter.hasOwnProperty(key)) {
        const filteredArray = selectedParameter[key].filter(
          (item) => item.value !== undefined
        );
        if (filteredArray.length > 0) {
          transformedObject[key] = filteredArray.map((item) => item.value);
        }
      }
    }
    setFilterParameter(transformedObject);
  };

  /* ----- Function date changes  ----- */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  return (
    <section className="dashboard p-3 pb-md-5">
      <div className="container-fluid">
        <div className="sub-header row pb-sm-2">
          <div className="col-xl-3 col-12">
            <h3 className="mb-lg-0 mb-2">Report Data</h3>
          </div>
        </div>
        {loading ? (
          <div className="row">
            <div className="col text-center py-5">
              <BeatLoader />
            </div>
          </div>
        ) : (
          <div>
            <div className="row gy-2">
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="product" className="dropdown-title">
                  Month/Year
                </label>
                <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  customInput={<CustomInput />}
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="class" className="dropdown-title">
                  Class
                </label>
                <Select
                  id="class"
                  name="class"
                  options={classList}
                  value={selectedParameter.class}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="tranId" className="dropdown-title">
                  Tran Id
                </label>
                <Select
                  id="tranId"
                  name="tranId"
                  options={tranIdList}
                  value={selectedParameter.tranId}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="insuredName" className="dropdown-title">
                  Insured Name
                </label>
                <Select
                  id="insuredNameList"
                  name="insuredNameList"
                  options={insuredName}
                  value={selectedParameter.insuredNameList}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="branchCode" className="dropdown-title">
                  Branch Code
                </label>
                <Select
                  id="branchCode"
                  name="branchCode"
                  options={branchCode}
                  value={selectedParameter.branchCode}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="productCode" className="dropdown-title">
                  Product Code
                </label>
                <Select
                  id="productCode"
                  name="productCode"
                  options={productCode}
                  value={selectedParameter.productCode}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="policyNo" className="dropdown-title">
                  Policy No
                </label>
                <Select
                  id="policyNo"
                  name="policyNo"
                  options={policyNo}
                  value={selectedParameter.policyNo}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
              <div className="col-xl-3 col-md-4 col-sm-12">
                <label htmlFor="uWYear" className="dropdown-title">
                  U/W Year
                </label>
                <Select
                  id="uWYear"
                  name="uWYear"
                  options={uWYear}
                  value={selectedParameter.uWYear}
                  components={{ IndicatorSeparator: () => null }}
                  styles={customStylesOfMultiSelect}
                  onChange={(e, action) => handleSelect(e, action)}
                  isMulti
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <CustomButton
                  type="submit"
                  className="mainBtn float-end btn-sm mt-2"
                  handleClick={() => handleApplyFilter()}
                >
                  Apply Filter
                </CustomButton>
              </div>
              <div style={{ color: 'red' }}><span>Note*</span> :- To upload the data, it has to be downloaded and the necessary changes have to be made and as it is uploaded.</div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-12 overflow-auto mt-3">
          <ReportDataTable
            filterParameter={filterParameter}
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
        </div>
      </div>
    </section>
  );
};

export default FinalReportData;
