import { Outlet } from "react-router-dom";
import Header from "../components/layout/Header";
import { getCurrentUser } from "./Utils";
import Footer from '../components/layout/Footer';

export const Layout = () => {
  const token = getCurrentUser()?.token;
  return (
    <>
      {token && <Header />}
      <Outlet />
      {token && <Footer />}

    </>
  );
};
