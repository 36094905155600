import jspreadsheet from "jspreadsheet-ce";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../helper/AxiosClient";
import { customStylesOfMultiSelect } from "../../../helper/customStyleOfSelect";
import {
  errorNotification,
  successNotification,
} from "../../../helper/notification";
import UploadControl from "../../../helper/UploadControl";
import CustomButton from "../../common/CustomButton";
import {
  ALLOCATION_MASTER_LIST,
  EDIT_ALLOCATION_DATA,
  POST_ALLOCATION_MASTER_CSV,
  PRODUCT_CLASS,
  PRODUCT_CODE,
} from "../../routes/ApiEndPoin";
import { CustomInput, pmlColumnDataCreate } from "./Temp";

const AllocationMaster = () => {
  const pageSize = 15;
  const consolidatedRef = useRef();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const selectedYear = selectedDate?.getFullYear();
  const selectedMonth = parseInt(selectedDate?.getMonth() + 1);
  const [loading, setLoading] = useState(false);
  const [loadingForDropdown, setLoadingForDropdown] = useState(false);
  const [getApiData, setGetApiData] = useState([]);
  const [allocationColumnData, setAllocationColumnData] = useState([]);
  const [allocationRowData, setAllocationRowData] = useState([]);
  const [productClassList, setProductClassList] = useState([]);
  const [productCodeList, setProductCodeList] = useState([]);
  const [changesData, setChangesData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [count, setCount] = useState(1);
  const [lastPageNumber, setLastPageNumber] = useState([]);
  const [submitToggle, setSubmitToggle] = useState(true);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [monthClosingValue, setMonthClosingValue] = useState(false);

  const [selectedParameter, setSelectedParameter] = useState({
    product_code: productCodeList?.[0],
    product_class: productClassList?.[0],
  });
  const [filterParameter, setFilterParameter] = useState(selectedParameter);

  var selectedMonthName = selectedDate?.toLocaleString("en-US", {
    month: "short",
  });

  useEffect(() => {
    setSubmitToggle(true);
    setUploadFlag(false);
  }, [count, selectedParameter]);

  /* ----- increment/decrement Function for pagination page count -----*/
  const increment = () => {
    setCount((prevActiveStep) => prevActiveStep + 1);
  };
  const decrement = () => {
    setCount((prevActiveStep) => prevActiveStep - 1);
  };

  /* ----- Col Generator Function ----- */
  const colGenerator = () => {
    setAllocationColumnData(pmlColumnDataCreate);
  };

  /* ----- Function date changes  ----- */
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  /* ----- Function for sample upload file Download ----- */
  const handleSampleFileDownload = () => {
    fetch("allocation_file.csv").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "allocation_file.csv";
        alink.click();
      });
    });
  };
  /* ----- Function upload file ----- */
  const readUploadFile = async (e) => {
    setLoading(true);
    if (e.target.files[0]) {
      let file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await postRequest(`${POST_ALLOCATION_MASTER_CSV}?month=${selectedMonth}&year=${selectedYear}`, formData);
        if (res.type === 1) {
          successNotification(res?.response?.data?.message);
          e.target.value = "";
        }
        if (res.type === 2) {
          errorNotification(res.errormessage);
          e.target.value = "";
        }
      } catch (error) {
        console.log(error);
      } finally {
        getAllocationMasterData();
        getProductClassList();
        getProductCodeList();
        setLoading(false);
      }
    }
  };

  const getAllocationMasterData = async () => {
    try {
      const url = `${ALLOCATION_MASTER_LIST}?page=${count}&limit=${pageSize}&month=${selectedMonth}&year=${selectedYear}&product_code=${filterParameter.product_code || ""
        }&product_class=${filterParameter.product_class || ""}`;
      const response = await getRequest(url);
      const responseData = response.data.data;

      setMonthClosingValue(responseData.is_closed);
      setGetApiData(responseData.allocationMasterData.page_data);
      setLastPageNumber(
        responseData.allocationMasterData.page_information.last_page
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetches the list of product classes from the API
   */
  const getProductClassList = async () => {
    try {
      const res = await getRequest(
        `${PRODUCT_CLASS}?month=${selectedMonth}&year=${selectedYear}`
      );
      const list = res.data.data?.map((data) => ({
        value: data.Class,
        label: data.Class,
      }));
      list.unshift({ value: "All", label: "All" });
      setProductClassList(list);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForDropdown(false);
    }
  };

  /**
   * Fetches the product code list from the API based on selected month and year.
   */
  const getProductCodeList = async () => {
    const { product_class } = selectedParameter;
    const productClass = product_class?.map((item) => item?.value);
    try {
      const res = await getRequest(
        `${PRODUCT_CODE}?month=${selectedMonth}&year=${selectedYear}&product_class=${productClass ? productClass : ''}`
      );
      const list = res?.data?.data?.map((data) => ({
        value: data["Product Code"],
        label: data["Product Code"],
      }));
      list.unshift({ value: "All", label: "All" });
      setProductCodeList(list);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingForDropdown(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllocationMasterData();
    // eslint-disable-next-line
  }, [filterParameter, selectedMonth, selectedYear, count]);

  useEffect(() => {
    setLoadingForDropdown(true);
    getProductClassList();
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    setLoadingForDropdown(true);
    getProductCodeList();
    // eslint-disable-next-line
  }, [selectedMonth, selectedYear, selectedParameter.product_class]);

  useEffect(() => {
    const tempRowData = [];
    if (getApiData?.length !== 0) {
      getApiData?.forEach((data, j) => {
        const { createdAt, updatedAt, ...rest } = data;
        const tempRowObj = {
          sr_no: 15 * (count - 1) + j + 1,
          id: rest["id"],
          "Product Code": rest["Product Code"],
          Class: rest["Class"],
          "Product Description": rest["Product Description"],
          "PML /OCCUPANCY": rest["PML /OCCUPANCY"],
          "OCCUPANCY CODE": rest["OCCUPANCY CODE"],
          Obligatory: rest["Obligatory"],
          Facultative: rest["Facultative"],
          Terrorism: rest["Terrorism"],
          "Obligatory Commission": rest["Obligatory Commission"],
          "Obligatory Commission OD": rest["Obligatory Commission OD"],
          "Obligatory Commission TP": rest["Obligatory Commission TP"],
          "Quota Commission %": rest["Quota Commission %"],
          "Quota Commission % OD": rest["Quota Commission % OD"],
          "Quota Commission % TP": rest["Quota Commission % TP"],
          "Quota Allocation %": rest["Quota Allocation %"],
          "Quota Allocation % Maximum":
            rest["Quota Allocation % Maximum"],
          "Quota Allocation OD % of IDV":
            rest["Quota Allocation OD % of IDV"],
          "Quota Allocation TP % of IDV":
            rest["Quota Allocation TP % of IDV"],
          "First Surplus Capacity": rest["First Surplus Capacity"],
          "First Surplus Capacity Lines":
            rest["First Surplus Capacity Lines"],
          "First Surplus Capacity Maximum on PML":
            rest["First Surplus Capacity Maximum on PML"],
          Retention: rest["Retention"],
          "Retention %": rest["Retention %"],
          "Retention Maximum": rest["Retention Maximum"],
          "Retention Amount": rest["Retention Amount"],
          "Retention OD %": rest["Retention OD %"],
          "Retention OD Amount": rest["Retention OD Amount"],
          "Retention TP %": rest["Retention TP %"],
          "Retention TP Amount": rest["Retention TP Amount"],
          "First Surplus Commission": rest["First Surplus Commission"],
          "FAC Comm": rest["FAC Comm"],
          "Terrorism Commission": rest["Terrorism Commission"],
          "Co-insurance Limitation": rest["Co-insurance Limitation"],
          "Treaty Name": rest["Treaty Name"],
          month: rest["month"],
          year: rest["year"],
        };
        tempRowData.push(tempRowObj);
      });
      setAllocationRowData(tempRowData);
    }
    colGenerator();
    // eslint-disable-next-line
  }, [getApiData]);

  const onAfterChanges = () => {
    const healthRefInstance = consolidatedRef?.current?.jexcel;
    const changedRowNumber = healthRefInstance.getSelectedRows(true)[0];
    const changedRowData = healthRefInstance.getJsonRow(changedRowNumber);
    setChangesData([...changesData, changedRowData]);
  };

  useEffect(() => {
    if (changesData.length !== 0) {
      const finalData = [...storeData, changesData?.[0]];
      const data = finalData?.filter((cur) => cur?.id !== changesData?.[0]?.id);
      setStoreData([...data, changesData?.[0]]);
    }
    // eslint-disable-next-line
  }, [changesData]);

  const options = {
    data: allocationRowData,
    columns: allocationColumnData,
    autoIncrement: false,
    allowInsertColumn: false,
    allowInsertRow: false,
    columnDrag: false,
    contextMenu: false,
    copyCompatibility: true,
    defaultColWidth: 200,
    search: false,
    csvHeaders: true,
    csvFileName: "Opening_Hc",
    ...(uploadFlag && { pagination: 15 }),
    columnSorting: true,
    updateTable: function (el, cell, x, y, source, value, label) {
      if (monthClosingValue) {
        cell.classList.add("readonly");
      }
      if (
        value === "As per FAC Contract" ||
        value === "If Requied" ||
        value === "As per SI & PML Calculation sheet" ||
        value === "Refer PML Slab Sheet" ||
        value === "Not Applicable" ||
        value === "Not Required"
      ) {
        cell.classList.add("readonly");
      }
      if (value === null || value === "" || value === "-") {
        cell.innerHTML = '-';
      }
    },
    onafterchanges: onAfterChanges,
    nestedHeaders: [
      [
        {
          title: "",
          colspan: "9",
        },
        {
          title: "Obligatory Commission",
          colspan: "3",
        },
        {
          title: "Quota Commission",
          colspan: "3",
        },
        {
          title: "Quota Allocation",
          colspan: "4",
        },
        {
          title: "First Surplus Capacity",
          colspan: "3",
        },
        {
          title: "Retention",
          colspan: "8",
        },
        {
          title: "",
          colspan: "8",
        },
      ],
    ],
  };

  useEffect(() => {
    if (allocationRowData?.length !== 0) {
      document.getElementById("consolidated").innerHTML = "";
      jspreadsheet(consolidatedRef.current, options).hideIndex();
    }
    // eslint-disable-next-line
  }, [allocationRowData]);

  const saveAllocationData = async () => {
    setLoading(true)
    const newData = storeData.map((item) => {
      const replacedItem = Object.fromEntries(
        Object.entries(item).map(([key, value]) => [key, value === "-" ? null : value])
      );
      return replacedItem;
    });
    const obj = {
      allocation_data: newData,
    };
    try {
      const res = await patchRequest(`${EDIT_ALLOCATION_DATA}?month=${selectedMonth}&year=${selectedYear}`, obj);
      successNotification(res.data.message);
      getAllocationMasterData();
      setChangesData([]);
      setStoreData([])
    } catch (error) {
      errorNotification(error.response.data.message);
    } finally {
      setLoading(false)
    }
  };

  const handleSelect = (e, action) => {
    const isInclude = e?.length && action?.option?.value === "All";
    if (isInclude) {
      const DATA = e.filter((data) => data.value === "All");
      setSelectedParameter({ ...selectedParameter, [action.name]: DATA });
    } else {
      const DATA = e.filter((data) => data.value !== "All");
      setSelectedParameter({ ...selectedParameter, [action.name]: DATA });
    }
  };

  const handleApplyFilter = () => {
    const { product_class, product_code } = selectedParameter;
    const productCode = product_code?.map((item) => item?.value);
    const productClass = product_class?.map((item) => item?.value);
    setFilterParameter({
      product_class: productClass,
      product_code: productCode,
    });
  };

  return (
    <div class="dashboard p-3 pb-md-5 ">
      <div className="container-fluid">
        <div className="sub-header row pb-sm-2">
          <div className="col-xl-3 col-12">
            <h3 className="mb-lg-0 mb-2">{`Allocation Master (${selectedMonthName}-${selectedYear})`}</h3>
          </div>
          {loadingForDropdown ? (
            <div className="row">
              <div className="col text-center py-5">
                <BeatLoader />
              </div>
            </div>
          ) : (
            <div className="col-xl-9 col-12 mt-xxl-0 mt-2">
              <div className="row select-box-class">
                <div className="col-xxl-2 col-lg-3 col-md-4 col-12 mt-xl-0 mt-2 position-relative z-3">
                  <label htmlFor="product" className="dropdown-title">
                    Month/Year
                  </label>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    customInput={<CustomInput />}
                  />
                </div>
                <div className="col-xxl-2 col-lg-3 col-md-4 col-12 mt-xl-0 mt-2">
                  <label htmlFor="product_class" className="dropdown-title">
                    Product Class
                  </label>
                  <Select
                    id="product_class"
                    name="product_class"
                    options={productClassList}
                    value={selectedParameter.product_class}
                    components={{ IndicatorSeparator: () => null }}
                    styles={customStylesOfMultiSelect}
                    onChange={(e, action) => handleSelect(e, action)}
                    isMulti
                  />
                </div>
                <div className="col-xxl-2 col-lg-3 col-md-4 col-12 mt-xl-0 mt-2">
                  <label htmlFor="product_code" className="dropdown-title">
                    Product Code
                  </label>
                  <Select
                    id="product_code"
                    name="product_code"
                    options={productCodeList}
                    value={selectedParameter.product_code}
                    components={{ IndicatorSeparator: () => null }}
                    styles={customStylesOfMultiSelect}
                    onChange={(e, action) => handleSelect(e, action)}
                    isMulti
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <CustomButton
                    type="submit"
                    className="mainBtn float-end btn-sm mt-2"
                    handleClick={() => handleApplyFilter()}
                  >
                    Apply Filter
                  </CustomButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-12 mt-3 position-relative ">
        <div className="row mb-3">
          <div className="col d-flex justify-content-between align-items-center">
            {!monthClosingValue && (
              <div className="col d-flex justify-content-end align-items-center">
                <CustomButton
                  className="mainBtn me-3 btn-sm"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Import Sample Template"
                  onClick={handleSampleFileDownload}
                >
                  Format for upload
                </CustomButton>
                <CustomButton
                  className="mainBtn btn-sm"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Import csv"
                >
                  <UploadControl onChange={readUploadFile}>
                    Upload
                  </UploadControl>
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="row">
            <div className="col text-center py-5">
              <BeatLoader />
            </div>
          </div>
        ) : getApiData?.length === 0 ? (
          <div className="row">
            <div className="col text-center py-5">No Data Available</div>
          </div>
        ) : (
          <>
            <div className="row position-relative">
              <div className="col">
                <div className="actual_gwp">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between overflow-auto">
                      <div ref={consolidatedRef} id="consolidated" />
                    </div>
                  </div>
                </div>
              </div>
              {submitToggle ? (
                <div className="mt-2 d-flex justify-content-between">
                  <div className="pageNumber">
                    Page {count} of {lastPageNumber}
                  </div>
                  <div>
                    <CustomButton
                      type="button"
                      className="mainBtn float-end btn-sm ms-3"
                      disabled={lastPageNumber <= count}
                      onClick={increment}
                    >
                      Next
                    </CustomButton>
                    <CustomButton
                      type="button"
                      className="mainBtn float-end btn-sm "
                      disabled={count === 1}
                      onClick={decrement}
                    >
                      Prev
                    </CustomButton>
                  </div>
                </div>
              ) : (
                ""
              )}
              {!monthClosingValue && (
                <div className="col-12">
                  <CustomButton
                    type="submit"
                    className="mainBtn float-end btn-sm mt-2"
                    handleClick={() => saveAllocationData()}
                    disabled={storeData.length === 0}
                  >
                    Enter For Save
                  </CustomButton>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllocationMaster;
